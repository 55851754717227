import {gql} from "@apollo/client";

export const GET_ALL_BUSINESS_STARTER = gql`
  query PaginatedReturnsTest($page: Float!, $pageSize: Float!, $searchTerm: String) {
    allBusinessStarterToolkitPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
       returns{
      _id
      firstName
      lastName
      phoneNumber
      created_at
    
      paymentDetails{
        amount
        transactionId
        serviceId
      }
      status
      user{
        firstName
        lastName
        email
        phoneNumber
        dob
      }
      adminMessage
    }
    pageInfo{
      currentPage
      pageSize
      totalPages
      totalItems
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
    }
    }
  }
`;
