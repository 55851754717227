import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress, Select, MenuItem
} from '@mui/material';
// components
// mock
import {useLazyQuery, useQuery} from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { GET_ADMIN_ORDERS } from 'src/graphql/queries/order';
import {GET_ALL_ANNUAL_RETURN_REQUEST_PAGINATED} from "../../graphql/queries/annualReturn";
import {useSnackbar} from "notistack";
import {debounce} from "lodash";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminOrder() {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [orderStatus, setOrderStatus] = useState('ALL');
  const { data: data2, loading: loadingData2, fetchMore } = useQuery(GET_ADMIN_ORDERS, {
    variables: { page, pageSize, orderStatus:orderStatus },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [orderRequest, setOrderRequest] = useState([]);

  const [filtered, setFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (data2 && data2?.getAllAdminOrders) {
      setOrderRequest(data2.getAllAdminOrders.orders);
    }
  }, [data2]);

  useEffect(() => {
    setFiltered(orderRequest);
  }, [orderRequest,]);

  const handlePageChange = async (newPage) => {
    setPage(newPage);
    await fetchMore({
      variables: { page: newPage, pageSize, searchTerm },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  };

  useEffect( ()=>{
    async function fetchData(){
      setPage(1);
      setSearchLoading(true);
      await fetchMore({
        variables: { page, pageSize, searchTerm: searchTerm, orderStatus },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
      setSearchLoading(false);
    }
    fetchData();

  },[orderStatus]);

  const handleSearchDebounced = debounce(async (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
    setSearchLoading(true);
    await fetchMore({
      variables: { page, pageSize, searchTerm: newSearchTerm, orderStatus },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        console.log({fetchMoreResult, newSearchTerm} )
        return fetchMoreResult;
      },
    });
    setSearchLoading(false);

  }, 200);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setSearchLoading(false);
    handleSearchDebounced(newSearchTerm);
  };


  // useEffect(() => {
  //   getAllAdminOrders({
  //     variables: {
  //       input: {
  //         orderStatus: 'NEW',
  //       },
  //     },
  //   });
  // }, [getAllAdminOrders]);

  return (
    <>
      <Helmet>
        <title> Orders | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button> */}
        </Stack>

        <Grid container spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10} >
              {/* Search TextField */}
              <TextField
                  value={searchTerm}
                  fullWidth
                  placeholder="Search Reservation by Company name..."
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          {searchLoading && <CircularProgress size={20} />}
                        </InputAdornment>
                    ),
                  }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              {/* Select for Order Status */}
              <Select
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Order Status' }}
                  sx={{ marginLeft: { xs: 0, md: 2 } }}
              >
                <MenuItem value="" disabled>
                  Filter by Status
                </MenuItem>
                <MenuItem value="ALL">ALL</MenuItem>
                <MenuItem value="NEW">NEW</MenuItem>
                <MenuItem value="DRAFT">DRAFT</MenuItem>
              </Select>
            </Grid>
          </Grid>


          <Grid item md={12} sm={12}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {filtered?.map((_, index) => (
                  <Grid item xs={12} key={index}>
                    <Card
                      sx={{
                        p: 5,
                        ...((index === 0 || index === 2) && {
                          boxShadow: 'none',
                          bgcolor: 'background.default',
                          border: (theme) => `dashed 1px ${theme.palette.divider}`,
                        }),
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Stack direction="column">
                            <Typography>Client: {`${_.user?.firstName} ${_.user?.lastName}`}</Typography>
                            <Typography>Order No: {_._id}</Typography>
                            <Typography>Date: {_?.created_at}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item container md={6} xs={12} justifyContent="flex-end">
                          <Stack direction="column">
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                              }}
                            >
                              <img
                                src={
                                  _.orderStatus === 'DRAFT'
                                    ? '/icons/statuses/Pending.svg'
                                    : '/icons/statuses/Approved.svg'
                                }
                                width={20}
                                alt="status"
                              />
                              <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                                {_.orderStatus === 'DRAFT' ? 'Pending Payment' : 'Payment Successful'}
                              </Typography>
                            </Box>
                            <Typography variant="h5">{_.amount ? _.amount : ''}</Typography>
                            <Typography>Client Email: {`${_.user?.email}`}</Typography>
                            <Typography>Client Phone: {`${_.user?.phoneNumber}`}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item container md={8} xs={12} spacing={2}>
                          {_.products?.map((product, index) => (
                            <Chip label={product?.productName} key={index} sx={{ lineHeight: '100px' }} />
                          ))}
                        </Grid>
                        <Grid item container md={4} xs={12} justifyContent="flex-end">
                          <Button
                            variant="outlined"
                            sx={{ width: '200px', height: '40px' }}
                            onClick={() => navigate(`/admin/order?id=${_?._id}`, { replace: true })}
                          >
                            {' '}
                            {_.orderStatus === 'DRAFT' ? 'Complete Order' : 'View Details'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item>
                  <Button variant="outlined" onClick={() => handlePageChange(page - 1)} disabled={page <= 1 || loadingData2}>
                    Previous
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="body2">Page {page}</Typography>
                </Grid>
                <Grid item>
                  <Button
                      variant="outlined"
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page >= data2?.getAllAdminOrders.pageInfo?.totalPages || loadingData2}
                  >
                    Next
                  </Button>
                </Grid>
                {loadingData2 && <CircularProgress size={24} />}
              </Grid>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
