import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PersonIcon from '@mui/icons-material/Person';
import { Hidden, Paper } from '@mui/material';

export default function ButtomNavigation() {
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Hidden smUp>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation sx={{ width: '100%' }} value={value} onChange={handleChange}>
          <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
          <BottomNavigationAction label="Messages" value="messages" icon={<EmailIcon />} />
          <BottomNavigationAction label="BookMart" value="bookmart" icon={<BookmarkBorderIcon />} />
          <BottomNavigationAction label="User" value="user" icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </Hidden>
  );
}
