import { createSlice } from '@reduxjs/toolkit';

const businessRegistrationSlice = createSlice({
  name: 'businessregistration',
  initialState: {
    data: {},
  },
  reducers: {
    setBusinessRegistrationData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },

    clearBusinessRegistrationData: (state, action) => {
      state.data = {};
    },
  },
});

export const businessRegistrationReducer = businessRegistrationSlice.reducer;

export const { setBusinessRegistrationData, clearBusinessRegistrationData } = businessRegistrationSlice.actions;
