import { gql } from '@apollo/client';

export const FETCH_USER = gql`
  query {
    me {
      _id
      firstname
      lastname
      email
      username
      thumbnail
      sessionId
      subscriptionId
    }
  }
`;
export const GET_INVITES_COUNT = gql`
  query getInviteCount {
    getInviteCount
  }
`;
export const GET_PREVIEW_COUNT = gql`
  query getPreviewcount {
    getPreviewcount
  }
`;
export const GET_LATEST_PREVIEW = gql`
  query getLatestPreview {
    getLatestPreview {
      _id
      previewId
      video
      video_length
      fullname
      created_at
      storybook {
        storybookId
        _id
        title
        thumbnail
      }
    }
  }
`;

//returns a single user
export const GET_USER_BY_ID = gql`
  query findUserById($input: FindUserByIdInput!) {
    findUserById(input: $input) {
      firstname
      lastname
      username
    }
  }
`;

export const GET_ALL_USERS = gql`
  query {
    findAllUsers {
      _id
      firstName
      lastName
      role
      middleName
      gender
      address
      city
      postCode
      phoneNumber
      dob
      thumbnail
      created_at
      email
    }
  }
`;

export const GET_ALL_USERS_PAGINATED = gql`
  query findAllUsersPaginated($page: Float, $pageSize: Float, $searchTerm: String, $filter: String, $order: Float) {
    findAllUsersPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm, filter: $filter, order: $order) {
      allClient {
        _id
        firstName
        lastName
        role
        middleName
        gender
        address
        city
        postCode
        phoneNumber
        dob
        thumbnail
        created_at
        email
        clientRef
      }
      pageInfo {
        currentPage
        totalPages
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const GET_ALL_CLIENT_USERS_PAGINATED = gql`
  query findAllClientUsersPaginated($page: Float, $pageSize: Float, $searchTerm: String, $filter: String, $order: Float) {
    findAllClientUsersPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm, filter: $filter, order: $order) {
      allClient {
        _id
        firstName
        lastName
        role
        middleName
        gender
        address
        city
        postCode
        phoneNumber
        dob
        thumbnail
        created_at
        email
        clientRef
      }
      pageInfo {
        currentPage
        totalPages
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

