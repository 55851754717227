import { Grid, TextField, Hidden, Divider } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledHeader, StyledHeaderContainer } from './styled';
// ----------------------------------------------------------------------
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyRegistrationData } from 'src/redux/companyregistration.slice';

export default function Step3({ setStep, step }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.companyregistration);
  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setCompanyRegistrationData(data));
    handleNextStep();
  };
  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12} container>
        <StyledHeaderContainer>
          <img src="/assets/icons/NatureofBusiness.svg" alt="Nature of Business" />
          <StyledHeader>Object(s) of the Company</StyledHeader>
        </StyledHeaderContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          multiline
          name="description"
          label="Description"
          fullWidth
          rows={4}
          required
          defaultValue={data?.description}
          {...register('description', { required: true })}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton style={{ marginRight: '1rem' }} variant="outlined" onClick={() => setStep(step - 1)}>
            Go Back
          </LoadingButton>
          <LoadingButton variant="contained" type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item container xs={12} sm={12} md={12} spacing={2}>
          <Grid item xs={12}>
            <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton variant="contained" fullWidth type="submit">
              Save & Continue
            </LoadingButton>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}
