import { useQuery } from '@apollo/client';
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { GET_USER } from 'src/graphql/queries/auth';
import { USER_ROLES } from 'src/utils/Constants';

const AuthGuard = ({ children }) => {
  const {loading, data } = useQuery(GET_USER);
  let location = useLocation();

  if (loading) return <div>Loading...</div>;

  if (data && data?.me && !data?.me?.verified) {
    return <Navigate to="/verify-email" />;
  }

  if(data && data?.me && data?.me?.verified && data?.me?.role === USER_ROLES.CLIENT) {
    return <Navigate to="/client" state={{ from: location }} replace />;
  }

  if(data && data?.me && data?.me?.verified && data?.me?.role === USER_ROLES.ADMIN) {
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthGuard;
