import { gql } from '@apollo/client';

export const UPADATE_STATUS_REPORT_STATUS = gql`
  mutation updateStatusReportApprovalStatus($input: StatusReportStatusInput!) {
    updateStatusReportApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;
