import { Container, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
// ----------------------------------------------------------------------
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import { UPDATE_PROFILE } from 'src/graphql/mutations/user';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: `${theme.palette.primary.main}`,
    height: '40px',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
    },
  },
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
}));

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

export default function ProfilePage() {
  const inputFileRef = React.useRef();
  const details = {
    firstName: '',
    lastName: '',
    thumbnail: '',
  };

  const [profileImage, _setImageProfile] = React.useState('/assets/images/avatars/avatar_default.jpg');
  const { user } = useSelector((state) => state.auth);
  const [updateUserProfile, { loading }] = useMutation(UPDATE_PROFILE);

  const setImageProfile = (newImage) => {
    _setImageProfile(newImage);
  };

  const handleOnChangeProfileImage = (event) => {
    const newImage = event.target?.files?.[0];
    updateUserProfile({
      variables: {
        input: {
          ...details,
        },
        userThumbnail: newImage,
      },
    });
    if (newImage) {
      setImageProfile(URL.createObjectURL(newImage));
    }
  };

  React.useEffect(() => {
    setImageProfile(user?.thumbnail);
  }, [user?.thumbnail]);

  return (
    <>
      <Helmet>
        <title> Profile </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item container xs={12} sm={12} md={12} justifyContent="center">
            <Stack direction="column" spacing={3}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClick={() => inputFileRef.current.click()}
                badgeContent={
                  <IconButton size="small" variant="contained" aria-label="upload picture">
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      ref={inputFileRef}
                      onChange={handleOnChangeProfileImage}
                    />
                    <Iconify icon="material-symbols:edit" color="#FFF" width={22} height={22} />
                  </IconButton>
                }
              >
                <StyledAvatar alt="Remy Sharp" src={profileImage} sx={{ width: 200, height: 200 }} />
              </StyledBadge>

              <LoadingButton size="small" variant="outlined" loading={loading}>
                Edit Profile
              </LoadingButton>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <StyledHeaderContainer>
              <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
              <Typography variant="h5">Personal Details</Typography>
            </StyledHeaderContainer>
            <Divider />
            <StyledContent>
              <StyledElementContainer>
                <Typography variant="caption">Name</Typography>
                <Typography variant="subtitle2">{`${user?.firstName} ${user?.lastName}`}</Typography>
              </StyledElementContainer>
              <Divider />
              <StyledElementContainer>
                <Typography variant="caption">Date of Birth</Typography>
                <Typography variant="subtitle2">{moment(user?.dob).format('LL')}</Typography>
              </StyledElementContainer>
              <Divider />
              <StyledElementContainer>
                <Typography variant="caption">Address</Typography>
                <Typography variant="subtitle2">{user?.address}</Typography>
              </StyledElementContainer>
              <Divider />
              <StyledElementContainer>
                <Typography variant="caption">Phone</Typography>
                <Typography variant="subtitle2">{`${user?.phoneNumber}`}</Typography>
              </StyledElementContainer>
              <Divider />
              <StyledElementContainer>
                <Typography variant="caption">Email</Typography>
                <Typography variant="subtitle2">{`${user?.email}`}</Typography>
              </StyledElementContainer>
              <Divider />
            </StyledContent>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12}>
            <StyledHeaderContainer>
              <img src="/assets/icons/CompanyDetails.svg" alt="login" />{' '}
              <Typography variant="h5">Company Details</Typography>
            </StyledHeaderContainer>
            <Divider />
            <StyledContent>
              <StyledElementContainer>
                <Typography variant="caption">Company Name</Typography>
                <Typography variant="subtitle2">Migual</Typography>
              </StyledElementContainer>
              <Divider />
              <StyledElementContainer>
                <Typography variant="caption">RC/BN Number</Typography>
                <Typography variant="subtitle2">0008775</Typography>
              </StyledElementContainer>
            </StyledContent>
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
