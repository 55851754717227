import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  container: {
    // display: 'flex',
    // justifyContent: 'center',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.5rem',
  },
  text: {
    backgroundColor: '#F1FBFA',
    color: '#7D9E9B',
    padding: '0.4rem 0.7rem',
    textAlign: 'center',
    width: 'max-content',
  },
  headerBtn: {
    backgroundColor: '#E60000',
    borderRadius: '5px',
    width: '100%',
    display: 'flex',
    color: '#fff',
    padding: '0.7rem',
    gap: '25%',
  },
  plusImg: {
    backgroundColor: '#7D0202',
    borderRadius: '6px',
    padding: '0.5rem',
  },
}));

export const Header = ({ title, notification }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <div className={classes.text}>{notification}</div>
        </div>
        <div className={classes.textContainer}>
          <div className={classes.headerBtn}>
            <img className={classes.plusImg} src="/assets/icons/PlusIcon.svg" alt="Nature of Business" />
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <p style={{ margin: '0' }}>{title}</p>
              <img src="/assets/icons/CompanyUserIcon.svg" alt="Nature of Business" />
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
