import { Grid, TextField, Hidden, Divider, Stack, Checkbox, Alert } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import {
  StyledContent,
  StyledElementContainer,
  StyledFieldLabel,
  StyledFormControlLabel,
  StyledHeader,
  StyledHeaderContainer,
} from './styled';
// ----------------------------------------------------------------------
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { clearBusinessRegistrationData, setBusinessRegistrationData } from 'src/redux/businessregistration.slice';
import { useMutation } from '@apollo/client';
import { REGISTER_BUSINESS_NAME } from 'src/graphql/mutations/businessname';
import { DISCLAIMER } from 'src/utils/Constants';

export default function Step6({ setStep, step, selectedReservedName }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.businessregistration);
  const { service } = useSelector((state) => state.service);
  const [checked, setChecked] = useState(false);
  const [registerBusinessName, { loading, data: uploadedData, error }] = useMutation(REGISTER_BUSINESS_NAME);
  const { proprietorID, proprietorPassportPhoto, proprietorSignature, ...rest } = data;

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const sendDataForProcessing = () => {
    registerBusinessName({
      variables: {
        input: {
          ...rest,
          nameReservation: selectedReservedName?._id,
          serviceAmount: service?.cost,
        },
        proprietorID,
        proprietorPassportPhoto,
        proprietorSignature,
      },
    });
  };

  useEffect(() => {
    if (uploadedData?.registerBusinessName?.data?.link) {
      dispatch(clearBusinessRegistrationData());
      window.open(uploadedData?.registerBusinessName?.data?.link, '_self');
    }
  }, [uploadedData, dispatch]);

  const { handleSubmit, register } = useForm();
  const [proprietorDob, setValue] = useState(dayjs(data?.proprietorDob));
  const onSubmit = (data) => {
    dispatch(setBusinessRegistrationData({ ...data, proprietorDob }));
    sendDataForProcessing();
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12} container>
        <StyledHeaderContainer>
          <img src="/assets/icons/ProprietorDetails.svg" alt="Proprietor Details" />
          <StyledHeader>Proprietor Details</StyledHeader>
        </StyledHeaderContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>

      {error && (
        <Grid item xs={12} sm={12} md={12}>
          <Alert severity="error">An Error occured while processing your request please try again</Alert>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorName"
          label="Name"
          fullWidth
          defaultValue={data?.proprietorName}
          {...register('proprietorName', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorTelephone"
          label="Telephone"
          fullWidth
          defaultValue={data?.proprietorTelephone}
          {...register('proprietorTelephone', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorEmail"
          label="Email"
          type="email"
          fullWidth
          defaultValue={data?.proprietorEmail}
          {...register('proprietorEmail', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorPhone"
          label="Phone"
          fullWidth
          defaultValue={data?.proprietorPhone}
          {...register('proprietorPhone', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorGender"
          label="Gender"
          fullWidth
          defaultValue={data?.proprietorGender}
          {...register('proprietorGender', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorNationality"
          label="Nationality"
          fullWidth
          defaultValue={data?.proprietorNationality}
          {...register('proprietorNationality', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledContent>
          <StyledElementContainer>
            <StyledFieldLabel>Contact Address*</StyledFieldLabel>
          </StyledElementContainer>
          <Divider />
          <TextField
            name="proprietorAddress"
            label=""
            fullWidth
            required
            defaultValue={data?.proprietorAddress}
            {...register('proprietorAddress', { required: true })}
          />
        </StyledContent>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Date of Birth"
            inputFormat="MM/DD/YYYY"
            value={proprietorDob}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledFormControlLabel
          label={DISCLAIMER}
          control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} required={true} />}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <Stack spacing={2} direction="row">
            <LoadingButton variant="outlined" onClick={() => setStep(step - 1)} loading={loading}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" type="submit" loading={loading}>
              {`Save & Pay NGN ${numberWithCommas(service?.cost)}`}
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)} loading={loading}>
            Go Back
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="contained" fullWidth type="submit" loading={loading}>
            {`Save & Pay NGN ${numberWithCommas(service?.cost)}`}
          </LoadingButton>
        </Grid>
      </Hidden>
    </Grid>
  );
}
