import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateUserProfile($input: UpdateUserInput!, $userThumbnail: Upload!) {
    updateUserProfile(input: $input, userThumbnail: $userThumbnail) {
      _id
      thumbnail
    }
  }
`;
export const UPDATE_PROFILE_NO_FILE = gql`
  mutation updateUserProfileWithoutImage($input: UpdateUserInput!) {
    updateUserProfileWithoutImage(input: $input) {
      _id
      firstname
      lastname
      email
      username
      thumbnail
    }
  }
`;
//makeUserAdmin
export const MAKE_USER_ADMIN = gql`
  mutation makeUserAdmin($userId: String!) {
    makeUserAdmin(userId: $userId) {
      _id
      firstName
      lastName
    }
  }
`;
//make normal user
export const MAKE_USER_NORMAL = gql`
  mutation makeUserNormalUser($userId: String!) {
    makeUserNormalUser(userId: $userId) {
      _id
      firstName
      lastName
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation changeUserPassword($input: ChangePasswordInput!) {
    changeUserPassword(input: $input) {
      username
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation {
    logout
  }
`;
