import { gql } from '@apollo/client';

export const CREATE_TICKET = gql`
  mutation createTicket($input: SupportTicketInput!) {
    createTicket(input: $input){
      _id
      title
      description
    }
  }
`;

export const RESOLVE_TICKET = gql`
  mutation resolveTicket($ticketId: String!) {
    resolveTicket(ticketId: $ticketId){
      _id
      title
      description
    }
  }
`;
