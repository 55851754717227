import { gql } from '@apollo/client';

export const GET_ALL_TICKETS_PAGINATED = gql`
  query getAllTicketsPaginated($page: Float, $pageSize: Float, $searchTerm: String, $filter: String, $order: Float) {
    getAllTicketsPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm, filter: $filter, order: $order) {
      data {
        _id
        title
        description
        createdBy{
          _id
          firstName
          lastName
          email
        }
        assignedTo{
          _id
          firstName
          lastName
          email
        }
        status
        createdAt
        attachments
      }
      pageInfo {
        currentPage
        totalPages
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;