import { Grid, TextField } from '@mui/material';

// ----------------------------------------------------------------------

export const NumberOfAllotedShare = ({ register }) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <TextField
        type="number"
        name="numberOfAllotedShares"
        label="Number of Allotted shares"
        fullWidth
        required
        {...register('numberOfAllotedShares', { required: true })}
      />
    </Grid>
  );
};
