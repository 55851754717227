import { Helmet } from 'react-helmet-async';
// @mui
import { Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
// components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
// mock
import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { GET_ORDER_DETAILS, GET_ORDER_PROJECTS } from 'src/graphql/queries/order';

const OrderItem = ({ item, completedTrack }) => {
  const { productName, questions, _id } = item;
  const productIndex = useMemo(() => {
    return completedTrack?.findIndex((track) => track.product._id === _id);
  }, [completedTrack, _id]);

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>{productName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {questions?.length ? (
            <>
              {questions?.map((question, index) => (
                <Grid item container xs={12} key={index} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>{question}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label=""
                      multiline
                      maxRows={4}
                      fullWidth
                      defaultValue={
                        productIndex !== -1 && completedTrack
                          ? completedTrack[productIndex]?.projectQuestionAndAnswer?.find(
                              (pqa) => pqa.question === question
                            )?.answer
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              {' '}
              <Typography>No questions</Typography>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default function AdminOrderDetails() {
  const orderId = useMemo(() => {
    return new URLSearchParams(window.location.search).get('id');
  }, []);

  const navigate = useNavigate();

  const [getOrderProjects, { data, loading }] = useLazyQuery(GET_ORDER_PROJECTS);
  const [getOrder, { data: orderData, loading: loadingOrderDetails }] = useLazyQuery(GET_ORDER_DETAILS);

  useEffect(() => {
    getOrderProjects({
      variables: { orderId },
    });

    getOrder({
      variables: { orderId },
    });
  }, [orderId, getOrderProjects, getOrder]);

  return (
    <>
      <Helmet>
        <title> Ordersd| BeeBot </title>
      </Helmet>

      <Container maxWidth="xl" sx={{ paddingTop: 5 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Order Details
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={() => navigate('/admin/orders')}>
              {' '}
              Go Back
            </Button>
            <Button variant="contained" onClick={() => window.print()}>
              Download
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={2}>
          {loading || loadingOrderDetails ? (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>User Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Typography> First Name: {orderData?.getOrder?.user?.firstName}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography> Last Name: {orderData?.getOrder?.user?.lastName}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography> Email: {orderData?.getOrder?.user?.email}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography> Phone: {orderData?.getOrder?.user?.phoneNumber}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography> Gender: {orderData?.getOrder?.user?.gender}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography> DOB: {orderData?.getOrder?.user?.dob}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography> Address: {orderData?.getOrder?.user?.address}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography> City: {orderData?.getOrder?.user?.city}</Typography>
                      </Grid>
                    </Grid>
                    <Typography></Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {orderData?.getOrder?.products?.map((item, index) => (
                <Grid key={index} item md={12} sm={12}>
                  <OrderItem item={item} completedTrack={data?.getOrderProjects} />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
