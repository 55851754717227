import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// @mui
import { Divider, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fDate } from '../../../utils/formatTime';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { MAKE_USER_ADMIN, MAKE_USER_NORMAL } from 'src/graphql/mutations/user';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export const UserRoles = {
  USER: 1,
  ADMIN: 2,
  CLIENT: 3,
};

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

UserListRequestCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const checkNullField = (field) => {
  return field ? field : '';
};

export const ComponentToPrint = React.forwardRef(({ post, changeStatus, loading }, ref) => {
  const { firstName, lastName, middleName, gender, address, city, postCode, phoneNumber, dob, email } = post;

  const FieldCheck = ({ partnerField, title, args }) => {
    return (
      partnerField && (
        <>
          <StyledElementContainer>
            <Typography variant="caption">{title}</Typography>
            {args && <Typography variant="subtitle2">{`${args}`}</Typography>}
            {!args && <Typography variant="subtitle2">{`${partnerField}`}</Typography>}
          </StyledElementContainer>
          <Divider />
        </>
      )
    );
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ px: 3 }}>
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />
            <Typography variant="h5">User Details</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <FieldCheck
              title={'Customer Full Name '}
              partnerField={`${checkNullField(firstName)} ${checkNullField(lastName)} ${checkNullField(middleName)}`}
            />
            <FieldCheck title={'Email'} partnerField={email} />
            <FieldCheck title={'Gender'} partnerField={gender} />
            <FieldCheck title={'Address'} partnerField={`${checkNullField(address)} ${checkNullField(city)}`} />
            <FieldCheck title={'PostCode'} partnerField={postCode} />
            <FieldCheck title={'Phone Number'} partnerField={phoneNumber} />
            <FieldCheck title={'Date of Birth'} partnerField={dob} />
          </StyledContent>
        </Grid>
      </Grid>
    </div>
  );
});

export default function UserListRequestCard({ post, index, changeStatus, loading }) {
  const componentRef = React.useRef();

  const { enqueueSnackbar } = useSnackbar();

  const [makeUserAdmin, { data, loading: loadingMakeUserAdmin, error }] = useMutation(MAKE_USER_ADMIN);

  //makeUserNormalUser
  const [makeUserNormalUser, { data: makeUserNormalUserData, loading: loadingMakeUserNormalUser, error: makeUserNormalUserError }] = useMutation(MAKE_USER_NORMAL);

  const { _id, firstName, lastName, middleName, created_at, clientRef, role } = post;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMakeUserAdmin = () => {
    makeUserAdmin({
      variables: {
        userId: _id,
      },
    });
  };

  const handleMakeUserNormalUser = () => {
    makeUserNormalUser({
      variables: {
        userId: _id,
      },
    });
  };

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar('Error making user an admin', { variant: 'error' });
    }
    if (data) {
      enqueueSnackbar('User is now an admin', { variant: 'success' });
    }
  }, [error, data, enqueueSnackbar]);

  React.useEffect(() => {
    if (makeUserNormalUserError) {
      enqueueSnackbar('Error making user a normal user', { variant: 'error' });
    }
    if (makeUserNormalUserData) {
      enqueueSnackbar('User is now a normal user', { variant: 'success' });
    }
  }, [makeUserNormalUserError, makeUserNormalUserData, enqueueSnackbar]);

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={`${checkNullField(firstName)} ${checkNullField(lastName)} ${checkNullField(middleName)} `}
          subheader={`Created: ${fDate(created_at)}`}
          action={
            <Stack direction="row" spacing={2}>
              <LoadingButton variant="outlined" sx={{ ml: 1 }}>
                {`App Client: ${clientRef ? clientRef : 'Beebot'}`}
              </LoadingButton>
              <LoadingButton variant="outlined" sx={{ ml: 1 }}>
                {`Role: ${role === UserRoles.ADMIN ? 'Admin' : role === UserRoles.CLIENT ? 'Client' : 'User'}`}
              </LoadingButton>
            </Stack>
          }
        />

        <CardActions disableSpacing>
          <Stack direction="row" spacing={2}>
            <LoadingButton variant="outlined" onClick={handleExpandClick} disabled={loading}>
              View Details
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              onClick={handleMakeUserAdmin}
              disabled={loadingMakeUserAdmin}
              loading={loadingMakeUserAdmin}
            >
              Make Admin
            </LoadingButton>
            <LoadingButton variant="outlined" onClick={handleMakeUserNormalUser} disabled={loading}>
              Make User
            </LoadingButton>
            <LoadingButton variant="outlined" onClick={() => changeStatus(post._id)} disabled={loading}>
              Block User
            </LoadingButton>
            <LoadingButton variant="outlined" onClick={() => changeStatus(post._id)} disabled={loading}>
              Delete User
            </LoadingButton>
          </Stack>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
