import { Helmet } from 'react-helmet-async';
// @mui
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// components
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { UPDATE_COMPANY_REGISTRATION_STATUS } from 'src/graphql/mutations/companyRegistration';
import { GET_ALL_COM_REG_CLIENT_PAGINATED } from 'src/graphql/queries/companyName';
import CompanyRegistrationRequestCard from 'src/sections/@dashboard/company-registration-requests/CompanyRegistrationRequestCard';
import debounce from 'lodash.debounce';

// ----------------------------------------------------------------------

const ORDER_BY = {
  Oldest: { name: 'Oldest', value: 1 },
  Newest: { name: 'Newest', value: -1 },
};

// ----------------------------------------------------------------------

export default function ClientCompanyNameRegistration() {
  const [page, setPage] = useState(1);
const pageSize = 20;
const [searchTerm, setSearchTerm] = useState('');
const [searchLoading, setSearchLoading] = useState(false);
const [orderBy, setOrderBy] = useState(ORDER_BY.Oldest);
const [filterStatus, setFilterStats] = useState('All');

const { data: data2, loading: loadingData2, fetchMore } = useQuery(GET_ALL_COM_REG_CLIENT_PAGINATED, {
  variables: { page, pageSize, order: orderBy.value, filter: filterStatus },
});

const [updateCompanyRegistrationApprovalStatus, { data: updatedReservation, loading }] = useMutation(
  UPDATE_COMPANY_REGISTRATION_STATUS
);
const { enqueueSnackbar } = useSnackbar();

// Helper function to fetch more data
const fetchMoreData = async (variables) => {
  await fetchMore({
    variables,
    updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult || prev,
  });
};

// Handle order change
const handleChange = ({ target: { value } }) => {
  setOrderBy(ORDER_BY[value]);
};

// Handle status change
const handleStatusChange = ({ target: { value } }) => {
  setFilterStats(value);
};

// Change status and show notification
const changeStatus = (_id, status, adminMessage) => {
  enqueueSnackbar('Status updated!');
  updateCompanyRegistrationApprovalStatus({
    variables: { input: { _id, status, adminMessage } },
  });
};

// Handle page change
const handlePageChange = async (newPage) => {
  setPage(newPage);
  await fetchMoreData({ page: newPage, pageSize, searchTerm, order: orderBy.value, filter: filterStatus });
};

// Debounced search handler
const handleSearchDebounced = useCallback(
  debounce(async (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
    setSearchLoading(true);
    await fetchMoreData({ page: 1, pageSize, searchTerm: newSearchTerm, filter: filterStatus });
    setSearchLoading(false);
  }, 200),
  [fetchMore]
);

// Handle search change
const handleSearchChange = ({ target: { value } }) => {
  setSearchTerm(value);
  setSearchLoading(false);
  handleSearchDebounced(value);
};

// Fetch more data when reservation is updated
useEffect(() => {
  if (updatedReservation) {
    setPage(1);
    fetchMoreData({ page: 1, pageSize, searchTerm, order: orderBy.value, filter: filterStatus });
  }
}, [updatedReservation, fetchMore, orderBy.value, filterStatus, searchTerm]);

  return (
    <>
      <Helmet>
        <title> Company Registration | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Company Registration Submissions
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={6} sm={6}>
            <TextField
              value={searchTerm}
              fullWidth
              placeholder="Search Reservation by name..."
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{searchLoading && <CircularProgress size={20} />}</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="statusLabel">Order By</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={orderBy.name}
                label="menuItem"
                onChange={(event) => handleChange(event)}
              >
                <MenuItem selected={orderBy === ORDER_BY.Oldest} value={ORDER_BY.Oldest.name}>
                  Oldest{' '}
                </MenuItem>
                <MenuItem selected={orderBy === ORDER_BY.Newest} value={ORDER_BY.Newest.name}>
                  Newest{' '}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="statusLabel">Filter Status</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={filterStatus}
                label="menuItem"
                onChange={(event) => handleStatusChange(event)}
              >
                {['All', 'Paid', 'Not-Paid', 'Internal-Query', 'Completed', 'Pending', 'Customer-Action', 'Not-Approved'].map(
                  (one) => (
                    <MenuItem selected={filterStatus === one} value={one} key={one}>
                      {one}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          {loadingData2 && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}

          {data2?.getAllCompanyRegByUserClient?.allClient?.map((post, index) => (
            <CompanyRegistrationRequestCard
              key={post._id}
              post={post}
              index={index}
              loading={loading}
              changeStatus={changeStatus}
            />
          ))}
          <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handlePageChange(page - 1)}
                disabled={page <= 1 || loadingData2}
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="body2">Page {page}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= data2?.getAllCompanyRegByUserClient?.pageInfo?.totalPages || loadingData2}
              >
                Next
              </Button>
            </Grid>
            {loadingData2 && <CircularProgress size={24} />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
