import { createSlice } from '@reduxjs/toolkit';

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    service: {},
    services: [],
  },
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload;
    },

    setService: (state, action) => {
      state.service = action.payload;
    },
  },
});

export const serviceReducer = serviceSlice.reducer;

export const { setServices, setService } = serviceSlice.actions;
