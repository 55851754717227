import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only', // Provides a fast response while also helping to keep cached data consistent with server data.
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all', // useQuery does not discard query response data if even occurrs
  },
  mutate: {
    errorPolicy: 'all', // useUsemutation does not discard query response data if even occurrs
  },
};

const apiUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000/graphql';

const uploadLink = createUploadLink({
  uri: `${apiUrl}`,
  credentials: 'include',
});

export const client = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
  defaultOptions,
});
