import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_BANK_ACCOUNT } from 'src/graphql/queries/bankAccount';
import { useEffect, useState } from 'react';
import { UPADATE_BUSINESS_REGISTRATION_STATUS } from 'src/graphql/mutations/businessname';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { useSnackbar } from 'notistack';
import BankAccountRequestCard from 'src/sections/@dashboard/bank-account/BankAccountRequestCard';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminBankAccount() {
  const { data, loading: loadingData } = useQuery(GET_ALL_BANK_ACCOUNT);

  const [bankAccountRequests, setBankAccountRequests] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateBusinessRegistrationApprovalStatus, { data: updatedReservation, loading }] = useMutation(
    UPADATE_BUSINESS_REGISTRATION_STATUS
  );
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setBankAccountRequests(data?.allBankAccounts);
  }, [data?.allBankAccounts]);

  useEffect(() => {
    const lowerCaseSearchTerm = search.toLowerCase();
    setFiltered(() =>
      bankAccountRequests?.filter((request) => {
        return (
          request?.companyName?.toLowerCase().includes(lowerCaseSearchTerm) ||
          request?.rcNumber?.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
          request?.tin?.toString().toLowerCase().includes(lowerCaseSearchTerm)
        );
      })
    );
    if (search.length) {
    } else {
      setFiltered(bankAccountRequests);
    }
  }, [bankAccountRequests, search]);
  useEffect(() => {
    if (updatedReservation?.updateBusinessRegistrationApprovalStatus && updateStatus) {
      setUpdateStatus(false);
      //Initailize array of objects.
      let tempReservations = [...bankAccountRequests];

      //Find index of specific object using findIndex method.
      const objIndex = tempReservations.findIndex(
        (obj) => obj._id === updatedReservation?.updateBusinessRegistrationApprovalStatus?._id
      );

      //Update object's name property.
      tempReservations[objIndex].status = updatedReservation?.updateBusinessRegistrationApprovalStatus?.status;

      //Log object to console again.
      setBankAccountRequests(tempReservations);
    }
  }, [updatedReservation?.updateBusinessRegistrationApprovalStatus, bankAccountRequests, updateStatus]);

  const changeStatus = (_id, status, adminMessage = '') => {
    setUpdateStatus(true);
    enqueueSnackbar('Status updated!');
    updateBusinessRegistrationApprovalStatus({
      variables: { input: { _id, status, adminMessage: [adminMessage] } },
    });
  };

  return (
    <>
      <Helmet>
        <title> Admin Bank Account | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Bank Account submissions
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={12} sm={12}>
            <TextField
              value={search}
              fullWidth
              placeholder="Search Reservation by Company name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          {loadingData && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {filtered?.map((post, index) => (
            <BankAccountRequestCard
              key={post._id}
              post={post}
              index={index}
              loading={loading}
              changeStatus={changeStatus}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
}
