import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
// @mui
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CHANGE_PASSWORD } from 'src/graphql/mutations/auth';
import * as Yup from 'yup';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledFormWrapper = styled('form')(({ theme }) => ({
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
  padding: '0 1rem 1rem 1rem',
}));

const StyledFormHeader = styled('div')(({ theme }) => ({
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  borderBottom: '1px solid #CFCFCF',
  marginLeft: '-1rem',
  marginRight: '-1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const StyledHeaderIconWrapper = styled('div')`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50px;
  background-color: #ffd3d3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ForgetPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const userId = new URLSearchParams(location.search).get('id');

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      password2: '',
      token: '',
      userId: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Required').min(6, 'Password is too short - should be 6 chars minimum.'),
      password2: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: () => {
      handleResetPasswrd();
    },
  });

  const [handleResetPasswrd, { loading }] = useMutation(CHANGE_PASSWORD, {
    variables: { input: { ...formik.values } },
    onCompleted: (data) => {
      console.log('handleResetPasswrd', data);
      navigate(`/`, { replace: true });
    },
    onError: (error) => {
      console.log('onError', error);
    },
  });

  useEffect(() => {
    formik.setFieldValue('token', token);
    formik.setFieldValue('userId', userId);
  }, []);

  return (
    <>
      <StyledFormWrapper onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <StyledFormHeader>
            <StyledHeaderIconWrapper>
              <img src="/assets/icons/LockIcon.svg" alt="login" />
            </StyledHeaderIconWrapper>
            <Typography
              variant="h5"
              style={{
                padding: '0.5rem',
              }}
            >
              Create New Password
            </Typography>
          </StyledFormHeader>

          <TextField
            name="password"
            label="New Password "
            type={showPassword ? 'text' : 'password'}
            id="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={Boolean(formik.touched.password && formik.errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {Boolean(formik.touched.password && formik.errors.password) && <span>{formik.errors.password}</span>}

          <TextField
            name="password2"
            label="Confirm new password"
            type={showPassword ? 'text' : 'password'}
            id="password2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password2}
            error={Boolean(formik.touched.password2 && formik.errors.password2)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {Boolean(formik.touched.password2 && formik.errors.password2) && <span>{formik.errors.password2}</span>}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            loadingIndicator="Loading…"
          >
            Change Password
          </LoadingButton>
        </Stack>
      </StyledFormWrapper>
    </>
  );
}
