import { gql } from '@apollo/client';

export const GET_ALL_ANNUAL_RETURN_REQUEST = gql`
  query {
    allAnnualReturns {
      _id
      organisationName
      organisationType
      rcNumber
      netAssets
      annualTurnover
      requestYear
      created_at
      paymentDetails {
        amount
        serviceId
        transactionId
        status
      }
      status
      authorisedID
      user {
        firstName
        lastName
        email
        gender
        address
        phoneNumber
        city
        dob
      }
      authorisedID
    }
  }
`;

export const GET_ALL_ANNUAL_RETURN_REQUEST_PAGINATED = gql`
  query PaginatedReturnsTest($page: Float!, $pageSize: Float!, $searchTerm: String) {
    allReturns(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      returns {
        _id
        organisationName
        organisationType
        rcNumber
        netAssets
        annualTurnover
        requestYear
        created_at
        paymentDetails {
          amount
          serviceId
          transactionId
          status
        }
        status
        authorisedID
        user {
          firstName
          lastName
          email
          gender
          address
          phoneNumber
          city
          dob
        }
      }
      pageInfo {
        currentPage
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;
