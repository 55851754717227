import { Alert, Checkbox, Grid, Hidden } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledFormControlLabel, StyledHeader, StyledHeaderContainer } from './styled';
// ----------------------------------------------------------------------
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CompanyRegistrationCard from 'src/components/company-registration-card/CompanyRegistrationCard';
import { PreviewCard } from 'src/components/company-registration-inputs/PreviewCard';
import { COMPANY_REG } from 'src/graphql/mutations/companyRegistration';
import { clearCompanyRegistrationData } from 'src/redux/companyregistration.slice';
import { DISCLAIMER } from 'src/utils/Constants';

export default function Step7({ setStep, step }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.companyregistration);
  const { service } = useSelector((state) => state.service);
  const [checked, setChecked] = useState(false);
  const [detailsOfDirectors, setDetailsOfDirections] = useState([]);
  const [detailsOfSecretary, setDetailsOfSecretary] = useState([]);
  const [detailsOfShareholders, setDetailsOfShareholders] = useState([]);
  const [totalIssueSharedCapital, setTotalIssueSharedCapital] = useState({});
  const [companyRegistration, { loading, data: uploadedData, error }] = useMutation(COMPANY_REG);
  const {
    nameReservation: { _id },
    businessName,
    address,
    email,
    phoneNumber,
    description,
    ...rest
  } = data;

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const defDetailsOfDirectors = rest?.detailsOfDirectors.map(
      (
        {
          name,
          dob,
          gender,
          nationality,
          country,
          state,
          lga,
          city,
          postCode,
          houseNumber,
          streetName,
          identityNumber,
          type,
          identityCard,
          signature,
        },
        indx
      ) => {
        return {
          serviceId: `testing_service_id${indx}`,
          personalDetails: {
            name,
            dob,
            gender,
            nationality,
          },
          contactDetails: {
            country,
            state,
            lga,
            city,
            postCode,
            houseNumber,
            streetName,
          },
          meansOfIdentification: {
            type,
            identityNumber,
            identityCard,
            signature,
          },
        };
      }
    );
    const defDetailsOfSecretary = rest?.detailsOfSecretary.map(
      (
        {
          name,
          dob,
          gender,
          nationality,
          country,
          state,
          lga,
          city,
          postCode,
          houseNumber,
          streetName,
          identityNumber,
          type,
          identityCard,
          signature,
        },
        indx
      ) => {
        return {
          serviceId: `testing_service_id${indx}`,
          personalDetails: {
            name,
            dob,
            gender,
            nationality,
          },
          contactDetails: {
            country,
            state,
            lga,
            city,
            postCode,
            houseNumber,
            streetName,
          },
          meansOfIdentification: {
            type,
            identityNumber,
            identityCard,
            signature,
          },
        };
      }
    );
    const defDetailsOfShareholders = rest?.detailsOfShareholders?.map(
      (
        {
          name,
          dob,
          gender,
          nationality,
          country,
          state,
          lga,
          city,
          postCode,
          houseNumber,
          streetName,
          identityNumber,
          type,
          identityCard,
          signature,
          numberOfAllotedShares,
        },
        indx
      ) => {
        return {
          serviceId: `testing_service_id${indx}`,
          numberOfAllotedShares: parseFloat(numberOfAllotedShares),
          personalDetails: {
            name,
            dob,
            gender,
            nationality,
          },
          contactDetails: {
            country,
            state,
            lga,
            city,
            postCode,
            houseNumber,
            streetName,
          },
          meansOfIdentification: {
            type,
            identityNumber,
            identityCard,
            signature,
          },
        };
      }
    );
    setDetailsOfDirections(defDetailsOfDirectors);
    setDetailsOfSecretary(defDetailsOfSecretary);
    setDetailsOfShareholders(defDetailsOfShareholders);
    setTotalIssueSharedCapital({ words: rest?.totalShareInCapitalWords, number: parseFloat(rest?.totalShareCapital) });
  }, [rest?.detailsOfDirectors, rest?.detailsOfSecretary, rest?.detailsOfShareholders, rest?.totalShareCapital, rest?.totalShareInCapitalWords]);

  const sendDataForProcessing = () => {
    companyRegistration({
      variables: {
        input: {
          nameReservation: _id,
          serviceAmount: service?.cost,
          businessName,
          address,
          email,
          phoneNumber,
          description,
          detailsOfDirectors,
          detailsOfSecretary,
          detailsOfShareholders,
          totalIssueSharedCapital,
        },
      },
    });
  };
  const { handleSubmit } = useForm();
  const onSubmit = () => {
    sendDataForProcessing();
  };

  useEffect(() => {
    if (uploadedData?.CreateNewRegistrationOfCompany?.data?.link) {
      dispatch(clearCompanyRegistrationData());
      window.open(uploadedData?.CreateNewRegistrationOfCompany?.data?.link, '_self');
    }
  }, [uploadedData, dispatch]);

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12} container>
        <StyledHeaderContainer>
          <img src="/assets/icons/NatureofBusiness.svg" alt="Nature of Business" />
          <StyledHeader>Preview</StyledHeader>
        </StyledHeaderContainer>
      </Grid>
      {error && (
        <Grid item xs={12} sm={12} md={12}>
          <Alert severity="error">An Error occured while processing your request please try again</Alert>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12}>
        <CompanyRegistrationCard
          natureOfBusiness={'Director(s)'}
          proposedName={''}
          status={1}
          setSelectedReservedName={() => null}
          children={<PreviewCard data={data?.detailsOfDirectors} />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CompanyRegistrationCard
          natureOfBusiness={'Secretary(s)'}
          proposedName={''}
          status={1}
          setSelectedReservedName={() => null}
          children={<PreviewCard data={data?.detailsOfSecretary} />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CompanyRegistrationCard
          natureOfBusiness={'Shareholder(s)'}
          proposedName={''}
          status={1}
          setSelectedReservedName={() => null}
          children={<PreviewCard data={data?.detailsOfShareholders} />}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <StyledFormControlLabel
          label={DISCLAIMER}
          control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} required={true} />}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton
            style={{ marginRight: '1rem' }}
            variant="outlined"
            onClick={() => setStep(step - 1)}
            loading={loading}
          >
            Go Back
          </LoadingButton>
          <LoadingButton variant="contained" type="submit" loading={loading}>
            {`Save & Pay NGN ${numberWithCommas(service?.cost)}`}
          </LoadingButton>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item container xs={12} sm={12} md={12} spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingButton variant="outlined" onClick={() => setStep(step - 1)} fullWidth loading={loading}>
              Go Back
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingButton variant="contained" fullWidth type="submit" loading={loading}>
              {`Save & Pay NGN ${numberWithCommas(service?.cost)}`}
            </LoadingButton>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}
