import { Box, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// sections
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Step1 from 'src/sections/name-reservation/Step1';
import Step2 from 'src/sections/name-reservation/Step2';
import Step3 from 'src/sections/name-reservation/Step3';
// import Step4 from 'src/sections/name-reservation/Step4';
// import Step5 from 'src/sections/name-reservation/Step5';

// ----------------------------------------------------------------------
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: 'linear-gradient(90deg, #9A0000 50%, #E60000 88.03%)',
  },
}));

export default function NewNameReservationPage() {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(20);
  const totalSteps = 3;
  useEffect(() => {
    setProgress((100 / totalSteps) * step);
  }, [step, totalSteps]);

  return (
    <>
      <Helmet>
        <title> New name reservation </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography>{`Step ${step} of ${totalSteps} Name Reservation`}</Typography>
              </Box>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
          </Grid>
          {step === 1 && <Step1 setStep={setStep} step={step} />}
          {step === 2 && <Step2 setStep={setStep} step={step} />}
          {step === 3 && <Step3 setStep={setStep} step={step} />}
        </Grid>
      </Container>
    </>
  );
}
