import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Pdf from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
// @mui
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fDate } from '../../../utils/formatTime';
//
import { useMutation } from '@apollo/client';
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { QUERY_COMPANY_REGISTRATION_CAC } from 'src/graphql/mutations/companyRegistration';
import { UPDATE_PAYMENT_STATUS_MANUAL } from 'src/graphql/mutations/payment';
import { NAME_SERVATIONS_STATUS } from 'src/utils/Constants';
import { onDownload } from 'src/utils/HelperFunctions';

// ----------------------------------------------------------------------

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

export const ComponentToPrint = React.forwardRef(({ post, changeStatus, loading }, ref) => {
  const {
    _id,
    businessName,
    email,
    address,
    description,
    detailsOfDirectors,
    user,
    // detailsOfSecretary,
    totalIssueSharedCapital,
    detailsOfShareholders,
    detailsOfSecretary,
    status,
    paymentDetails,
    rcNumber,
    partners,
    adminMessage,
    customerReview,
    starRating,
    queryReason,
  } = post;

  const [statusReservation, setStatusReservation] = React.useState(status);
  const [customerActionMessage, setCustomerActionMessage] = React.useState('');
  const [queryText, setQueryText] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const [queryRegistrationOfCompany, { loading: queryLoading, error: queryError, data: queriedData }] =
    useMutation(QUERY_COMPANY_REGISTRATION_CAC);
  const [
    resolvePaymentStatus,
    { loading: resolvePaymentStatusLoading, error: resolvePaymentStatusError, data: resolvePaymentStatusData },
  ] = useMutation(UPDATE_PAYMENT_STATUS_MANUAL);

  const handleUpdateUserApplication = () => {
    const linkToApplication = `https://www.app.beebot.io/pre-incorporation/registration-of-companies/${_id}`;
    //open link in new tab
    window.open(linkToApplication, '_blank');
  };

  const [paymentResolutionData, setPaymentResolutionData] = useState({
    transaction_id: _id,
    type: 'RegistrationOfCompaniesService',
    gateway: 'paystack',
    tx_ref: '',
    status: 'success',
    serviceAmount: 50000,
  });

  const handleSubmitQuery = () => {
    queryRegistrationOfCompany({
      variables: {
        id: _id,
        queryReason: queryText,
      },
    });
  };

  const handleQueryChange = (event) => {
    setQueryText(event.target.value);
  };

  const handleChange = (event) => {
    setStatusReservation(event.target.value);
  };

  const getFieldName = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg', '.webp'];
    const fileExtension = url?.toLowerCase().split('.')?.pop();
    return imageExtensions.includes(`.${fileExtension}`);
  };

  const FieldCheck = ({ partnerField, title, args }) => {
    return (
      partnerField && (
        <>
          <StyledElementContainer>
            <Typography variant="caption">{title}</Typography>
            {args && <Typography variant="subtitle2">{`${args}`}</Typography>}
            {!args && <Typography variant="subtitle2">{`${partnerField}`}</Typography>}
          </StyledElementContainer>
          <Divider />
        </>
      )
    );
  };

  const handleSubmit = (_id, statusReservation, customerActionMessage) => {
    if (statusReservation !== NAME_SERVATIONS_STATUS.CUSTOMER_ACTION) setCustomerActionMessage(null);
    changeStatus(_id, statusReservation, customerActionMessage);
    setCustomerActionMessage('');
  };

  const handleResolvePaymentStatus = () => {
    resolvePaymentStatus({
      variables: {
        input: {
          ...paymentResolutionData,
          serviceAmount: parseInt(paymentResolutionData.serviceAmount),
        },
        user: user?._id,
      },
    });
  };

  React.useEffect(() => {
    if (queryError) {
      enqueueSnackbar('Fail to send query', { variant: 'error' });
    }
  }, [queryError]);

  React.useEffect(() => {
    if (queriedData?.queryRegistrationOfCompany) {
      enqueueSnackbar('Query Sent!', { variant: 'success' });
    }
  }, [queriedData?.queryRegistrationOfCompany]);

  useEffect(() => {
    if (resolvePaymentStatusError) {
      enqueueSnackbar('Fail to resolve payment', { variant: 'error' });
    }
  }, [resolvePaymentStatusError]);

  useEffect(() => {
    if (resolvePaymentStatusData) {
      enqueueSnackbar('Payment resolved!', { variant: 'success' });
    }
  }, [resolvePaymentStatusData]);

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid item md={12} sm={12} xs={12}>
        <StyledContent>
          <Grid item md={12} sm={12} xs={12}>
            <StyledHeaderContainer>
              <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
              <Typography variant="h5">User Detail</Typography>
            </StyledHeaderContainer>
            <StyledContent>
              <FieldCheck title={`Order Number`} partnerField={_id} />
              <FieldCheck title={`Email`} partnerField={user?.email} />
              <FieldCheck title={`Phone Number`} partnerField={user?.phoneNumber} />
              <FieldCheck title={`FirstName`} partnerField={user?.firstName} />
              <FieldCheck title={`Surname`} partnerField={user?.lastName} />
              <FieldCheck title={`Address`} partnerField={user?.address} />
              <FieldCheck title={`city`} partnerField={user?.city} />
              <FieldCheck title={`Date of Birth`} partnerField={user?.dob} />
            </StyledContent>
          </Grid>
          {getFieldName('https://beebot.s3.amazonaws.com/Email_Messaging_sequence_flow1696330317699.docx')}
          {starRating && starRating !== 0 && (
            <StyledElementContainer>
              <Typography variant="caption">Star Rating</Typography>
              <Typography variant="subtitle2">{starRating}</Typography>
            </StyledElementContainer>
          )}
          {customerReview && (
            <StyledElementContainer>
              <Typography variant="caption">Customer Review</Typography>
              <Typography variant="subtitle2">{`${customerReview}`}</Typography>;
            </StyledElementContainer>
          )}
          {adminMessage?.length > 0 && (
            <StyledElementContainer>
              <Typography variant="caption">Admin Review</Typography>
              {adminMessage?.map((review) => {
                return <Typography variant="subtitle2">{`${review}`}</Typography>;
              })}
            </StyledElementContainer>
          )}
        </StyledContent>
      </Grid>

      <Grid container spacing={2}>
        {partners?.map((partner, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Partner {index + 1} Information</Typography>
              </StyledHeaderContainer>
              <Divider />

              <StyledContent>
                <FieldCheck title={`Partner's Name`} partnerField={partner.proprietorName} />

                <FieldCheck partnerField={partner.proprietorTelephone} title={'Partner Telephone Number'} />
                <FieldCheck partnerField={partner.proprietorPhone} title={'Partner Phone'} />

                <FieldCheck title={`Partner's DOB`} partnerField={partner.proprietorDob} />

                <FieldCheck partnerField={partner.proprietorEmail} title={"Partner's Email"} />

                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">{`Proprietor's ID (${partner.proprietorMeansOfIdentification})`}</Typography>
                    <IconButton color="primary" component="label" onClick={() => onDownload(partner.proprietorID)}>
                      <Download />
                    </IconButton>
                  </Box>

                  <img src={partner.proprietorID} alt={partner.proprietorName} width={200} />
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Proprietor's Passport Photo</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(partner.proprietorPassportPhoto)}
                    >
                      <Download />
                    </IconButton>
                  </Box>

                  <img src={partner.proprietorPassportPhoto} alt={partner.proprietorName} width={200} />
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Proprietor's Signature</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(partner.proprietorSignature)}
                    >
                      <Download />
                    </IconButton>
                  </Box>

                  <img src={partner.proprietorSignature} alt={partner.proprietorName} width={200} />
                </StyledElementContainer>
              </StyledContent>
            </Grid>
          );
        })}

        {/* Directors Details */}
        {detailsOfDirectors?.map((director, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Directors {index + 1} Information</Typography>
              </StyledHeaderContainer>
              <Divider />
              {/* personalDetails {
                  surname
                  firstname
                  othername
                  email
                  mobile
                  dob
                  gender
                  nationality
                }
                contactDetails {
                  country
                  state
                  lga
                  city
                  postCode
                  houseNumber
                  streetName
                }
                meansOfIdentification {
                  type
                  identityCard
                  identityNumber
                  signature
                } */}

              <StyledContent>
                <FieldCheck title={`Director's First Name`} partnerField={director.personalDetails.firstname} />
                <FieldCheck title={`Director's Last Name`} partnerField={director.personalDetails.surname} />
                <FieldCheck title={`Director's Other Name`} partnerField={director.personalDetails.othername} />
                <FieldCheck title={`Director's Gender`} partnerField={director.personalDetails.gender} />
                <FieldCheck title={`Director's Email`} partnerField={director.proprietorName} />
                <FieldCheck title={`Director's Phone Number`} partnerField={director.proprietorName} />
                <FieldCheck title={`Director's DOB`} partnerField={director.personalDetails.dob} />
                <FieldCheck title={`Director's Nationality`} partnerField={director.personalDetails.nationality} />
                {/* contact details */}
                <FieldCheck title={`Director's Country`} partnerField={director.contactDetails.country} />
                <FieldCheck title={`Director's State`} partnerField={director.contactDetails.state} />
                <FieldCheck title={`Director's LGA`} partnerField={director.contactDetails.lga} />
                <FieldCheck title={`Director's City`} partnerField={director.contactDetails.city} />
                <FieldCheck title={`Director's Post Code`} partnerField={director.contactDetails.postCode} />
                <FieldCheck title={`Director's House Number`} partnerField={director.contactDetails.houseNumber} />
                <FieldCheck title={`Director's Street Name`} partnerField={director.contactDetails.streetName} />{' '}
                <FieldCheck
                  title={`Director's Name`}
                  partnerField={`${director.personalDetails.othername} ${director.personalDetails.firstname}`}
                />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">{`Director's ID (${director.meansOfIdentification.type})`}</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(director.meansOfIdentification.identityCard)}
                    >
                      <Download />
                    </IconButton>
                  </Box>
                  {getFieldName(director.meansOfIdentification.identityCard) ? (
                    <img
                      src={director.meansOfIdentification.identityCard}
                      alt={director.meansOfIdentification.identityCard}
                      width={200}
                    />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(director.meansOfIdentification.identityCard)}
                    />
                  )}
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Director's Signature</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(director.meansOfIdentification.signature)}
                    >
                      <Download />
                    </IconButton>
                  </Box>
                  {getFieldName(director.meansOfIdentification.signature) ? (
                    <img
                      src={director.meansOfIdentification.signature}
                      alt={director.meansOfIdentification.signature}
                      width={200}
                    />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(director.meansOfIdentification.signature)}
                    />
                  )}
                </StyledElementContainer>
              </StyledContent>
            </Grid>
          );
        })}

        {/* Shareholder Details */}
        {detailsOfShareholders?.map((shareHolder, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Shareholder {index + 1} Information</Typography>
              </StyledHeaderContainer>
              <Divider />

              <StyledContent>
                <FieldCheck
                  title={`ShareHolder's First Name`}
                  partnerField={` ${shareHolder.personalDetails.firstname}`}
                />
                <FieldCheck
                  title={`ShareHolder's Last Name`}
                  partnerField={` ${shareHolder.personalDetails.surname}`}
                />

                <FieldCheck title={`Shareholder's Other Name`} partnerField={shareHolder.personalDetails.othername} />
                <FieldCheck title={`Shareholder's Gender`} partnerField={shareHolder.personalDetails.gender} />
                <FieldCheck
                  title={`Shareholder's Nationality`}
                  partnerField={shareHolder.personalDetails.nationality}
                />
                <FieldCheck title={`Shareholder's Email`} partnerField={shareHolder.proprietorName} />
                <FieldCheck title={`Shareholder's Phone Number`} partnerField={shareHolder.proprietorName} />
                <FieldCheck title={`Number of shares`} partnerField={shareHolder.numberOfAllotedShares} />
                <FieldCheck title={`Shareholder's DOB`} partnerField={shareHolder.personalDetails.dob} />
                {/* contact details */}
                <FieldCheck title={`Shareholder's Country`} partnerField={shareHolder.contactDetails.country} />
                <FieldCheck title={`Shareholder's State`} partnerField={shareHolder.contactDetails.state} />
                <FieldCheck title={`Shareholder's LGA`} partnerField={shareHolder.contactDetails.lga} />
                <FieldCheck title={`Shareholder's City`} partnerField={shareHolder.contactDetails.city} />
                <FieldCheck title={`Shareholder's Post Code`} partnerField={shareHolder.contactDetails.postCode} />
                <FieldCheck
                  title={`Shareholder's House Number`}
                  partnerField={shareHolder.contactDetails.houseNumber}
                />
                <FieldCheck title={`Shareholder's Street Name`} partnerField={shareHolder.contactDetails.streetName} />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">{`Shareholder's ID (${shareHolder.meansOfIdentification.type})`}</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(shareHolder.meansOfIdentification.identityCard)}
                    >
                      <Download />
                    </IconButton>
                  </Box>
                  {getFieldName(shareHolder.meansOfIdentification.identityCard) ? (
                    <img
                      src={shareHolder.meansOfIdentification.identityCard}
                      alt={shareHolder.meansOfIdentification.identityCard}
                      width={200}
                    />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(shareHolder.meansOfIdentification.identityCard)}
                    />
                  )}
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Shareholder's Signature</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(shareHolder.meansOfIdentification.signature)}
                    >
                      <Download />
                    </IconButton>
                  </Box>

                  {getFieldName(shareHolder.meansOfIdentification.signature) ? (
                    <img
                      src={shareHolder.meansOfIdentification.signature}
                      alt={shareHolder.meansOfIdentification.signature}
                      width={200}
                    />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(shareHolder.meansOfIdentification.signature)}
                    />
                  )}
                </StyledElementContainer>
              </StyledContent>
            </Grid>
          );
        })}
        {/* Secretary Details */}
        {detailsOfSecretary?.map((secretary, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Secretary {index + 1} Information</Typography>
              </StyledHeaderContainer>
              <Divider />

              <StyledContent>
                {/* surname
                    firstname
                    othername
                    email
                    mobile
                    dob
                    nationality
                    gender
                    city
                    postCode
                    houseNumber
                    streetName
                    country
                    state
                    lga
                    firmName
                    rcNumber
                    repName
                    type
                    sectype
                    signature
                    identityCard */}
                <FieldCheck title={`Secretary's Surname`} partnerField={secretary.surname} />
                <FieldCheck title={`Secretary's First Name`} partnerField={secretary.firstname} />
                <FieldCheck title={`Secretary's Other Name`} partnerField={secretary.othername} />
                <FieldCheck title={`Secretary's Gender`} partnerField={secretary.gender} />
                <FieldCheck title={`Secretary's Email`} partnerField={secretary.email} />
                <FieldCheck title={`Secretary's Phone Number`} partnerField={secretary.mobile} />
                <FieldCheck title={`Secretary's DOB`} partnerField={secretary.dob} />
                <FieldCheck title={`Secretary's Nationality`} partnerField={secretary.nationality} />
                <FieldCheck title={`Secretary's City`} partnerField={secretary.city} />
                <FieldCheck title={`Secretary's Post Code`} partnerField={secretary.postCode} />
                <FieldCheck title={`Secretary's House Number`} partnerField={secretary.houseNumber} />
                <FieldCheck title={`Secretary's Street Name`} partnerField={secretary.streetName} />
                <FieldCheck title={`Secretary's Country`} partnerField={secretary.country} />
                <FieldCheck title={`Secretary's State`} partnerField={secretary.state} />
                <FieldCheck title={`Secretary's LGA`} partnerField={secretary.lga} />
                <FieldCheck title={`Secretary's Firm Name`} partnerField={secretary.firmName} />
                <FieldCheck title={`Secretary's RC Number`} partnerField={secretary.rcNumber} />
                <FieldCheck title={`Secretary's Rep Name`} partnerField={secretary.repName} />
                <FieldCheck title={`Secretary's Type`} partnerField={secretary.type} />
                <FieldCheck title={`Secretary's Sectype`} partnerField={secretary.sectype} />

                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">{`Secretary's ID (${secretary.type})`}</Typography>
                    <IconButton color="primary" component="label" onClick={() => onDownload(secretary.identityCard)}>
                      <Download />
                    </IconButton>
                  </Box>

                  {getFieldName(secretary.identityCard) ? (
                    <img src={secretary.identityCard} alt={secretary.identityCard} width={200} />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(secretary.identityCard)}
                    />
                  )}
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Secretary's Signature</Typography>
                    <IconButton color="primary" component="label" onClick={() => onDownload(secretary.signature)}>
                      <Download />
                    </IconButton>
                  </Box>
                  {getFieldName(secretary.signature) ? (
                    <img src={secretary.signature} alt={secretary.signature} width={200} />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(secretary.signature)}
                    />
                  )}
                </StyledElementContainer>
              </StyledContent>
            </Grid>
          );
        })}

        {/* Business Details */}
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5"> Business name details</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <StyledElementContainer>
              <Typography variant="caption">Company Name</Typography>
              <Typography variant="subtitle2">{businessName}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Company Email</Typography>
              <Typography variant="subtitle2">{email}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Total Share Capital</Typography>
              <Typography variant="subtitle2">{totalIssueSharedCapital?.number}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Company Rc Number</Typography>
              <Typography variant="subtitle2">{rcNumber}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Business Address</Typography>
              <Typography variant="subtitle2">{address}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Nature of Business</Typography>
              <Typography variant="subtitle2">{description}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Payment Status</Typography>
              <Typography variant="subtitle2">
                {paymentDetails ? `NGN ${paymentDetails?.amount} ${paymentDetails?.status}` : 'Not Paid'}
              </Typography>
            </StyledElementContainer>
          </StyledContent>
          <Stack spacing={2} sx={{ mt: 2 }} direction="column">
            <FormControl>
              <InputLabel id="statusLabel"> Order Status</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={statusReservation}
                label="menuItem"
                onChange={(event) => handleChange(event)}
                sx={{ minWidth: 200 }}
              >
                <MenuItem value={NAME_SERVATIONS_STATUS.CUSTOMER_ACTION}>Customer Action Required </MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.PROCESSING}>Processed</MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.COMPLETED}>Completed</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="outlined-multiline-static"
              label="Extra Message"
              multiline
              rows={2}
              value={customerActionMessage}
              onChange={(event) => setCustomerActionMessage(event.target.value)}
            />

            <LoadingButton
              variant="contained"
              fullWidth={false}
              onClick={() => handleSubmit(_id, statusReservation, customerActionMessage)}
              loading={loading}
            >
              Update Status
            </LoadingButton>
          </Stack>
        </Grid>
        {queryReason && (
          <Grid item xs={12}>
            <Typography paragraph>
              <strong>Query Reason:</strong>
            </Typography>
            <Typography paragraph>{queryReason}</Typography>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label="Query"
            value={queryText}
            onChange={handleQueryChange}
            multiline
            rows={2}
            variant="outlined"
            margin="normal"
          />
          <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
            <Button onClick={handleUpdateUserApplication} variant="outlined">
              Edit Application
            </Button>
            <Button onClick={handleSubmitQuery} variant="outlined">
              {queryLoading ? 'Loading...' : 'Query'}
            </Button>
          </Stack>
        </Grid>
        {!paymentDetails && (
          <Grid item xs={12}>
            <Typography paragraph>
              <strong>Payment Resolution:</strong>
            </Typography>
            <TextField
              fullWidth
              label="Transaction ID"
              value={paymentResolutionData.tx_ref}
              onChange={(e) => setPaymentResolutionData({ ...paymentResolutionData, tx_ref: e.target.value })}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Amount"
              type="number"
              value={paymentResolutionData.serviceAmount}
              onChange={(e) => setPaymentResolutionData({ ...paymentResolutionData, serviceAmount: e.target.value })}
              variant="outlined"
              margin="normal"
            />
            <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
              <Button onClick={handleResolvePaymentStatus} variant="outlined">
                {resolvePaymentStatusLoading ? 'Loading...' : 'Resolve Payment'}
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </div>
  );
});

CompanyRegistrationRequestCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function CompanyRegistrationRequestCard({ post, index, changeStatus, loading }) {
  const { businessName, created_at, status, user, paymentDetails, queryReason } = post;

  const [expanded, setExpanded] = React.useState(false);
  const componentRef = React.useRef();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <img
                src={
                  status === NAME_SERVATIONS_STATUS.PENDING
                    ? '/assets/icons/statuses/Pending.svg'
                    : paymentDetails
                    ? '/assets/icons/statuses/Approved.svg'
                    : '/assets/icons/statuses/Denied.svg'
                }
                width={20}
                alt="status"
              />
            </IconButton>
          }
          title={`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
          subheader={`Submitted: ${fDate(created_at)}`}
        />
        <CardActions disableSpacing>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Order Number: ${post._id}`}
          </LoadingButton>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Company Name: ${businessName}`}
          </LoadingButton>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Status: ${status}`}
          </LoadingButton>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {paymentDetails ? `NGN ${paymentDetails?.amount} ${paymentDetails?.status}` : 'Not Paid'}
          </LoadingButton>
          {queryReason && (
            <LoadingButton variant="outlined" sx={{ ml: 1 }}>
              Query Internal
            </LoadingButton>
          )}
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button onClick={handlePrint}>Print</Button>
                <Pdf targetRef={componentRef} filename="code-example.pdf">
                  {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                </Pdf>
              </Stack>

              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
