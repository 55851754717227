import { Helmet } from 'react-helmet-async';
import {Grid, Button, Container, Stack, Typography, TextField, CircularProgress, InputAdornment} from '@mui/material';
import Iconify from '../../components/iconify';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_TIN_STATUS } from 'src/graphql/mutations/tin';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import {GET_ALL_TIN_PAGINATED} from "../../graphql/queries/tin";
import TinRequestCard from "../../sections/@dashboard/tin/TinRequestCard";
import {GET_ALL_BUSINESS_STARTER} from "../../graphql/queries/businessStarter";
import {UPDATE_BUSINESS_STARTER_TOOLKIT} from "../../graphql/mutations/businessStarter";
import BusinessStarterToolkitRequestCard
    from "../../sections/@dashboard/businesstoolkit/BusinessStarterToolkitRequestCard";

export default function AdminBusinessStarter() {
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const { data: data2, loading: loadingData2, fetchMore } = useQuery(GET_ALL_BUSINESS_STARTER, {
        variables: { page, pageSize },
    });

    const [annualReturnRequests, setAnnualReturnRequests] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [updateBusinessRegistrationApprovalStatus, { data: updatedReservation, loading }] =
        useMutation(UPDATE_BUSINESS_STARTER_TOOLKIT);
    console.log(data2)
    const { enqueueSnackbar } = useSnackbar();

    const [filtered, setFiltered] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false); // New state for search loading

    useEffect(() => {
        if (data2 && data2?.allBusinessStarterToolkitPaginated) {
            setAnnualReturnRequests(data2.allBusinessStarterToolkitPaginated.returns);
        }
    }, [data2]);

    useEffect(() => {
        setFiltered(annualReturnRequests);
    }, [annualReturnRequests,]);

    useEffect(() => {
        console.log(updatedReservation)
        if (updatedReservation?.updateBusinessRegistrationApprovalStatus && updateStatus) {
            setUpdateStatus(false);
            let tempReservations = [...annualReturnRequests];
            const objIndex = tempReservations.findIndex(
                (obj) => obj._id === updatedReservation?.updateBusinessRegistrationApprovalStatus?._id
            );
            tempReservations[objIndex].status = updatedReservation?.updateBusinessRegistrationApprovalStatus?.status;
            setAnnualReturnRequests(tempReservations);
        }
    }, [updatedReservation?.updateBusinessRegistrationApprovalStatus, annualReturnRequests, updateStatus]);

    const changeStatus = async (_id, status, adminMessage) => {
        setUpdateStatus(true);
        enqueueSnackbar('Status updated!');
        await updateBusinessRegistrationApprovalStatus({
            variables: { input: { _id, status, adminMessage } },
        });
    };

    const handlePageChange = async (newPage) => {
        setPage(newPage);
        await fetchMore({
            variables: { page: newPage, pageSize, searchTerm },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
            },
        });
    };

    const handleSearchDebounced = debounce(async (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setPage(1);
        setSearchLoading(true);
        await fetchMore({
            variables: { page, pageSize, searchTerm: newSearchTerm },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                console.log({fetchMoreResult, newSearchTerm} )
                return fetchMoreResult;
            },
        });
        setSearchLoading(false);

    }, 200);

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        setSearchLoading(false);
        handleSearchDebounced(newSearchTerm);
    };

    return (
        <>
            <Helmet>
                <title> Business Starter Toolkit | BeeBot </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Business Starter Toolkit
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Record
                    </Button>
                </Stack>

                <Grid container spacing={3}>
                    <Grid item md={12} sm={12}>
                        <TextField
                            value={searchTerm}
                            fullWidth
                            placeholder="Search Reservation by Company name..."
                            onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchLoading && <CircularProgress size={20} />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    {filtered?.map((post, index) => (
                        <BusinessStarterToolkitRequestCard key={post._id} post={post} index={index} loading={loading} changeStatus={changeStatus} />
                    ))}

                    <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <Button variant="outlined" onClick={() => handlePageChange(page - 1)} disabled={page <= 1 || loadingData2}>
                                Previous
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">Page {page}</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={() => handlePageChange(page + 1)}
                                disabled={page >= data2?.allBusinessStarterToolkitPaginated.pageInfo?.totalPages || loadingData2}
                            >
                                Next
                            </Button>
                        </Grid>
                        {loadingData2 && <CircularProgress size={24} />}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
