import { Helmet } from 'react-helmet-async';
// @mui
import {Grid, Button, Container, Stack, Typography, TextField, InputAdornment, CircularProgress} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { useMutation, useQuery } from '@apollo/client';
import {GET_ALL_CHANGE_OF_DIRECTORS, GET_ALL_CHANGE_OF_DIRECTORS_PAGINATED} from 'src/graphql/queries/companyName';
import { UPDATE_CHANGE_OF_DIRECTOR_STATUS } from 'src/graphql/mutations/companyRegistration';

import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ChangeOfDirectorsRequestCards from 'src/sections/@dashboard/change of directors/ChangeOfDirectors';
import {debounce} from "lodash";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminChangeOfDirectors() {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const { data: data2, loading: loadingData2, fetchMore } = useQuery(GET_ALL_CHANGE_OF_DIRECTORS_PAGINATED, {
    variables: { page, pageSize },
  });
  const { data, loading: loadingData } = useQuery(GET_ALL_CHANGE_OF_DIRECTORS);

  const [changeOfDirectorRequests, setChangeOfDirectorRequests] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateBusinessRegistrationApprovalStatus, { data: updatedReservation, loading }] = useMutation(
    UPDATE_CHANGE_OF_DIRECTOR_STATUS
  );
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (data2 && data2?.getAllChangeOfDirectorsPaginated) {
      setChangeOfDirectorRequests(data2.getAllChangeOfDirectorsPaginated.returns);
    }
  }, [data2]);


  useEffect(() => {
    setFiltered(changeOfDirectorRequests);
  }, [changeOfDirectorRequests,]);

  useEffect(() => {
    if (updatedReservation?.updateBusinessRegistrationApprovalStatus && updateStatus) {
      setUpdateStatus(false);
      //Initailize array of objects.
      let tempReservations = [...changeOfDirectorRequests];

      //Find index of specific object using findIndex method.
      const objIndex = tempReservations.findIndex(
        (obj) => obj._id === updatedReservation?.updateBusinessRegistrationApprovalStatus?._id
      );

      //Update object's name property.
      tempReservations[objIndex].status = updatedReservation?.updateBusinessRegistrationApprovalStatus?.status;

      //Log object to console again.
      setChangeOfDirectorRequests(tempReservations);
    }
  }, [updatedReservation?.updateBusinessRegistrationApprovalStatus, updateStatus, changeOfDirectorRequests]);


  const changeStatus = (_id, status, adminMessage) => {
    setUpdateStatus(true);
    enqueueSnackbar('Status updated!');
    updateBusinessRegistrationApprovalStatus({
      variables: { input: { _id, status, adminMessage } },
    });
  };


  const handlePageChange = async (newPage) => {
    setPage(newPage);
    await fetchMore({
      variables: { page: newPage, pageSize, searchTerm },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  };

  const handleSearchDebounced = debounce(async (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
    setSearchLoading(true);
    await fetchMore({
      variables: { page, pageSize, searchTerm: newSearchTerm },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        console.log({fetchMoreResult, newSearchTerm} )
        return fetchMoreResult;
      },
    });
    setSearchLoading(false);

  }, 200);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setSearchLoading(false);
    handleSearchDebounced(newSearchTerm);
  };

  return (
    <>
      <Helmet>
        <title> Annual Returns | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Change of Directors
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={12} sm={12}>
            {' '}
            <TextField
                value={searchTerm}
                fullWidth
                placeholder="Search Reservation by Company name..."
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                        {searchLoading && <CircularProgress size={20} />}
                      </InputAdornment>
                  ),
                }}
            />
          </Grid>

          {filtered?.map((post, index) => (
            <ChangeOfDirectorsRequestCards
              key={post._id}
              post={post}
              index={index}
              loading={loading}
              changeStatus={changeStatus}
            />
          ))}

          <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button variant="outlined" onClick={() => handlePageChange(page - 1)} disabled={page <= 1 || loadingData2}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="body2">Page {page}</Typography>
            </Grid>
            <Grid item>
              <Button
                  variant="outlined"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= data2?.getAllChangeOfDirectorsPaginated.pageInfo?.totalPages || loadingData2}
              >
                Next
              </Button>
            </Grid>
            {loadingData2 && <CircularProgress size={24} />}
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
