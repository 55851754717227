import { gql } from '@apollo/client';

export const RESERVE_NAME = gql`
  mutation CreateNameReservation($input: NameReservationInput!) {
    CreateNameReservation(input: $input) {
      status
      message
      data {
        link
      }
    }
  }
`;
export const NAME_RESERVATIONS = gql`
  query {
    nameReservations {
      _id
      classification
      specificType
      NatureOfBusiness
      reasonsForAvailabilitySearch
      additionalComment
      paymentDetails {
        serviceId
      }
      proposedNames {
        optionOne
        optionTwo
      }
      status
      created_at
    }
  }
`;
export const UPDATE_NAME_RESERVATION_STATUS = gql`
  mutation updateNameReservationApprovalStatus($input: NameReservationStatusInput!) {
    updateNameReservationApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;
