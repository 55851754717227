import { Grid, TextField, Hidden, Divider } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledContent, StyledElementContainer, StyledFieldLabel } from './styled';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyRegistrationData } from 'src/redux/companyregistration.slice';
// ----------------------------------------------------------------------

export default function Step1({ setStep, step, selectedReservedName }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.companyregistration);

  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setCompanyRegistrationData(data));
    handleNextStep();
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="businessName"
          label="Business Name"
          fullWidth
          required
          {...register('businessName', { required: true })}
          defaultValue={selectedReservedName?.proposedNames?.optionOne}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledContent>
          <StyledElementContainer>
            <StyledFieldLabel>Address*</StyledFieldLabel>
          </StyledElementContainer>
          <Divider />
          <TextField
            name="address"
            label=""
            fullWidth
            required
            defaultValue={data?.address}
            {...register('address', { required: true })}
          />
        </StyledContent>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledContent>
          <StyledElementContainer>
            <StyledFieldLabel>Email*</StyledFieldLabel>
          </StyledElementContainer>
          <Divider />
          <TextField
            name="email"
            type="email"
            label=""
            fullWidth
            required
            defaultValue={data?.email}
            {...register('email', { required: true })}
          />
        </StyledContent>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="phoneNumber"
          label="Phone"
          fullWidth
          required
          defaultValue={data?.phoneNumber}
          {...register('phoneNumber', { required: true })}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="contained" type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="contained" fullWidth type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
    </Grid>
  );
}
