import { gql } from '@apollo/client';

export const GET_ALL_BANK_ACCOUNT = gql`
  query {
    allBankAccounts {
      _id
      user {
        _id
        firstName
        lastName
        email
        middleName
        gender
        address
        city
        postCode
        phoneNumber
        dob
        created_at
        verified
        thumbnail
        role
      }
      numberOfDirectors
      numberOfSignatory
      accountApplication
      cacCertificate
      companyAddress
      companyName
      incorporationDocument
      nearestLandmark
      rcNumber
      statusReport
      tin
      utilityBill
      accountType
      directors {
        surname
        firstname
        othername
        email
        mobile
        dob
        nationality
        address
        isSignatory
        bvn
        gender
        country
        state
        lga
        identityDocument
        passportPhoto
        dirSignature
      }

      nextOfKins {
        surname
        firstname
        othername
        email
        mobile
        nationality
        address
        nextOfKinRelationship
        gender
        country
        state
        lga
      }
      refNameAccountOne
      refNameAccountTwo
      refNameBankOne
      refNameBankTwo
      refNameOne
      refNameTwo
      adminMessage
      customerReview
      starRating
      status
      created_at
    }
  }
`;
