export const PARENTSERVICES = [
  {
    title: 'Registration of business',
    icon: '/assets/images/services/business-registration.png',
    link: '/client/business-registration',
    services: [
      {
        title: 'Application for Name Reservation',
        cost: 17000,
        icon: '/assets/icons/application_for_name_reservation.svg',
        link: '/client/name-reservation/new',
      },
      {
        title: 'Registration of Business',
        cost: 14000,
        icon: '/assets/icons/registration_of_business.svg',
        link: '/client/business-registration/new',
      },
      {
        title: 'Registration of Companies (LTD, ULTD & GTEE) ',
        cost: 14000,
        icon: '/assets/icons/application_registration_of_companies.svg',
        link: '/client/company-registration/new',
      },
    ],
  },
  {
    title: 'Company Registraion',
    icon: '/assets/images/services/name-reservation.png',
    link: '/client/business-registration',
    services: [
      {
        title: 'Application for Increase in Share Capital',
        cost: 714000,
        icon: '/assets/icons/application_for_increase_in_share _capital.svg',
        link: '/',
      },
      {
        title: 'Application for reduction in Share Capital',
        cost: 714000,
        icon: '/assets/icons/application_for_reduction_in_share_capital.svg',
        link: '/',
      },
      {
        title: 'Registration of Incorporated Trustees Flow',
        cost: 714000,
        icon: '/assets/icons/registration_of_incorporated_trustees_flow.svg',
        link: '/',
      },
      {
        title: 'Application for Status Report',
        cost: 714000,
        icon: '/assets/icons/application_for_status_report.svg',
        link: '/',
      },
      {
        title: 'Application for Conversion or Re-registration',
        cost: 714000,
        icon: '/assets/icons/application_for_conversion_or_re_registration.svg',
        link: '/',
      },
    ],
  },
  // {
  //   title: 'Logo Design',
  //   cost: 714000,
  //   icon: '/assets/images/products/product_6.jpg',
  //   link: '/client/business-registration',
  // },
  // {
  //   title: 'Web Design',
  //   cost: 714000,
  //   icon: '/assets/images/products/product_8.jpg',
  //   link: '/client/business-registration',
  // },
];

export const RESERVED_NAMES = [
  {
    natureOfBusiness: 'Registration of business',
    proposedName: 'Sales of Phones and accessories',
    _id: '6381e529e296e2ec2ca56593',
    status: 1,
  },
  {
    natureOfBusiness: 'Registration of business',
    proposedName: 'Sales of Phones and accessories',
    _id: '6381e529e296e2ec2ca56hhhyug593',
    status: 0,
  },
  {
    natureOfBusiness: 'Registration of business',
    proposedName: 'Sales of Phones and accessories',
    _id: '6381e529e296e2ec78782ca56ggg593',
    status: 0,
  },
];

export const CHANGE_OF_DIRECTOR_OPTIONS = [
  {
    value: 'lenco',
    title: 'Lenco Micro Finance Bank',
    icons: ['/icons/RegistrationOfCompaniesIcon.svg'],
  },
  {
    value: 'stambic',
    title: 'Stambic IBTC Bank',
    icons: ['/icons/RegistrationOfCompaniesIcon.svg'],
  },
];

export const NAME_SERVATIONS_STATUS = {
  PROCESSING: 1,
  COMPLETED: 2,
  PENDING: 0,
  CUSTOMER_ACTION: 3,
  CANCELLED: 4,
  NOT_APPROVED: 5,
};
export const USER_ROLES = {
  ADMIN: 2,
  CLIENT: 3,
  USER: 1,
  PENDING: 0,
};

export const DISCLAIMER =
  'You acknowledge that payment for this request is non refundable and that Beebot will not be responsible for any error in the information provided by you above.';
export const CLASSIFICATIONS = [
  'BUSINESS NAME',
  'COMPANY',
  // 'INCORPORATED TRUSTEE',
  // 'LIMITED PARTNERSHIP',
  // 'LIMITED LIABILITY PARTNERSHIP',
];

export const SPECIFIC_TYPE = ['BUSINESS NAME - SOLE PROPRIETOR', 'BUSINESS NAME - PARTNERSHIP'];
export const SPECIFIC_TYPE_BUSINESS = ['BUSINESS NAME - SOLE PROPRIETOR', 'BUSINESS NAME - PARTNERSHIP'];
export const SPECIFIC_TYPE_COMPANY = ['LIMITED BY SHARES', 'BUSINESS NAME - PARTNERSHIP'];
export const REASON_FOR_AVAILABILITY_SEARCH = [
  'NEW INCORPORATION/REGISTRATION',
  'GROUP HOLDINGS/CONSORTIUM',
  'CHANGE OF NAME',
  'NAME SUBSTITUTION',
];
export const NATURE_OF_BUSINESS_CATEGORY = [
  'ACCOMODATION AND FOOD SERVIVES ACTIVITIES',
  'ACTIVITIES OF EXTRATERRITORIAL ORGANISATION AND BODIES',
  'ACTIVITIES OF HOUSE AS EMPLOYMENT UNDIFFERENTIATED GOODS-AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE',
  'ADMINISTRATIVE AND SUPPORT SERVICES ACTIVITIES',
  'ART ENTERTAINMENT AND RECREATION',
  'AGRICULTURE FORESTRY & FISHING',
  'COMMUNITY BASED ASSOCIATION',
  'CULTURAL BASED ASSOCIATION',
  'CONSTRUCTION',
  'EDUCATION',
  'FAITH BASED ASSOCIATION',
  'FINANCIAL AND INSURANCE ACTIVITIES',
  'FOUNDATION BASED ASSOCIATION',
  'HUMAN HEALTH AND SOCIAL WORK ACTIVITIES',
  'INFORMATION AND COMMUNICATION',
  'MANUFACTURING',
  'MINING AND QUARRYING',
  'OTHER SERVICE ACTIVITIES',
  'OTHERS',
  'POWER',
  'PROFESSIONAL SCIENTIFIC AND TECHNICAL ACTIVITIES',
  'PUBLIC ADMINISTRATION AND DEFENCE-DEFENCE. COMPULSORY SOCIAL SECURITY',
  'REAL ESTATE ACTIVITIES',
  'REPAIRS OF MOTORVEHICLES AND MOTORCYCLES',
  'SOCIAL CLUBS BASED ASSOCIATION',
  'SPORTING BASED ASSOCIATION',
  'ANIMAL HUSBANDRY SERVICES',
  'AQUARIUM SERVICES',
  'AVIARY SERVICES',
  'CROP AND ANIMAL PRODUCTION,HUNTING AND RELATED SERVICES ACTIVITIES',
  'FISH FARMING/AQUACULTURE',
  'FISHING & AQUACULTURE',
  'FOOD PRODUCTION AND PROCESSING SERVICES',
  'FORESTRY & LOGGING',
  'GARDENING SERVICES',
  'HONEY PROCESSING',
  'HORTICULTURAL SERVICES',
  'KENNEL SERVICES',
  'LIVESTOCK FEEDS PRODUCTION AND DISTRIBUTION',
  'LIVESTOCK MANAGEMENT SERVICES',
  'TRANSPORTATION',
  'WATERSUPPLY SEWERAGE WASTEMANAGEMENT AND REMEDIATION ACTIVITIES',
  'WHOLESALE AND RETAIL TRADE: REPAIR OF MOTOR VEHICLES AND MOTOR VEHICLES AND MOTORCYCLES',
];
