import { gql } from '@apollo/client';

export const GET_BUSINESS_NAME_REGISTRATION_REQUEST = gql`
  query {
    businessNames {
      _id
      businessName
      natureOfBusinessDescription
      proprietorID
      paymentStatus
      proprietorDob
      status
      created_at
    }
  }
`;
export const GET_ALL_BUSINESS_NAME_REGISTRATION_REQUEST = gql`
  query {
    allBusinessNames {
      _id
      nameReservation
      businessName
      businessEmail
      businessPhone
      businessAddress
      natureOfBusinessDescription
      created_at
      status
      adminMessage
      customerReview
      starRating
      paymentDetails {
        serviceId
        status
        transactionId
        amount
      }
      partners {
        proprietorName
        proprietorTelephone
        gender
        proprietorEmail
        proprietorPhone
        proprietorGender
        proprietorNationality
        proprietorAddress
        proprietorDob
        natureOfBusinessDescription
        proprietorMeansOfIdentification
        proprietorID
        proprietorPassportPhoto
        proprietorSignature
      }
      user {
        email
        firstName
        lastName
        address
        postCode
        phoneNumber
        dob
      }
    }
  }
`;
