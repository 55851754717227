import { gql } from '@apollo/client';

export const GET_ALL_COMPANY_NAME_REGISTRATION_REQUEST = gql`
  query {
    getAllCompanyRegistration {
      _id
      businessName
      address
      email
      description
      queryReason
      created_at
      user {
        _id
        firstName
        lastName
        gender
        address
        city
        postCode
        phoneNumber
        dob
        created_at
        verified
        thumbnail
        email
      }
      detailsOfDirectors {
        _id
        serviceId
        personalDetails {
          surname
          firstname
          othername
          email
          mobile
          dob
          gender
          nationality
        }
        contactDetails {
          country
          state
          lga
          city
          postCode
          houseNumber
          streetName
        }
        meansOfIdentification {
          type
          identityCard
          identityNumber
          signature
        }
      }
      detailsOfSecretary {
        surname
        firstname
        othername
        email
        mobile
        dob
        nationality
        gender
        city
        postCode
        houseNumber
        streetName
        country
        state
        lga
        firmName
        rcNumber
        repName
        type
        sectype
        signature
        identityCard
      }
      detailsOfShareholders {
        _id
        serviceId
        numberOfAllotedShares
        personalDetails {
          surname
          firstname
          othername
          email
          mobile
          dob
          gender
          nationality
        }
        contactDetails {
          country
          state
          lga
          city
          postCode
          houseNumber
          streetName
        }
        meansOfIdentification {
          type
          identityCard
          identityNumber
          signature
        }
      }
      totalIssueSharedCapital {
        words
        number
      }
      status
      paymentDetails {
        _id
        amount
        serviceId
        transactionId
        currency
        status
      }
      rcNumber
    }
  }
`;

export const GET_ALL_COM_REG_PAGINATED = gql`
  query getAllCompanyReg($page: Float, $pageSize: Float, $searchTerm: String, $filter: String, $order: Float) {
    getAllCompanyReg(page: $page, pageSize: $pageSize, searchTerm: $searchTerm, filter: $filter, order: $order) {
      allClient {
        _id
        businessName
        address
        email
        description
        queryReason
        created_at
        user {
          _id
          firstName
          lastName
          gender
          address
          city
          postCode
          phoneNumber
          dob
          created_at
          verified
          thumbnail
          email
        }
        detailsOfDirectors {
          _id
          serviceId
          personalDetails {
            surname
            firstname
            othername
            email
            mobile
            dob
            gender
            nationality
          }
          contactDetails {
            country
            state
            lga
            city
            postCode
            houseNumber
            streetName
          }
          meansOfIdentification {
            type
            identityCard
            identityNumber
            signature
          }
        }
        detailsOfSecretary {
          surname
          firstname
          othername
          email
          mobile
          dob
          nationality
          gender
          city
          postCode
          houseNumber
          streetName
          country
          state
          lga
          firmName
          rcNumber
          repName
          type
          sectype
          signature
          identityCard
        }
        detailsOfShareholders {
          _id
          serviceId
          numberOfAllotedShares
          personalDetails {
            surname
            firstname
            othername
            email
            mobile
            dob
            gender
            nationality
          }
          contactDetails {
            country
            state
            lga
            city
            postCode
            houseNumber
            streetName
          }
          meansOfIdentification {
            type
            identityCard
            identityNumber
            signature
          }
        }
        totalIssueSharedCapital {
          words
          number
        }
        status
        paymentDetails {
          _id
          amount
          serviceId
          transactionId
          currency
          status
        }
        rcNumber
      }
      pageInfo {
        currentPage
        totalPages
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;
export const GET_ALL_COM_REG_CLIENT_PAGINATED = gql`
  query getAllCompanyRegByUserClient($page: Float, $pageSize: Float, $searchTerm: String, $filter: String, $order: Float) {
    getAllCompanyRegByUserClient(page: $page, pageSize: $pageSize, searchTerm: $searchTerm, filter: $filter, order: $order) {
      allClient {
        _id
        businessName
        address
        email
        description
        queryReason
        created_at
        user {
          _id
          firstName
          lastName
          gender
          address
          city
          postCode
          phoneNumber
          dob
          created_at
          verified
          thumbnail
          email
        }
        detailsOfDirectors {
          _id
          serviceId
          personalDetails {
            surname
            firstname
            othername
            email
            mobile
            dob
            gender
            nationality
          }
          contactDetails {
            country
            state
            lga
            city
            postCode
            houseNumber
            streetName
          }
          meansOfIdentification {
            type
            identityCard
            identityNumber
            signature
          }
        }
        detailsOfSecretary {
          surname
          firstname
          othername
          email
          mobile
          dob
          nationality
          gender
          city
          postCode
          houseNumber
          streetName
          country
          state
          lga
          firmName
          rcNumber
          repName
          type
          sectype
          signature
          identityCard
        }
        detailsOfShareholders {
          _id
          serviceId
          numberOfAllotedShares
          personalDetails {
            surname
            firstname
            othername
            email
            mobile
            dob
            gender
            nationality
          }
          contactDetails {
            country
            state
            lga
            city
            postCode
            houseNumber
            streetName
          }
          meansOfIdentification {
            type
            identityCard
            identityNumber
            signature
          }
        }
        totalIssueSharedCapital {
          words
          number
        }
        status
        paymentDetails {
          _id
          amount
          serviceId
          transactionId
          currency
          status
        }
        rcNumber
      }
      pageInfo {
        currentPage
        totalPages
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const GET_ALL_CHANGE_OF_DIRECTORS = gql`
  query {
    getAllChangeOfDirectors {
      _id
      companyName
      rcNumber
      type
      adminMessage
      boardResolution
      newDirector {
        contactDetails {
          state
          lga
          city
          postCode
          houseNumber
          country
          streetName
        }
        meansOfIdentification {
          type
          identityCard
          signature
          identityNumber
        }
        personalDetails {
          othername
          firstname
          email
          mobile
          dob
          gender
          nationality
        }
      }
      dateOfResolution
      reasonForChange
      directorId
      oldDirectorSignature
      status
      paymentDetails {
        amount
        serviceId
        transactionId
        currency
        status
      }
    }
  }
`;
export const GET_ALL_CHANGE_OF_DIRECTORS_PAGINATED = gql`
  query paginatedChangeOfDirectors($page: Float!, $pageSize: Float!, $searchTerm: String) {
    getAllChangeOfDirectorsPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      returns {
        _id
        companyName
        rcNumber
        created_at
        user {
          firstName
          lastName
          email
        }
        type
        adminMessage
        boardResolution
        newDirector {
          contactDetails {
            state
            lga
            city
            postCode
            houseNumber
            country
            streetName
          }
          meansOfIdentification {
            type
            identityCard
            signature
            identityNumber
          }
          personalDetails {
            othername
            firstname
            email
            mobile
            dob
            gender
            nationality
          }
        }
        dateOfResolution
        reasonForChange
        directorId
        oldDirectorSignature
        status
        paymentDetails {
          amount
          serviceId
          transactionId
          currency
          status
        }
      }
      pageInfo {
        currentPage
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const GET_ALL_CHANGE_OF_ADDRESS = gql`
  query {
    getAllChangeOfAddress {
      _id
      user {
        firstName
        lastName
        email
        gender
        address
        city
        postCode
        phoneNumber
        dob
      }
      companyName
      boardResolution
      rcNumber
      dateOfResolution
      newAddress
      directorOneId
      directorOneSignature
      directorTwoId
      directorTwoSignature
      status
      adminMessage
      paymentDetails {
        amount
        serviceId
        transactionId
        currency
        status
      }
      created_at
    }
  }
`;
export const GET_ALL_CHANGE_OF_ADDRESS_PAGINATED = gql`
  query paginatedaddress($page: Float!, $pageSize: Float!, $searchTerm: String) {
    getAllChangeOfAddressPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      returns {
        _id
        user {
          firstName
          lastName
          email
          gender
          address
          city
          postCode
          phoneNumber
          dob
        }
        companyName
        boardResolution
        rcNumber
        dateOfResolution
        newAddress
        directorOneId
        directorOneSignature
        directorTwoId
        directorTwoSignature
        status
        adminMessage
        paymentDetails {
          amount
          serviceId
          transactionId
          currency
          status
        }
        created_at
      }
      pageInfo {
        currentPage
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const GET_ALL_CHANGE_OF_SECRETARY = gql`
  query {
    getAllChangeOfSecretary {
      _id
      user {
        _id
        firstName
        lastName
        email
        gender
        address
        phoneNumber
        dob
      }
      companyName
      rcNumber
      type
      boardResolution
      dateOfResolution
      reasonForChange
      secretaryId
      secretarySignature
      adminMessage
      status
      paymentDetails {
        _id
        amount
        serviceId
        transactionId
        status
      }
      created_at
    }
  }
`;

export const GET_ALL_UPDATE_SHAREHOLDER = gql`
  query {
    getUpdateShareholderDetails {
      _id
      user {
        _id
        firstName
        lastName
        email
        gender
        address
        phoneNumber
        dob
      }
      organisationName
      rcNumber
      shareholderName
      firstName
      surname
      otherName
      nationality
      phoneNumber
      emailAddress
      dateOfBirth
      gender
      residentialAddress
      serviceAddress
      affidavit
      application
      adminMessage
      status
      paymentDetails {
        _id
        status
        amount
        transactionId
      }
      created_at
    }
  }
`;
export const GET_ALL_UPDATE_DIRECTORS = gql`
  query {
    getUpdateDirectorDetails {
      _id
      user {
        _id
        firstName
        lastName
        email
        gender
        address
        phoneNumber
        dob
      }
      organisationName
      rcNumber
      firstName
      surname
      otherName
      nationality
      phoneNumber
      emailAddress
      dateOfBirth
      gender
      residentialAddress
      serviceAddress
      affidavit
      application
      adminMessage
      status
      directorName
      paymentDetails {
        _id
        status
        amount
        transactionId
      }
      created_at
    }
  }
`;
