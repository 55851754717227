import { Grid, TextField } from '@mui/material';
// ----------------------------------------------------------------------

export const ContactDetailsInput = ({ register }) => {
  return (
    <Grid item container xs={12} sm={12} md={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="country" label="Country" fullWidth required {...register('country', { required: true })} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="state" label="State" fullWidth required {...register('state', { required: true })} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="lga" label="LGA" fullWidth required {...register('lga', { required: true })} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="city" label="City/Town/Village" fullWidth required {...register('city', { required: true })} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="postCode" label="Post code" fullWidth {...register('postCode', { required: false })} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="houseNumber"
          label="House number/building name"
          fullWidth
          required
          {...register('houseNumber', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="streetName"
          label="Street name"
          fullWidth
          required
          {...register('streetName', { required: true })}
        />
      </Grid>
    </Grid>
  );
};
