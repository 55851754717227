import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { ForgetPasswordForm } from 'src/sections/auth/forget-password';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));
const StyledCenteredSection = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> SignUp | BeeBot </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Let's Join Beebot
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <StyledCenteredSection>
              <Logo
                sx={{
                  top: { xs: 16, sm: 24, md: 40 },
                  left: { xs: 16, sm: 24, md: 40 },
                }}
              />
              {/* <Typography variant="h4" gutterBottom>
                Welcome to Beebot
              </Typography> */}
            </StyledCenteredSection>

            <ForgetPasswordForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
