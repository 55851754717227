import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useDispatch } from 'react-redux';
import { setUser } from 'src/redux/auth.slice';
import styled from '@emotion/styled';
import { borderRadius } from '@mui/system';

// ----------------------------------------------------------------------

const StyledFormWrapper = styled('div')(({ theme }) => ({
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
  padding: '0 1rem 1rem 1rem',
}));

const StyledFormHeader = styled('div')(({ theme }) => ({
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  borderBottom: '1px solid #CFCFCF',
  marginLeft: '-1rem',
  marginRight: '-1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const StyledContentWrapper = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const StyledIconWrapper = styled('div')(({ theme }) => ({
  height: '3.5rem',
  width: '3.5rem',
  borderRadius: '50px',
  backgroundColor: '#428124',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledIconContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function EmailVerified() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    dispatch(setUser({ username: 'abubakar', email: 'abubakar@gmail.com', status: true, role: 1 }));
    navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <StyledFormWrapper>
        <Stack spacing={3}>
          <StyledFormHeader>
            <Typography
              variant="p"
              style={{
                padding: '0.5rem',
              }}
            >
              Verify your email
            </Typography>
          </StyledFormHeader>
          <StyledIconContentWrapper>
            <StyledIconWrapper>
              <img src="/assets/icons/MarkIcon.svg" alt="login" />
            </StyledIconWrapper>
          </StyledIconContentWrapper>

          <StyledContentWrapper>
            <Typography variant="p" sx={{ mb: 5 }}>
              Welcome to Beebot
            </Typography>
          </StyledContentWrapper>
          <StyledContentWrapper>
            <Typography variant="p" sx={{ mb: 5 }}>
              Please check your email to verify your account.
            </Typography>
          </StyledContentWrapper>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            style={{ marginTop: '3rem', marginBottom: '3rem' }}
          >
            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
              Continue
            </LoadingButton>
          </Stack>
        </Stack>
      </StyledFormWrapper>
    </>
  );
}
