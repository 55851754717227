import { useLazyQuery, useMutation } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { QUERY_BUSINESS_REGISTRATION_CAC } from 'src/graphql/mutations/companyRegistration';
import { UPDATE_PAYMENT_STATUS_MANUAL } from 'src/graphql/mutations/payment';
import { fDate } from 'src/utils/formatTime';
import { NAME_SERVATIONS_STATUS } from '../../../utils/Constants';
import { APPROVE_CAC_REQUEST, RESUBMIT_QUERIED_APPLICATION } from 'src/graphql/queries/cacClient';

const ExpandableCard = ({
  data,
  handleToggle,
  handleApprove,
  selected,
  loading,
  checkCacStatus,
  resubmitQueriedApplication,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [queryText, setQueryText] = useState('');

  const [paymentResolutionData, setPaymentResolutionData] = useState({
    transaction_id: data._id,
    type: 'BusinessRegCac',
    gateway: 'paystack',
    tx_ref: '',
    status: 'success',
    serviceAmount: 19500,
  });

  const paymentDetails = data.paymentDetails;

  const [queryBusinessRegCac, { loading: queryLoading, error: queryError, data: queriedData }] = useMutation(
    QUERY_BUSINESS_REGISTRATION_CAC
  );

  const [sendQueriedApplicationToCac, { error: error3, loading: loading3, data: sendQueriedApplicationToCacData }] =
    useMutation(RESUBMIT_QUERIED_APPLICATION);
  const [sendToCac, { error: errorSendToCac, loading: loadinSendToCac, data: sendToCacData }] =
    useMutation(APPROVE_CAC_REQUEST);

  const [
    resolvePaymentStatus,
    { loading: resolvePaymentStatusLoading, error: resolvePaymentStatusError, data: resolvePaymentStatusData },
  ] = useMutation(UPDATE_PAYMENT_STATUS_MANUAL);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleToggleFunc = () => {
    handleToggle(data._id);
  };

  const handleResubmitQueriedApplication = (id) => {
    resubmitQueriedApplication(id);
    sendQueriedApplicationToCac({
      variables: {
        id,
      },
    });
  };

  const handleApproveRequest = (id) => {
    handleApprove(id);
    sendToCac({
      variables: {
        id,
      },
    });
  };

  const handleResolvePaymentStatus = () => {
    resolvePaymentStatus({
      variables: {
        input: {
          ...paymentResolutionData,
          serviceAmount: parseInt(paymentResolutionData.serviceAmount),
        },
        user: data?.user?._id,
      },
    });
  };

  const handleUpdateUserApplication = () => {
    const linkToApplication = `https://www.app.beebot.io/pre-incorporation/registration-of-business-name-cac/${data._id}`;
    //open link in new tab
    window.open(linkToApplication, '_blank');
  };

  const handleSubmitQuery = () => {
    queryBusinessRegCac({
      variables: {
        id: data._id,
        queryReason: queryText,
      },
    });
  };

  const handleQueryChange = (event) => {
    setQueryText(event.target.value);
  };

  const status = useMemo(() => {
    if (paymentDetails) {
      if (paymentDetails.status === 'success') {
        return NAME_SERVATIONS_STATUS.COMPLETED;
      } else {
        return NAME_SERVATIONS_STATUS.PENDING;
      }
    } else {
      return NAME_SERVATIONS_STATUS.CANCELLED;
    }
  });

  useEffect(() => {
    if (queryError) {
      enqueueSnackbar('Fail to send query', { variant: 'error' });
    }
  }, [queryError]);

  useEffect(() => {
    if (queriedData?.queryBusinessRegCac) {
      enqueueSnackbar('Query Sent!', { variant: 'success' });
    }
  }, [queriedData?.queryBusinessRegCac]);

  useEffect(() => {
    if (resolvePaymentStatusError) {
      enqueueSnackbar('Fail to resolve payment', { variant: 'error' });
    }
  }, [resolvePaymentStatusError]);

  useEffect(() => {
    if (resolvePaymentStatusData) {
      enqueueSnackbar('Payment resolved!', { variant: 'success' });
    }
  }, [resolvePaymentStatusData]);

  const Text = ({ text }) => {
    return (
      <LoadingButton variant="outlined" sx={{ ml: 1 }}>
        {`${text}`}
      </LoadingButton>
    );
  };

  const handleOpenLinkInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const cacReason = useMemo(() => {
    if (expanded) {
      try {
        return JSON.parse(data.cacQueryReason);
      } catch (e) {
        console.warn(e);
        return [];
      }
    } else {
      return [];
    }
  }, [data, expanded]);

  return (
    <Card sx={{ margin: '10px' }}>
      <Grid container>
        <Grid item xs={12}>
          <CardHeader
            avatar={<Avatar src={data.passport} alt={data.proprietorFirstname} sx={{ width: 56, height: 56 }} />}
            title={data.proposedOption1}
            subheader={`${new Date(data.created_at).toLocaleDateString()} ${new Date(
              data.created_at
            ).toLocaleTimeString()}`}
            action={
              <Grid container>
                <Grid item xs={12}>
                  <IconButton aria-label="settings">
                    <img
                      src={
                        status === NAME_SERVATIONS_STATUS.PENDING
                          ? '/assets/icons/statuses/Pending.svg'
                          : status === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION
                          ? '/assets/icons/statuses/Pending.svg'
                          : status === NAME_SERVATIONS_STATUS.COMPLETED
                          ? '/assets/icons/statuses/Approved.svg'
                          : '/assets/icons/statuses/Denied.svg'
                      }
                      width={20}
                      alt="status"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            }
            sx={{ padding: '12px 20px ' }}
          />
          <CardActions
            disableSpacing
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid>
              <Text text={`Order Number: ${data._id}`} />
              {data?.queryReason && <Text text={`Queried Internal`} />}
              {data.transactionRef && <Text text={`Submitted`} />}
              {data.status === 'queried' && <Text text={`Queried CAC`} />}
              {data.status === 'approved' && <Text text={`Approved`} />}
            </Grid>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{ mr: 1 }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ExpandMore>
          </CardActions>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}></Grid>
            {loading && selected === data._id ? (
              <CircularProgress />
            ) : data.transactionRef ? (
              <Grid item>
                <Typography paragraph>
                  <strong>Application sent successfully</strong>
                </Typography>
                <Typography paragraph>
                  Vas ref: <strong>{data.transactionRef}</strong>
                </Typography>
                <Typography paragraph>
                  Application status: <strong>{data.status || 'pending'}</strong>
                </Typography>
                {data.status === 'approved' ? (
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant="contained" size="small" onClick={() => handleOpenLinkInNewTab(data?.statusReport)}>
                      Donwload Status Report
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleOpenLinkInNewTab(data?.certificate);
                      }}
                    >
                      Download Certificate
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    onClick={() => {
                      checkCacStatus(data._id);
                    }}
                  >
                    Check CAC Status
                  </Button>
                )}

                {data.status === 'approved' && (
                  <Grid item>
                    <Typography paragraph>
                      Rc Number: <strong>{data.rcNumber}</strong>
                    </Typography>
                    <Typography paragraph>
                      TIN: <strong>{data.tin}</strong>
                    </Typography>
                    <Typography paragraph>
                      Reg Date:{' '}
                      <strong>{`${new Date(data.registrationDate).toLocaleDateString()} ${new Date(
                        data.registrationDate
                      ).toLocaleTimeString()}`}</strong>
                    </Typography>
                  </Grid>
                )}
                {cacReason?.length && (
                  <Grid item>
                    <Typography paragraph>Comment from CAC:</Typography>
                    {cacReason.map((item) => (
                      <>
                        <Typography paragraph>Reason: {item.reason}</Typography>
                        <Typography paragraph>Comment: {item.comment}</Typography>
                        <br />
                      </>
                    ))}
                  </Grid>
                )}
                {data.status === 'queried' && (
                  <Grid item>
                    {loading3 ? (
                      <CircularProgress />
                    ) : (
                      <Button onClick={() => handleResubmitQueriedApplication(data._id)}>
                        Resubmit Queried Application
                      </Button>
                    )}
                    {error3 && <Typography variant="body2">Error: {JSON.stringify(error3)}</Typography>}
                    {sendQueriedApplicationToCacData && <Typography variant="body2">Sent!</Typography>}
                  </Grid>
                )}
              </Grid>
            ) : (
              (data.paymentDetails?.status === 'success' || data.paymentDetails?.status === 'successful') && (
                <Grid item>
                  {loadinSendToCac ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      onClick={() => {
                        handleApproveRequest(data._id);
                      }}
                    >
                      Approve
                    </Button>
                  )}
                  {sendToCacData && <Typography variant="body2">Sent!</Typography>}
                  {errorSendToCac && <Typography variant="body2">Error: {JSON.stringify(errorSendToCac)}</Typography>}
                  <Button onClick={handleToggleFunc}>
                    {data.editable === true ? 'Remove Editable' : 'Make Editable'}
                  </Button>
                </Grid>
              )
            )}
            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Payment Details:</strong>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Payment Status:</strong>{' '}
                {data.paymentDetails?.status === 'success' || data.paymentDetails?.status === 'successful'
                  ? 'Paid'
                  : 'Not Paid'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Order Number:</strong> {data._id}
              </Typography>
            </Grid>
            {data.paymentDetails?.amount && (
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Amount:</strong> {data.paymentDetails?.amount}
                </Typography>
              </Grid>
            )}
            <Divider />
            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Company Details:</strong>
              </Typography>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Gender:</strong> {data.proprietorGender}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Nationality:</strong> {data.proprietorNationality}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Address:</strong> {data.proprietorStreetNumber}, {data.proprietorCity}, {data.proprietorState}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>LGA:</strong> {data.proprietorLga}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Postcode:</strong> {data.proprietorPostcode}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Phone Number:</strong> {data.proprietorPhonenumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Postcode:</strong> {data.proprietorPostcode}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Address:</strong> {data.companyStreetNumber}, {data.companyCity}, {data.companyState}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Email:</strong> {data.companyEmail}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Line of Business:</strong> {data.lineOfBusiness}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Proposed Name:</strong> {data.proposedOption1}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Proprietor Details:</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Name:</strong> {data.proprietorFirstname} {data.proprietorSurname}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Email:</strong> {data.proprietorEmail}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Phone:</strong> {data.proprietorPhonenumber}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Gender:</strong> {data.proprietorGender}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>DOB:</strong> {fDate(data.proprietorDob)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Nationality:</strong> {data.proprietorNationality}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Address:</strong> {data.proprietorStreetNumber}, {data.proprietorCity}, {data.proprietorState}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>LGA:</strong> {data.proprietorLga}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Postcode:</strong> {data.proprietorPostcode}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Images:</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={data.passport} alt="Passport" style={{ width: '100%' }} />
              <Typography variant="caption">Passport</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={data.meansOfId} alt="Means of ID" style={{ width: '100%' }} />
              <Typography variant="caption">Means of ID</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={data.signature} alt="Signature" style={{ width: '100%' }} />
              <Typography variant="caption">Signature</Typography>
            </Grid>
            {data?.queryReason && (
              <Grid item xs={12}>
                <Typography paragraph>
                  <strong>Query Reason:</strong>
                </Typography>
                <Typography paragraph>{data.queryReason}</Typography>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Query"
                value={queryText}
                onChange={handleQueryChange}
                multiline
                rows={2}
                variant="outlined"
                margin="normal"
              />
              <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                <Button onClick={handleUpdateUserApplication} variant="outlined">
                  Edit Application
                </Button>
                <Button onClick={handleSubmitQuery} variant="outlined">
                  {queryLoading ? 'Loading...' : 'Query'}
                </Button>
              </Stack>
            </Grid>
            {!data.paymentDetails && (
              <Grid item xs={12}>
                <Typography paragraph>
                  <strong>Payment Resolution:</strong>
                </Typography>
                <TextField
                  fullWidth
                  label="Transaction ID"
                  value={paymentResolutionData.tx_ref}
                  onChange={(e) => setPaymentResolutionData({ ...paymentResolutionData, tx_ref: e.target.value })}
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Amount"
                  type="number"
                  value={paymentResolutionData.serviceAmount}
                  onChange={(e) =>
                    setPaymentResolutionData({ ...paymentResolutionData, serviceAmount: e.target.value })
                  }
                  variant="outlined"
                  margin="normal"
                />
                <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                  <Button onClick={handleResolvePaymentStatus} variant="outlined">
                    {resolvePaymentStatusLoading ? 'Loading...' : 'Resolve Payment'}
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const CacBusinessNameCard = ({
  items,
  handleSendEmail,
  handleToggle,
  handleApprove,
  selected,
  loading,
  checkCacStatus,
  resubmitQueriedApplication,
}) => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12} key={item._id}>
          <ExpandableCard
            data={item}
            handleSendEmail={handleSendEmail}
            handleToggle={handleToggle}
            handleApprove={handleApprove}
            selected={selected}
            loading={loading}
            checkCacStatus={checkCacStatus}
            resubmitQueriedApplication={resubmitQueriedApplication}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CacBusinessNameCard;
