import { gql } from '@apollo/client';

export const GET_ALL_STATUS_REPORT_REQUEST = gql`
  query {
    allStatusReport {
      _id
      organisationName
      rcNumber
      user {
        city
        firstName
        lastName
        email
        address
        phoneNumber
        city
      }
      status
      paymentDetails {
        amount
        transactionId
        serviceId
        status
      }
      authorisedID
      created_at
      adminMessage
    }
  }
`;
export const GET_ALL_PAGINATED_STATUS_REPORT = gql`
query paginatedSR($page: Float!, $pageSize: Float!, $searchTerm: String) {
  allPaginatedStatus(page: $page, pageSize: $pageSize, searchTerm: $searchTerm){
    returns{
      _id
      organisationName
      rcNumber
      user {
        city
        firstName
        lastName
        email
        address
        phoneNumber
        city
      }
      status
      paymentDetails {
        amount
        transactionId
        serviceId
        status
      }
      authorisedID
      created_at
      adminMessage
    },
    pageInfo{
      currentPage
      pageSize
      totalPages
      totalItems
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
    }
  }
}

`