import { Box, Grid, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledLinearProgress, StyledSpaceBetweenContainer } from './styled';
// ----------------------------------------------------------------------

export default function FileUploader({ value, setValue, icon }) {
  const upload = (e) => {
    const files = e.target.files;
    setValue(files[0]);
  };

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item md={8} sm={8} xs={6}>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', gap: 1, overflow: 'hidden' }}>
          <img src={icon} alt="my icon" />
          <Box sx={{ width: '80%' }}>
            <StyledSpaceBetweenContainer>
              <Typography variant="caption">{value?.name}</Typography>
              <Typography variant="caption">{`${value === null ? 0 : 100}%`}</Typography>
            </StyledSpaceBetweenContainer>
            <StyledLinearProgress variant="determinate" value={value === null ? 0 : 100} />
          </Box>
        </Box>
      </Grid>
      <Grid item container md={4} sm={4} xs={6} justifyContent="flex-end">
        <LoadingButton
          size="small"
          variant="contained"
          aria-label="upload picture"
          component="label"
          onChange={(e) => upload(e)}
        >
          <input hidden accept="image/*" type="file" />
          Upload Document
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
