import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F4F6FA',
    borderRadius: '5px',
    border: 'none',
    padding: '0.3rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
}));

export const PreviewCard = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid item container xs={12} sm={12} md={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        {data.map((obj, index) => (
          <div className={classes.container} key={index}>
            <p>{obj?.name}</p>
            <div className={classes.iconContainer}></div>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};
