import { Divider, Grid, Hidden } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledHeader, StyledHeaderContainer } from './styled';
// ----------------------------------------------------------------------
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CompanyRegistrationCard from 'src/components/company-registration-card/CompanyRegistrationCard';
import { ContactDetailsInput } from 'src/components/company-registration-inputs/ContactDetailsInput';
import { MeansOfIdentityInput } from 'src/components/company-registration-inputs/MeansOfIdentityInput';
import { PersonalDetailInput } from 'src/components/company-registration-inputs/PersonalDetailInput';
import { RegistrationCard } from 'src/components/company-registration-inputs/RegistrationCard';
import { setCompanyRegistrationData } from 'src/redux/companyregistration.slice';

export default function Step4({ setStep, step }) {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.companyregistration);
  const [directorsInfo, setDirectorsInfo] = useState({});
  const [directorsList, setDirectorsList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [identityCard, setIdentityCard] = useState(null);
  const [signature, setSignature] = useState(null);
  const [type, SetType] = useState('National ID');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleNotify = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const { handleSubmit, register, reset, setValue, control } = useForm();

  useEffect(() => {
    setDirectorsList(data?.detailsOfSecretary ? data.detailsOfSecretary : []);
  }, [data]);

  useEffect(() => {
    if (Object.keys(directorsInfo).length !== 0) {
      setIdentityCard(directorsInfo?.setIdentityCard);
      setSignature(directorsInfo?.signature);
      SetType(directorsInfo?.type);
      Object.entries(directorsInfo).forEach(([key, value]) => {
        console.log(key, value);
        setValue(key, value);
      });
    }
  }, [directorsInfo, setValue]);

  const handleChangeId = (event) => {
    SetType(event.target.value);
  };

  const handleNextStep = () => {
    dispatch(setCompanyRegistrationData({ detailsOfSecretary: directorsList }));
    setStep(step + 1);
  };

  const onSubmit = (data) => {
    if ((!identityCard || !signature) && !directorsInfo?.index) {
      setShowAlert(true);
    } else {
      if (directorsInfo?.index) {
        //existing record update required
        const currentDirectors = [...directorsList];
        currentDirectors[directorsInfo?.index] = { ...data, type, identityCard, signature };
        setShowAlert(false);
        setDirectorsList(currentDirectors);
        setIdentityCard(null);
        setSignature(null);
        reset();
        setDirectorsInfo({});
        handleNotify('Secretary updated!');
      } else {
        //new record
        setShowAlert(false);
        setDirectorsList((prev) => [...prev, { ...data, type, identityCard, signature }]);
        setIdentityCard(null);
        setSignature(null);
        reset();
        handleNotify('Secretary added!');
      }
    }
  };
  return (
    <>
      <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
        <Grid item xs={12} sm={12} md={12} container>
          <StyledHeaderContainer>
            <img src="/assets/icons/UserIcon.svg" alt="Nature of Business" />
            <StyledHeader>Details of Secretary</StyledHeader>
          </StyledHeaderContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Divider />
        </Grid>
        <Grid item container xs={12} sm={12} md={12}>
            <PersonalDetailInput register={register} inputValues={directorsInfo} />
        </Grid>
        <Grid item container xs={12} sm={12} md={12}>
          <ContactDetailsInput register={register} inputValues={directorsInfo} data={data} control={control}/>
        </Grid>
        <Grid item container xs={12} sm={12} md={12}>
           <MeansOfIdentityInput
                type={'Secretary'}
                identityCard={identityCard}
                directorsInfo={directorsInfo}
                setMeansOfID={setIdentityCard}
                signature={signature}
                setSignature={setSignature}
                idtype={type}
                handleChange={handleChangeId}
                register={register}
                control={control}
              />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CompanyRegistrationCard
            natureOfBusiness={'List of Individual Secretary'}
            proposedName={'List of Individual Secretary'}
            status={1}
            setSelectedReservedName={() => null}
            children={
              <RegistrationCard
                data={directorsList}
                setDirectorsInfo={setDirectorsInfo}
                setDirectorsList={setDirectorsList}
                handleNotify={handleNotify}
              />
            }
          />
        </Grid>
        {showAlert && (
          <Grid item xs={12} sm={12} md={12}>
            <Alert severity="error">Please fill all field and add director</Alert>
          </Grid>
        )}
        <Hidden smDown>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingButton style={{ marginRight: '1rem' }} variant="outlined" onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" disabled={!directorsList?.length} onClick={() => handleNextStep()}>
              Save & Continue
            </LoadingButton>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item container xs={12} sm={12} md={12} spacing={2}>
            <Grid item xs={12}>
              <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)}>
                Go Back
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                fullWidth
                disabled={!directorsList?.length}
                onClick={() => handleNextStep()}
              >
                Save & Continue
              </LoadingButton>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
