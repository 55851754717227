import { gql } from '@apollo/client';

export const QUERY_BUSINESS_REGISTRATION_CAC = gql`
  mutation queryBusinessRegCac($id: String!, $queryReason: String!) {
    queryBusinessRegCac(id: $id, queryReason: $queryReason) {
    _id
    }
  }
`;

export const QUERY_COMPANY_REGISTRATION_CAC = gql`
  mutation queryRegistrationOfCompany($id: String!, $queryReason: String!) {
    queryRegistrationOfCompany(id: $id, queryReason: $queryReason) {
    _id
    }
  }
`;

export const COMPANY_REG = gql`
  mutation CreateNewRegistrationOfCompany($input: RegistrationOfCompaniesInput!) {
    CreateNewRegistrationOfCompany(input: $input) {
      status
      message
      data {
        link
      }
    }
  }
`;

export const UPDATE_COMPANY_REGISTRATION_STATUS = gql`
  mutation updateRegistrationOfCompanyApprovalStatus($input: RegistrationOfCompaniesStatusInput!) {
    updateRegistrationOfCompanyApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;

export const UPDATE_CHANGE_OF_DIRECTOR_STATUS = gql`
  mutation updateChangeOfDirectorsApprovalStatus($input: ChangeDirectorsInput!) {
    updateChangeOfDirectorsApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;

export const UPDATE_CHANGE_OF_ADDRESS_STATUS = gql`
  mutation updateChangeOfAddressApprovalStatus($input: ChangeOfAddressStatusInput!) {
    updateChangeOfAddressApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;
export const UPDATE_CHANGE_OF_SECRETARY_STATUS = gql`
  mutation updateChangeOfSecretaryApprovalStatus($input: ChangeOfSecretaryStatusInput!) {
    updateChangeOfSecretaryApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;

export const UPDATE_SHAREHOLDER_APPROVAL_STATUS = gql`
  mutation updateStatusShareholderDetails($input: ShareholderDetailsStatusInput!) {
    updateStatusShareholderDetails(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;

export const UPDATE_DIRECTORS_APPROVAL_STATUS = gql`
  mutation updateStatusDirectorsDetails($input: DirectorDetailsStatusInput!) {
    updateStatusDirectorsDetails(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;
