import { Helmet } from 'react-helmet-async';
import {Grid, Button, Container, Stack, Typography, TextField, CircularProgress, InputAdornment} from '@mui/material';
import Iconify from '../../components/iconify';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_ANNUAL_RETURN_REQUEST_PAGINATED } from 'src/graphql/queries/annualReturn';
import { UPADATE_ANNUAL_RETURN_STATUS } from 'src/graphql/mutations/annualReturns';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AnnualReturnRequestCard from 'src/sections/@dashboard/annual return/AnnualReturnRequestCard';
import { debounce } from 'lodash';

export default function AdminAnnualReturn() {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const { data: data2, loading: loadingData2, fetchMore } = useQuery(GET_ALL_ANNUAL_RETURN_REQUEST_PAGINATED, {
    variables: { page, pageSize },
  });

  const [annualReturnRequests, setAnnualReturnRequests] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateBusinessRegistrationApprovalStatus, { data: updatedReservation, loading }] =
      useMutation(UPADATE_ANNUAL_RETURN_STATUS);
  const { enqueueSnackbar } = useSnackbar();

  const [filtered, setFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false); // New state for search loading

  useEffect(() => {
    if (data2 && data2?.allReturns) {
      setAnnualReturnRequests(data2.allReturns.returns);
    }
  }, [data2]);

  useEffect(() => {
      setFiltered(annualReturnRequests);
  }, [annualReturnRequests,]);

  useEffect(() => {
    if (updatedReservation?.updateBusinessRegistrationApprovalStatus && updateStatus) {
      setUpdateStatus(false);
      let tempReservations = [...annualReturnRequests];
      const objIndex = tempReservations.findIndex(
          (obj) => obj._id === updatedReservation?.updateBusinessRegistrationApprovalStatus?._id
      );
      tempReservations[objIndex].status = updatedReservation?.updateBusinessRegistrationApprovalStatus?.status;
      setAnnualReturnRequests(tempReservations);
    }
  }, [updatedReservation?.updateBusinessRegistrationApprovalStatus, annualReturnRequests, updateStatus]);

  const changeStatus = (_id, status, adminMessage) => {
    setUpdateStatus(true);
    enqueueSnackbar('Status updated!');
    updateBusinessRegistrationApprovalStatus({
      variables: { input: { _id, status, adminMessage } },
    });
  };

  const handlePageChange = async (newPage) => {
    setPage(newPage);
    await fetchMore({
      variables: { page: newPage, pageSize, searchTerm },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  };

  const handleSearchDebounced = debounce(async (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
    setSearchLoading(true);
    await fetchMore({
      variables: { page, pageSize, searchTerm: newSearchTerm },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        console.log({fetchMoreResult, newSearchTerm} )
        return fetchMoreResult;
      },
    });
    setSearchLoading(false);

  }, 200);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setSearchLoading(false);
    handleSearchDebounced(newSearchTerm);
  };

  return (
      <>
        <Helmet>
          <title> Annual Returns | BeeBot </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Annual Return
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Record
            </Button>
          </Stack>

          <Grid container spacing={3}>
            <Grid item md={12} sm={12}>
              <TextField
                  value={searchTerm}
                  fullWidth
                  placeholder="Search Reservation by Company name..."
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          {searchLoading && <CircularProgress size={20} />}
                        </InputAdornment>
                    ),
                  }}
              />
            </Grid>

            {filtered?.map((post, index) => (
                <AnnualReturnRequestCard key={post._id} post={post} index={index} loading={loading} changeStatus={changeStatus} />
            ))}

            <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <Button variant="outlined" onClick={() => handlePageChange(page - 1)} disabled={page <= 1 || loadingData2}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body2">Page {page}</Typography>
              </Grid>
              <Grid item>
                <Button
                    variant="outlined"
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= data2?.allReturns.pageInfo?.totalPages || loadingData2}
                >
                  Next
                </Button>
              </Grid>
              {loadingData2 && <CircularProgress size={24} />}
            </Grid>
          </Grid>
        </Container>
      </>
  );
}
