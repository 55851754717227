import { Divider, Grid, Hidden, Stack, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledContent, StyledElementContainer, StyledFieldLabel, StyledHeader, StyledHeaderContainer } from './styled';
// ----------------------------------------------------------------------
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessRegistrationData } from 'src/redux/businessregistration.slice';

export default function Step2({ setStep, step }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.businessregistration);

  const [proprietorDob, setValue] = useState(dayjs(data?.proprietorDob ? data?.proprietorDob : '2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setBusinessRegistrationData({ ...data, proprietorDob }));
    handleNextStep();
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12} container>
        <StyledHeaderContainer>
          <img src="/assets/icons/ProprietorDetails.svg" alt="Proprietor Details" />
          <StyledHeader>Proprietor Details</StyledHeader>
        </StyledHeaderContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorName"
          label="Name"
          fullWidth
          defaultValue={data?.proprietorName}
          {...register('proprietorName', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorTelephone"
          label="Telephone"
          fullWidth
          defaultValue={data?.proprietorTelephone}
          {...register('proprietorTelephone', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorEmail"
          label="Email"
          type="email"
          fullWidth
          defaultValue={data?.proprietorEmail}
          {...register('proprietorEmail', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorPhone"
          label="Phone"
          fullWidth
          defaultValue={data?.proprietorPhone}
          {...register('proprietorPhone', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorGender"
          label="Gender"
          fullWidth
          defaultValue={data?.proprietorGender}
          {...register('proprietorGender', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name="proprietorNationality"
          label="Nationality"
          fullWidth
          defaultValue={data?.proprietorNationality}
          {...register('proprietorNationality', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledContent>
          <StyledElementContainer>
            <StyledFieldLabel>Contact Address*</StyledFieldLabel>
          </StyledElementContainer>
          <Divider />
          <TextField
            name="proprietorAddress"
            label=""
            fullWidth
            required
            defaultValue={data?.proprietorAddress}
            {...register('proprietorAddress', { required: true })}
          />
        </StyledContent>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Date of Birth"
            inputFormat="MM/DD/YYYY"
            value={proprietorDob}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>

      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction="row" spacing={2}>
            <LoadingButton variant="outlined" onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" type="submit">
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <Stack spacing={2}>
            <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" fullWidth type="submit">
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
    </Grid>
  );
}
