import { gql } from '@apollo/client';

export const REGISTER_BUSINESS_NAME = gql`
  mutation registerBusinessName(
    $input: RegisterBusinessNameInput!
    $proprietorSignature: Upload!
    $proprietorPassportPhoto: Upload!
    $proprietorID: Upload!
  ) {
    registerBusinessName(
      input: $input
      proprietorSignature: $proprietorSignature
      proprietorPassportPhoto: $proprietorPassportPhoto
      proprietorID: $proprietorID
    ) {
      status
      message
      data {
        link
      }
    }
  }
`;
export const UPADATE_BUSINESS_REGISTRATION_STATUS = gql`
  mutation updateBusinessRegistrationApprovalStatus($input: BusinessRegistrationStatusInput!) {
    updateBusinessRegistrationApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;
