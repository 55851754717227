import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_CHANGE_OF_SECRETARY } from 'src/graphql/queries/companyName';
import { UPDATE_CHANGE_OF_SECRETARY_STATUS } from 'src/graphql/mutations/companyRegistration';

import { useEffect, useState } from 'react';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { useSnackbar } from 'notistack';
import ChangeOfSecretaryRequestCards from 'src/sections/@dashboard/change of secretary/ChangeOfSecretaryRequestCard';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminChangeOfSecretary() {
  const { data, loading: loadingData } = useQuery(GET_ALL_CHANGE_OF_SECRETARY);

  const [changeOfSecretaryRequests, setChangeOfSecretaryRequests] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateChangeOfSecretaryApprovalStatus, { data: updatedReservation, loading }] = useMutation(
    UPDATE_CHANGE_OF_SECRETARY_STATUS
  );
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setChangeOfSecretaryRequests(data?.getAllChangeOfSecretary);
  }, [data?.getAllChangeOfSecretary]);

  useEffect(() => {
    if (updatedReservation?.updateChangeOfSecretaryApprovalStatus && updateStatus) {
      setUpdateStatus(false);
      //Initailize array of objects.
      let tempReservations = [...changeOfSecretaryRequests];

      //Find index of specific object using findIndex method.
      const objIndex = tempReservations.findIndex(
        (obj) => obj._id === updatedReservation?.updateChangeOfSecretaryApprovalStatus?._id
      );

      //Update object's name property.
      tempReservations[objIndex].status = updatedReservation?.updateChangeOfSecretaryApprovalStatus?.status;

      //Log object to console again.
      setChangeOfSecretaryRequests(tempReservations);
    }
  }, [updatedReservation?.updateChangeOfSecretaryApprovalStatus, updateStatus, changeOfSecretaryRequests]);

  useEffect(() => {
    const lowerCaseSearchTerm = search.toLowerCase();
    if (search.length) {
      setFiltered(() =>
        changeOfSecretaryRequests?.filter((request) => {
          return (
            request?.companyName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.rcNumber?.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.type?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.directorId?.toLowerCase().includes(lowerCaseSearchTerm)
          );
        })
      );
    } else {
      setFiltered(changeOfSecretaryRequests);
    }
  }, [changeOfSecretaryRequests, search]);

  const changeStatus = (_id, status, adminMessage) => {
    setUpdateStatus(true);
    enqueueSnackbar('Status updated!');
    updateChangeOfSecretaryApprovalStatus({
      variables: { input: { _id, status, adminMessage } },
    });
  };

  return (
    <>
      <Helmet>
        <title> Annual Returns | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Change of Secretary
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={12} sm={12}>
            {' '}
            <TextField
              value={search}
              fullWidth
              placeholder="Search Reservation by Company name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          {loadingData && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {filtered?.map((post, index) => (
            <ChangeOfSecretaryRequestCards
              key={post._id}
              post={post}
              index={index}
              loading={loading}
              changeStatus={changeStatus}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
}
