import { gql } from '@apollo/client';

export const UPDATE_TRADEMARK_STATUS = gql`
    mutation updateTrademark($input: TrademarkStatusInput!) {
    updateTrademark(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;