import { Link, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const StyledCenteredSection = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '30vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 50,
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function EmptyBusinessName() {
  const navigate = useNavigate();
  return (
    <StyledContent>
      <StyledCenteredSection>
        <img src="/assets/illustrations/EmptyNames.svg" alt="logo" width={400} />
        <Typography variant="h3">Huft! No Business Name registered yet </Typography>

        <Typography variant="body2">
          Register a business name <Link onClick={() => navigate('/')}>here</Link>
        </Typography>
      </StyledCenteredSection>

      <StyledCenteredSection></StyledCenteredSection>
    </StyledContent>
  );
}
