import { useMutation } from '@apollo/client';
import { Button, Container, Divider, Link, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { gapi } from 'gapi-script';
import { useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { googleLogin } from 'src/graphql/mutations/auth';
import { setUser } from 'src/redux/auth.slice';
import Iconify from '../components/iconify';
// components
import Logo from '../components/logo';
// hooks
import useResponsive from '../hooks/useResponsive';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 680,
  margin: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  background: 'url(/assets/20240921_075123.jpg)',
  height: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain', // Changed from 'cover' to 'contain'
}));

const StyledCenteredSection = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [hangleGoogle, { loading: loadingGoogleAuth }] = useMutation(googleLogin, {
    onCompleted: (data) => {
      dispatch(setUser(data?.googleLogin));
      navigate('/client/dashboard', { replace: true });
    },
  });

  const mdUp = useResponsive('up', 'md');

  const responseGoogle = async (response) => {
    hangleGoogle({
      variables: {
        input: {
          email: response?.profileObj?.email,
          lastName: response?.profileObj?.familyName,
          firstName: response?.profileObj?.givenName,
        },
      },
    });
  };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: googleClientId,
        scope:
          'email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.gender.read',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  return (
    <>
      <Helmet>
        <title> Login | BeeBot </title>
      </Helmet>

      <StyledRoot>
        {mdUp && <StyledSection></StyledSection>}

        <Container maxWidth="sm">
          <StyledContent>
          <StyledCenteredSection>
          <Logo
                sx={{
                  top: { xs: 16, sm: 24, md: 40 },
                  left: { xs: 16, sm: 24, md: 40 },
                }}
              />
            </StyledCenteredSection>
            <LoginForm loadingGoogleAuth={loadingGoogleAuth} />
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>
            <Stack direction="row" spacing={2}>
              <GoogleLogin
                clientId={googleClientId}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                    // sx={{ justifyContent: 'space-between' }}
                  >
                    <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                    <Typography sx={{ marginLeft: 5 }}>Continue with Google</Typography>
                  </Button>
                )}
                buttonText="Continue with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />

              {/* <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button> */}
            </Stack>
            <StyledCenteredSection>
              <Typography variant="body2" sx={{ mb: 5 }}>
                Don’t have an account? {''}
                <Link href="/signup" variant="subtitle2">
                  Sign up
                </Link>
              </Typography>
            </StyledCenteredSection>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
