import { Divider, Grid, Hidden, Stack, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FileUploader from 'src/components/file-uploader/FileUploader';
import { setBusinessRegistrationData } from 'src/redux/businessregistration.slice';
import { StyledHeader, StyledHeaderContainer } from './styled';

// ----------------------------------------------------------------------

export default function Step4({ setStep, step }) {
  const { data } = useSelector((state) => state.businessregistration);
  const [proprietorMeansOfIdentification, setProprietorMeansOfIdentification] = useState(
    data?.proprietorMeansOfIdentification
  );
  const [proprietorID, setProprietorID] = useState(data?.proprietorID ? data?.proprietorID : null);
  const [proprietorPassportPhoto, setProprietorPassportPhoto] = useState(
    data?.proprietorPassportPhoto ? data?.proprietorPassportPhoto : null
  );
  const [proprietorSignature, setproprietorSignature] = useState(
    data?.proprietorSignature ? data?.proprietorSignature : null
  );

  const handleChange = (event) => {
    setProprietorMeansOfIdentification(event.target.value);
  };

  const dispatch = useDispatch();

  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(
      setBusinessRegistrationData({
        ...data,
        proprietorMeansOfIdentification,
        proprietorID,
        proprietorPassportPhoto,
        proprietorSignature,
      })
    );
    handleNextStep();
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12} container>
        <StyledHeaderContainer>
          <img src="/assets/icons/NatureofBusiness.svg" alt="Nature of Business" />
          <StyledHeader>Upload Documents</StyledHeader>
        </StyledHeaderContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Means of Identification *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={proprietorMeansOfIdentification}
            label="Means of Identification"
            onChange={handleChange}
            // {...register('proprietorMeansOfIdentification', { required: true })}
          >
            <MenuItem value="National ID">National ID</MenuItem>
            <MenuItem value="International Passport">International Passport</MenuItem>
            <MenuItem value="Driver's Licence">Driver's Licence</MenuItem>
            <MenuItem value="Voter's Card">Voter's Card</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FileUploader
          setValue={setProprietorID}
          value={proprietorID}
          name="proprietorID"
          icon="/assets/icons/PassportIcon.svg"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Passport Photograph</Typography>
        <FileUploader
          setValue={setProprietorPassportPhoto}
          value={proprietorPassportPhoto}
          icon="/assets/icons/PassportIcon.svg"
          name="proprietorPassportPhoto"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Signature</Typography>
        <FileUploader
          setValue={setproprietorSignature}
          value={proprietorSignature}
          icon="/assets/icons/SignatureIcon.svg"
          name="proprietorSignature"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>

      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction="row" spacing={2}>
            <LoadingButton variant="outlined" onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!proprietorID || !proprietorPassportPhoto || !proprietorSignature}
            >
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <Stack spacing={2}>
            <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              disabled={!proprietorID || !proprietorPassportPhoto || !proprietorSignature}
            >
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
    </Grid>
  );
}
