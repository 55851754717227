import { Grid, TextField, Hidden, Typography, Autocomplete } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { StyledTextInput } from 'src/components/input/Input';
import { setNameReservationData } from 'src/redux/namereservation.slice';
import { CLASSIFICATIONS, SPECIFIC_TYPE } from 'src/utils/Constants';
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  topInput: {
    [`& fieldset`]: {
      borderRadius: '5px 5px 0 0',
    },
  },
  bottomInput: {
    [`& fieldset`]: {
      borderRadius: '0 0 5px 5px',
    },
  },
}));

export default function Step1({ setStep, step }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data } = useSelector((state) => state.namereservation);
  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setNameReservationData(data));
    handleNextStep();
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          defaultValue={data?.classification}
          options={CLASSIFICATIONS.map((option) => option)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Classification*"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              name="classification"
              {...register('classification', { required: true })}
              fullWidth
            />
          )}
        />
        {/* <TextField
          name="classification"
          label="Classification*"
          fullWidth
          {...register('classification', { required: true })}
        /> */}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Autocomplete
          freeSolo
          id="free-specific_type"
          disableClearable
          defaultValue={data?.type}
          options={SPECIFIC_TYPE.map((option) => option)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              name="type"
              label="Specific Type*"
              fullWidth
              {...register('type', { required: true })}
            />
          )}
        />
        {/* <TextField name="type" label="Specific Type*" fullWidth {...register('type', { required: true })} /> */}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography>Proposed Names</Typography>
        <StyledTextInput
          className={classes.topInput}
          placeholder="Option 1*"
          fullWidth
          defaultValue={data?.option1}
          {...register('option1', { required: true })}
        />
        <StyledTextInput
          className={classes.bottomInput}
          placeholder="Option 2"
          defaultValue={data?.option2}
          fullWidth
          {...register('option2')}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="contained" type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="contained" fullWidth type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
    </Grid>
  );
}
