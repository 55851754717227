import {gql} from "@apollo/client";

export const CREATE_CAC_CLIENT = gql`
  mutation create($input: CacClientInput!){
      create(input: $input){
        _id
        companyName
        active
        apiKey
        phoneNumber
        address
        email
        apiKey
        wallet
      }
    }   
`;
export const UPDATE_WALLET_BALANCE = gql`
  mutation updateCacClientWallet($input: CacClientWalletUpdateInput!){
      updateCacClientWallet(input: $input){
        _id
        wallet
      }
    }   
`;

export const TOGGLE_EDITABLE = gql`
    mutation toggle($id: String!){
      toggleEditable(id: $id){
        _id
        editable
      }
    }
`;