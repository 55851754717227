import { gql } from '@apollo/client';

export const GET_ORDER_PROJECTS = gql`
	query getOrderProjects($orderId: String!){
		getOrderProjects(orderId: $orderId){
			_id
		product{
			_id
			productName
		}
		projectQuestionAndAnswer{
			question
			answer
		}
		}
	}
`;

export const GET_ORDER_DETAILS = gql`
query getOrder($orderId: String!){
  getOrder(orderId: $orderId){
    _id
    products{
      _id
      productName
      questions
    }
    package{
      packageType
    }
    user{
      _id
      email
      firstName
      lastName
      phoneNumber
      gender
      address
      city
      dob
      postCode
    }
  }
} 
`;

export const GET_ADMIN_ORDERS = gql`
 query getAllAdminOrders($page: Float!, $pageSize: Float!, $searchTerm: String, $orderStatus: String){
    getAllAdminOrders(page: $page, pageSize: $pageSize, searchTerm: $searchTerm, orderStatus: $orderStatus){
      orders{
          _id
          created_at
          orderStatus
          amount
          products {
              _id
              productName
              questions
              amount
              created_at
          }
          paymentDetails {
              amount
              status
              created_at
          }
          package{
            packageType
          }
          user{
            _id
            email
            firstName
            lastName
            phoneNumber
          }
      }
       pageInfo{
      pageSize
      totalPages
      currentPage
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
      totalItems
    }
    }
  } 
`;
