import { gql } from '@apollo/client';

export const GET_ALL_CERTIFIED_TRUE_COPY_REQUEST = gql`
  query {
    allCertifiedTrueCopy {
      _id
      organisationName
      documentType
      rcNumber
      created_at
      paymentDetails {
        _id
        amount
        serviceId
        transactionId
        status
      }
      status
      user {
        _id
        lastName
        firstName
        email
        gender
        address
        phoneNumber
      }
      authorisedSignature
      authorisedID
      application
      affidavit
    }
  }
`;
export const UPADATE_CERTIFIED_TRUE_COPY_STATUS = gql`
  mutation updateCertifiedTrueCopyApprovalStatus($input: CertifiedTrueCopyStatusInput!) {
    updateCertifiedTrueCopyApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;

export const GET_PAGINATED_CTC = gql`
query paginatedctc($page: Float!, $pageSize: Float!, $searchTerm: String) {
  allCertifiedTrueCopyPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm){
    returns{
      _id
      organisationName
      documentType
      rcNumber
      created_at
      paymentDetails {
        _id
        amount
        serviceId
        transactionId
        status
      }
      status
      user {
        _id
        lastName
        firstName
        email
        gender
        address
        phoneNumber
      }
      authorisedSignature
      authorisedID
      application
      affidavit
    },
    pageInfo {
      currentPage
      pageSize
      totalPages
      totalItems
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
    }
  }
}

`