import React from 'react';
import { Modal, Box, TextField, Button, Typography, CircularProgress, Alert } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Stack } from "@mui/system";
import { useMutation } from '@apollo/client';
import { CREATE_CAC_CLIENT } from "../../graphql/mutations/cacClient";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function ClientRegistrationModal({ open, handleClose, refetch }) {
    const { control, handleSubmit, reset } = useForm();
    const [createCacClient, { data: cacClient, error, loading }] = useMutation(CREATE_CAC_CLIENT);

    const onSubmit = async data => {
        try {
            await createCacClient({ variables: { input: data } });
            if(!error){
                reset();
                handleClose();
                refetch();
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Modal
            open={open}
            onClose={(_, reason) => reason === 'backdropClick' && false}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                    New Client Registration
                </Typography>
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error.message}
                    </Alert>
                )}
                {cacClient && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Client ${cacClient.create.companyName} created successfully
                    </Alert>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="companyName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField {...field} fullWidth margin="normal" label="Company Name" />
                        )}
                    />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField {...field} fullWidth margin="normal" label="Phone Number" />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField {...field} fullWidth margin="normal" label="Email" />
                        )}
                    />
                    <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                margin="normal"
                                label="Address"
                                multiline
                                rows={3}
                            />
                        )}
                    />
                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Submit
                        </Button>
                        <Button onClick={handleClose} type="button" variant="outlined" color="primary">
                            Close
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}
