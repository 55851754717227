import React, {useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, Stack, Alert} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import {styled} from "@mui/material/styles";
import {useMutation} from "@apollo/client";
import {UPDATE_WALLET_BALANCE} from "../../graphql/mutations/cacClient";

export default function ClientAccordion({client, refetch}) {
    const [balance, setBalance] = useState('');
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState('success');

    const [updateWallet, {data, error}] = useMutation(UPDATE_WALLET_BALANCE, {
        onCompleted: (data) => {
            setMessage('Balance updated successfully!');
            setMessageType('success');
            setBalance('');
        },
        onError: (error) => {
            setMessage('Failed to update balance. Please try again.');
            setMessageType('error');
        }
    });

    const handleAddBalance = async () => {
        await updateWallet(
            {
                variables: {
                    input: {
                        _id: client._id,
                        amount: balance
                    }
                }
            }
        );
    };

    const handleDeactivate = () => {
        // Add your logic to handle deactivation
        console.log(`Deactivated ${client.companyName}`);
    };

    const StyledElementContainer = styled('div')(({theme}) => ({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1rem',
    }));
    console.log(client)

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Stack spacing={2}>
                    <Typography variant="h6">Company Name: {client.companyName}</Typography>
                    <Button variant="outlined">Date Created: {dayjs(client.dateCreated).format('MMMM D, YYYY')}</Button>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <StyledElementContainer>
                    <Typography variant="body1" color="text.secondary">
                        Phone: {client.phoneNumber}
                    </Typography>
                </StyledElementContainer>

                <StyledElementContainer>
                    <Typography variant="body1" color="text.secondary">
                        Email: {client.email}
                    </Typography>
                </StyledElementContainer>

                <StyledElementContainer>
                    <Typography variant="body1" color="text.secondary">
                        Address: {client.address}
                    </Typography>
                </StyledElementContainer>
                <StyledElementContainer>
                    <Typography variant="body1" color="text.secondary">
                        Api Key: {client.apiKey}
                    </Typography>
                </StyledElementContainer>
                <StyledElementContainer>
                    <Typography variant="body1" color="text.secondary">
                        Wallet: {client.wallet}
                    </Typography>
                </StyledElementContainer>
                <StyledElementContainer>
                    <Typography variant="body1" color="text.secondary">
                        Date Created: {dayjs(client.createdAt).format('MMMM D, YYYY')}
                    </Typography>
                </StyledElementContainer>

                {message && (
                    <StyledElementContainer>
                        <Alert severity={messageType}>{message}</Alert>
                    </StyledElementContainer>
                )}

                <Stack direction="row" spacing={2} sx={{mt: 2}}>
                    <TextField
                        label="Add Balance"
                        variant="outlined"
                        size="small"
                        value={balance}
                        onChange={(e) => setBalance(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddBalance}>
                        Add
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleDeactivate}>
                        Deactivate
                    </Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
