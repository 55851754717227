import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_BUSINESS_NAME_REGISTRATION_REQUEST } from 'src/graphql/queries/businessName';
import BusinessRegistrationRequestCard from 'src/sections/@dashboard/business-registration-requests/BusinessRegistrationRequestCard';
import { useEffect, useState } from 'react';
import { UPADATE_BUSINESS_REGISTRATION_STATUS } from 'src/graphql/mutations/businessname';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminBusinessNameRegistrations() {
  const { data, loading: loadingData } = useQuery(GET_ALL_BUSINESS_NAME_REGISTRATION_REQUEST);

  const [search, setSearch] = useState('');
  const [nameReservationFiltered, setNameReservationFiltered] = useState([]);
  const [businessNameRegistrationRequests, setBusinessNameRegistrationRequests] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateBusinessRegistrationApprovalStatus, { data: updatedReservation, loading }] = useMutation(
    UPADATE_BUSINESS_REGISTRATION_STATUS
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBusinessNameRegistrationRequests(data?.allBusinessNames);
  }, [data?.allBusinessNames]);

  useEffect(() => {
    if (businessNameRegistrationRequests?.length) {
      setBusinessNameRegistrationRequests(businessNameRegistrationRequests);
    }
  }, [businessNameRegistrationRequests]);

  useEffect(() => {
    const lowerCaseSearchTerm = search.toLowerCase();
    if (search.length) {
      setNameReservationFiltered(() =>
        businessNameRegistrationRequests?.filter((request) => {
          return (
            request?.businessName.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?._id.toLowerCase().includes(lowerCaseSearchTerm)
          );
        })
      );
    } else {
      setNameReservationFiltered(businessNameRegistrationRequests);
    }
  }, [search, businessNameRegistrationRequests]);

  useEffect(() => {
    if (updatedReservation?.updateBusinessRegistrationApprovalStatus && updateStatus) {
      setUpdateStatus(false);
      //Initialize array of objects.
      let tempReservations = [...businessNameRegistrationRequests];

      //Find index of specific object using findIndex method.
      const objIndex = tempReservations.findIndex(
        (obj) => obj._id === updatedReservation?.updateBusinessRegistrationApprovalStatus?._id
      );

      //Update object's name property.
      tempReservations[objIndex].status = updatedReservation?.updateBusinessRegistrationApprovalStatus?.status;

      //Log object to console again.
      setBusinessNameRegistrationRequests(tempReservations);
    }
  }, [updatedReservation?.updateBusinessRegistrationApprovalStatus, businessNameRegistrationRequests, updateStatus]);

  const changeStatus = (_id, status, adminMessage = '') => {
    setUpdateStatus(true);
    enqueueSnackbar('Status updated!');
    updateBusinessRegistrationApprovalStatus({
      variables: { input: { _id, status, adminMessage: adminMessage } },
    });
  };

  return (
    <>
      <Helmet>
        <title> Business Registration Submissions | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Business Registration submissions
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={12} sm={12}>
            <TextField
              value={search}
              fullWidth
              placeholder="Search Reservation by Proposed name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          {loadingData && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {nameReservationFiltered?.map((post, index) => (
            <BusinessRegistrationRequestCard
              key={post._id}
              post={post}
              index={index}
              loading={loading}
              changeStatus={changeStatus}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
}
