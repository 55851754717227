import { gql } from '@apollo/client';

export const UPDATE_NAME_RESERVATION_PAYMENT_STATUS = gql`
  mutation updateNameReservationPaymentStatus($paymentUpdateInput: PaymentUpdateInput!) {
    updateNameReservationPaymentStatus(paymentUpdateInput: $paymentUpdateInput) {
      _id
      created_at
    }
  }
`;
export const UPDATE_PAYMENT_STATUS = gql`
  mutation updatePaymentStatus($input: PaymentUpdateInput!) {
    updatePaymentStatus(input: $input) {
      created_at
    }
  }
`;

export const UPDATE_PAYMENT_STATUS_MANUAL = gql`
  mutation resolvePaymentStatus($input: PaymentUpdateInput!, $user: String!) {
    resolvePaymentStatus(input: $input, user: $user) {
    _id
      amount
      status
      serviceId
    }
  }
`;

