import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import Pdf from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
// @mui
import { Button, Divider, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fDate } from '../../../utils/formatTime';
//
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

export const ComponentToPrint = React.forwardRef(({ post }, ref) => {
  const {
    email,
    userName,
    businessName,
    firstName,
    lastName,
    phoneNumber,
    gender,
    street,
    city,
    lga,
    state,
    isYourBusinessRegistered,
  } = post;

  const FieldCheck = ({ partnerField, title, args }) => {
    return (
      partnerField && (
        <>
          <StyledElementContainer>
            <Typography variant="caption">{title}</Typography>
            {args && <Typography variant="subtitle2">{`${args}`}</Typography>}
            {!args && <Typography variant="subtitle2">{`${partnerField}`}</Typography>}
          </StyledElementContainer>
          <Divider />
        </>
      )
    );
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5">Dukka Account Opening Details</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <FieldCheck title={`Email`} partnerField={email} />
            <FieldCheck title={`Business Name`} partnerField={businessName} />
            <FieldCheck title={`UserName`} partnerField={userName} />
            <FieldCheck title={`First Name`} partnerField={firstName} />
            <FieldCheck title={`Last Name`} partnerField={lastName} />
            <FieldCheck title={`Phone Number`} partnerField={phoneNumber} />
            <FieldCheck title={`Gender`} partnerField={gender} />
            <FieldCheck title={`City`} partnerField={city} />
            <FieldCheck title={`Street`} partnerField={street} />
            <FieldCheck title={`Local Government Area`} partnerField={lga} />
            <FieldCheck title={`State`} partnerField={state} />
            <FieldCheck title={`Is your Business Registered`} partnerField={isYourBusinessRegistered} />
          </StyledContent>
        </Grid>
      </Grid>
    </div>
  );
});

DukkaAccountRequestCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function DukkaAccountRequestCard({ post, index, changeStatus, loading }) {
  const { businessName, created_at, user } = post;

  const [expanded, setExpanded] = React.useState(false);
  const componentRef = React.useRef();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          action={<IconButton aria-label="settings"></IconButton>}
          title={`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
          subheader={`Submitted: ${fDate(created_at)}`}
        />
        <CardActions disableSpacing>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Business Name: ${businessName}`}
          </LoadingButton>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button onClick={handlePrint}>Print</Button>
                <Pdf targetRef={componentRef} filename="code-example.pdf">
                  {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                </Pdf>
              </Stack>

              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
