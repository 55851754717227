import { gql } from '@apollo/client';

export const LOGOUT_QUERY = gql`
  query logout {
    logout {
      statusCode
      message
      success
    }
  }
`;

export const GET_USER = gql`
  query me {
    me {
      _id
      firstName
      lastName
      email
      phoneNumber
      role
      verified
    }
  }
`;
