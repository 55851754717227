// @mui
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setService } from 'src/redux/service.slice';
// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const StyledCard = styled(Card)`
  box-shadow: 0;
  text-align: center;
  border: 1px solid #dadada;
  height: 200px;
  :hover {
    cursor: pointer;
    border: 1px solid #a80000;
    filter: drop-shadow(0px 4px 4px #e50c0c);
  }
`;

// ----------------------------------------------------------------------

ServiceCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
};

export default function ServiceCard({ title, cost, icon, link, ...other }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <StyledCard
      onClick={() => {
        dispatch(setService({ title, cost, icon, link }));
        navigate(link);
      }}
      sx={{
        py: 4,
        px: 1,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          backgroundColor: 'primary.light',
        }}
      >
        <img src={icon} alt={title} width={34} height={34} />
      </StyledIcon>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </StyledCard>
  );
}
