// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'home',
    path: '/admin/',
    icon: icon('ic_home'),
  },
  {
    title: 'Tickets',
    path: '/admin/tickets',
    icon: icon('ic_user'),
  },
  {
    title: 'Orders',
    path: '/admin/orders',
    icon: icon('ic_user'),
  },

  {
    title: 'Users',
    path: '/admin/list-all-users',
    icon: icon('ic_user'),
  },

  {
    title: 'Bank Accounts',
    path: '/admin/list-all-accounts',
    icon: icon('ic_user'),
  },

  {
    title: 'Bookkeeping',
    path: '/admin/bookkeeping',
    icon: icon('ic_user'),
  },

  {
    title: 'business registrations',
    icon: icon('ic_user'),
    items: [
      {
        title: 'name reservation',
        path: '/admin/name-reservation-requests',
        icon: icon('ic_user'),
      },
      {
        title: 'business name registration',
        path: '/admin/business-registration-applications',
        icon: icon('ic_user'),
      },
    
      {
        title: 'Company Registration',
        path: '/admin/list-of-companies',
        icon: icon('ic_user'),
      },
    ]
  },
  {
    title: 'Post-Incorporation',
    icon: icon('ic_user'),
    items: [
      {
        title: 'Annual Return',
        path: '/admin/annual-return',
        icon: icon('ic_user'),
      },
      {
        title: 'Certified True Copy',
        path: '/admin/certified-true-copy',
        icon: icon('ic_user'),
      },
      {
        title: 'Status Report',
        path: '/admin/status-report',
        icon: icon('ic_user'),
      },
      {
        title: 'Change of Directors',
        path: '/admin/change-directors',
        icon: icon('ic_user'),
      },
      {
        title: 'Change of Address',
        path: '/admin/change-address',
        icon: icon('ic_user'),
      },
      {
        title: 'Change of secretary',
        path: '/admin/change-secretary',
        icon: icon('ic_user'),
      },
      {
        title: 'Update of Shareholders Details',
        path: '/admin/update-shareholder',
        icon: icon('ic_user'),
      },
      {
        title: 'Update of Directors Details',
        path: '/admin/update-directors',
        icon: icon('ic_user'),
      },
    ]
  },
  {
    title: 'Other Services',
    icon: icon('ic_user'),
    items: [
      {
        title: 'Trademark',
        path: '/admin/trademark',
        icon: icon('ic_user'),
      },{
        title: 'TIN',
        path: '/admin/tin',
        icon: icon('ic_user'),
      },{
        title: 'Business Starter Toolkit',
        path: '/admin/business-starter-toolkit',
        icon: icon('ic_user'),
      },
    ]
  },
  {
    title: 'Cac Services',
    icon: icon('ic_user'),
    items: [
      {
        title: 'Create a Client',
        path: '/admin/cac',
        icon: icon('ic_user'),
      },{
        title: 'Cac Business Registration',
        path: '/admin/business-registration-cac',
        icon: icon('ic_user'),
      },{
        title: 'Business Starter Toolkit',
        path: '/admin/business-starter-toolkit',
        icon: icon('ic_user'),
      },
    ]
  }

  // {
  //   title: 'product',
  //   path: '/admin/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/admin/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
