import { gql } from '@apollo/client';

export const USER = gql`
  fragment UserFields on User {
    _id
    firstName
    lastName
    middleName
    email
    gender
    dob
    address
    postCode
    phoneNumber
    thumbnail
    role
    verified
  }
`;
