import { Helmet } from 'react-helmet-async';
import { Grid, Container, Card, Typography, Button } from '@mui/material';
// sections
import { styled } from '@mui/material/styles';
import ServiceCard from '../../components/service-card/ServiceCard';
import ButtomNavigation from 'src/components/buttom-navigation/ButtomNavigation';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------
const StyledHeaderCard = styled(Card)(({ theme }) => ({
  // margin: 'auto',
  display: 'flex',
  width: '100%',
  height: 200,
  padding: 20,
  background: theme.palette.common.black,
  color: theme.palette.common.white,
  // borderRadius: '50%',
  // alignItems: 'center',
  // width: theme.spacing(8),
  // height: theme.spacing(8),
  // justifyContent: 'center',
  // marginBottom: theme.spacing(3),
  backgroundImage: 'url("/assets/images/covers/mask_group.png")',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function BusinessRegistrationPage() {
  const { services } = useSelector((state) => state.service);
  return (
    <>
      <Helmet>
        <title> Services </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <StyledHeaderCard>
              <Grid container spacing={3}>
                <Grid item xs={10} sm={6} md={6} container>
                  <StyledHeaderContainer>
                    <Typography variant="h4">Get your business registered with less stress</Typography>
                    <Button variant="contained" size="large">
                      Get Started
                    </Button>
                  </StyledHeaderContainer>
                </Grid>
              </Grid>
            </StyledHeaderCard>
          </Grid>
          {services.map((service, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <ServiceCard {...service} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <ButtomNavigation />
    </>
  );
}
