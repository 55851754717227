import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
// components
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'src/graphql/mutations/auth';
import { setUser } from 'src/redux/auth.slice';
import { USER_ROLES } from 'src/utils/Constants';
import Iconify from '../../../components/iconify';

const StyledLoginContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
}));

const StyledFormWrapper = styled('div')(({ theme }) => ({
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
  padding: '0 1rem 1rem 1rem',
}));

const StyledFormHeader = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #CFCFCF',
  marginLeft: '-1rem',
  marginRight: '-1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const StyledHeaderIconWrapper = styled('div')`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50px;
  background-color: #ffd3d3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// ----------------------------------------------------------------------

export default function LoginForm({ loadingGoogleAuth }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [login, { data, loading, error }] = useMutation(LOGIN);

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    login({
      variables: { input: {...data, rememberMe: true} },
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (data && data.login) {
      if (data?.login?.role === USER_ROLES.ADMIN) {
        dispatch(setUser(data.login));
        navigate('/admin', { replace: true });
      }else if( data?.login?.role === USER_ROLES.CLIENT){
        dispatch(setUser(data.login));
        navigate('/client', { replace: true });
      } else {
        enqueueSnackbar('You are not authorized to access this page', { variant: 'error' });
      }
    }
  }, [data, error, dispatch, navigate]);

  return (
    <StyledLoginContainer>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <StyledFormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <StyledFormHeader>
              <StyledHeaderIconWrapper>
                <img src="/assets/icons/LockIcon.svg" alt="login" />
              </StyledHeaderIconWrapper>
              <Typography
                variant="h5"
                style={{
                  padding: '0.5rem',
                }}
              >
                Login
              </Typography>
            </StyledFormHeader>
            <TextField name="email" label="Email address" {...register('email', { required: true })} />

            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('password', { required: true })}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="flex-end" pt={1} pb={1}>
            <Link variant="subtitle2" underline="hover" href="forgot-password">
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading || loadingGoogleAuth}
          >
            Login
          </LoadingButton>
        </form>
      </StyledFormWrapper>
    </StyledLoginContainer>
  );
}
