import { gql } from '@apollo/client';

export const UPADATE_ANNUAL_RETURN_STATUS = gql`
  mutation updateAnnualReturnsApprovalStatus($input: AnnualReturnsStatusInput!) {
    updateAnnualReturnsApprovalStatus(input: $input) {
      status
      _id
      adminMessage
    }
  }
`;
