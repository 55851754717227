import { Grid, TextField, Hidden, Divider } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledContent, StyledElementContainer, StyledFieldLabel } from './styled';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessRegistrationData } from 'src/redux/businessregistration.slice';
// ----------------------------------------------------------------------

export default function Step1({ setStep, step, selectedReservedName }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.businessregistration);
  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setBusinessRegistrationData(data));
    handleNextStep();
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="businessName"
          label="Business Name"
          fullWidth
          required
          defaultValue={selectedReservedName?.proposedNames?.optionOne}
          {...register('businessName', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledContent>
          <StyledElementContainer>
            <StyledFieldLabel>Business Address*</StyledFieldLabel>
          </StyledElementContainer>
          <Divider />
          <TextField
            name="businessAddress"
            label=""
            fullWidth
            required
            defaultValue={data?.businessAddress}
            {...register('businessAddress', { required: true })}
          />
        </StyledContent>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledContent>
          <StyledElementContainer>
            <StyledFieldLabel>Email*</StyledFieldLabel>
          </StyledElementContainer>
          <Divider />
          <TextField
            name="businessEmail"
            type="email"
            label=""
            fullWidth
            required
            defaultValue={data?.businessEmail}
            {...register('businessEmail', { required: true })}
          />
        </StyledContent>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="businessPhone"
          label="Business Phone"
          fullWidth
          required
          defaultValue={data?.businessPhone}
          {...register('businessPhone', { required: true })}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="contained" type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton variant="contained" fullWidth type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
    </Grid>
  );
}
