import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { GET_USER } from 'src/graphql/queries/auth';
import { setUser } from 'src/redux/auth.slice';

const RequireAuth = ({ children }) => {
  const {loading, error, data } = useQuery(GET_USER);
  let location = useLocation();
  const dispath = useDispatch();

  useEffect(() => {
    if(data && data?.me) {
      dispath(setUser(data.me));
    }
  }, [data, dispath]);

  if (loading) return <div>Loading...</div>;

  if (!data || error) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (data && data?.me && !data?.me?.verified) {
    return <Navigate to="/verify-email" />;
  }

  return children;
};

export default RequireAuth;
