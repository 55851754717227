import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    authenticated: false
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.authenticated = action.payload ? true : false;
    },

    setUpdatedUser: (state, action) => {
      state.user = action.payload;
      state.authenticated = true;
    },

    logOutUser: (state) => {
      state.user = null;
      state.authenticated = false;
    }
  }
});

export const authReducer = authSlice.reducer;

export const { setUser, logOutUser } = authSlice.actions;
