import { Helmet } from 'react-helmet-async';
// @mui
import {
  Grid,
  Container,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
} from '@mui/material';
// components
// mock
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { GET_ALL_TICKETS_PAGINATED } from 'src/graphql/queries/ticket';
import moment from 'moment';
import { RESOLVE_TICKET } from 'src/graphql/mutations/ticket';
// ----------------------------------------------------------------------
const ORDER_BY = {
  Oldest: { name: 'Oldest', value: 1 },
  Newest: { name: 'Newest', value: -1 },
};
// ----------------------------------------------------------------------

export default function AdminTicketsList() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(ORDER_BY.Oldest);
  const [filterStatus, setFilterStats] = useState('All');

  const {
    data: data2,
    loading,
    fetchMore,
  } = useQuery(GET_ALL_TICKETS_PAGINATED, {
    variables: { page, pageSize, order: orderBy.value, filter: filterStatus },
  });

  const [resolveTicket, { data, loading: loadingUpdateTicket }] = useMutation(RESOLVE_TICKET);

  const handleChange = (event) => {
    setOrderBy(ORDER_BY[event.target.value]);
  };

  const handleStatusChange = (event) => {
    setFilterStats(event.target.value);
  };

  const handleResolveTicket = async (ticketId) => {
    await resolveTicket({ variables: { ticketId } });
  };

  useEffect(() => {
    if (data) {
      enqueueSnackbar('Ticket resolved successfully!');
      fetchMore({
        variables: { page, pageSize, order: orderBy.value, filter: filterStatus },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
    }
  }, [data]);

  const handlePageChange = async (newPage) => {
    setPage(newPage);
    await fetchMore({
      variables: { page: newPage, pageSize, searchTerm, order: orderBy.value, filter: filterStatus },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  };

  const handleSearchDebounced = useCallback(
    debounce(async (newSearchTerm) => {
      setSearchTerm(newSearchTerm);
      setPage(1);
      setSearchLoading(true);
      await fetchMore({
        variables: { page: 1, pageSize, searchTerm: newSearchTerm, filter: filterStatus },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
      setSearchLoading(false);
    }, 200),
    [fetchMore]
  );

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setSearchLoading(false);
    handleSearchDebounced(newSearchTerm);
  };

  const changeStatus = (_id, status) => {
    enqueueSnackbar('Status updated!');
  };

  return (
    <>
      <Helmet>
        <title> Tickets List | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tickets List
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={6} sm={6}>
            <TextField
              value={searchTerm}
              fullWidth
              placeholder="Search Reservation by name..."
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{searchLoading && <CircularProgress size={20} />}</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="statusLabel">Order By</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={orderBy.name}
                label="menuItem"
                onChange={(event) => handleChange(event)}
              >
                <MenuItem selected={orderBy === ORDER_BY.Oldest} value={ORDER_BY.Oldest.name}>
                  Oldest{' '}
                </MenuItem>
                <MenuItem selected={orderBy === ORDER_BY.Newest} value={ORDER_BY.Newest.name}>
                  Newest{' '}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="statusLabel">Filter Status</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={filterStatus}
                label="menuItem"
                onChange={(event) => handleStatusChange(event)}
              >
                {[
                  'All',
                  'Paid',
                  'Not-Paid',
                  'Internal-Query',
                  'Completed',
                  'Pending',
                  'Customer-Action',
                  'Not-Approved',
                ].map((one) => (
                  <MenuItem selected={filterStatus === one} value={one} key={one}>
                    {one}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {loading && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {data2?.getAllTicketsPaginated?.data?.map((post, index) => (
            <Grid item md={12} sm={12} xs={12} key={index}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Title: {post.title}</Typography>
                {post.status !== 'Resolved'? (
                  <Button disabled={loadingUpdateTicket} onClick={() => handleResolveTicket(post._id)}>
                    Mark as Resolved
                  </Button>
                ): (
                    <Button color='success' variant='outlined'>
                        Resolved
                    </Button>
                )}
              </Stack>
              <Typography variant="body2">Description: {post.description}</Typography>
              <Typography variant="body2">{`Name: ${post.createdBy.firstName} ${post.createdBy.lastName}`}</Typography>
              <Typography variant="body2">{`Email: ${post.createdBy.email}`}</Typography>
              <Typography variant="body2">{`Status: ${post.status}`}</Typography>
              <Typography variant="body2">{post?.createdAt}</Typography>
              {post?.attachments?.map((attachment, index) => (
                <Typography variant="body2" key={index}>
                  <a href={attachment} target="_blank" rel="noreferrer">
                    Attachment {index + 1}
                  </a>
                </Typography>
              ))}

              <Divider />
            </Grid>
          ))}
          <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button variant="outlined" onClick={() => handlePageChange(page - 1)} disabled={page <= 1 || loading}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="body2">Page {page}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= data2?.findAllUsersPaginated?.pageInfo?.totalPages || loading}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
