import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const StyledTextInput = styled(TextField)`
  margin: 0 !important;
  input {
    margin: 0;
  }
`;

export const StyledTextInputCenter = styled(TextField)`
  margin: 0 !important;
  input {
    margin: 0;
  }
  fieldset {
    border-radius: 0;
  }
`;
