import {gql} from "@apollo/client";

export const GET_ALL_TIN_PAGINATED = gql`
  query PaginatedReturnsTest($page: Float!, $pageSize: Float!, $searchTerm: String) {
    allTaxProMaxPaginated(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
       returns{
       _id
    companyName
    rcNumber
    tin
    companyAddress
    designation
    email
    firstName
    lastName
    phoneNumber
    created_at
    paymentDetails{
        amount
        transactionId
        serviceId
        status
    }
    user{
        firstName
        lastName
        email
        phoneNumber
        dob
    }
    adminMessage
    incorporationCertificate
    status
    }
    pageInfo{
      currentPage
      pageSize
      totalPages
      totalItems
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
    }
    }
  }
`;
