import { gql } from '@apollo/client';

export const GET_NAME_RESERVATION_SUBMISSIONS = gql`
  query {
    allNameReservations {
      _id
      classification
      specificType
      NatureOfBusiness
      reasonsForAvailabilitySearch
      additionalComment
      rcNumber
      NatureOfBusiness
      companyName
      adminMessage
      customerReview
      starRating
      paymentDetails {
        serviceId
        status
        transactionId
        amount
      }
      proposedNames {
        optionOne
        optionTwo
      }
      user {
        firstName
        lastName
        email
        phoneNumber
      }
      status
      created_at
    }
  }
`;
