import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { authReducer } from './auth.slice';
import { businessRegistrationReducer } from './businessregistration.slice';
import { companyRegistrationReducer } from './companyregistration.slice';
import { nameReservationReducer } from './namereservation.slice';
import { serviceReducer } from './service.slice';
import storage from './storage';

const rootReducer = combineReducers({
  auth: authReducer,
  businessregistration: businessRegistrationReducer,
  namereservation: nameReservationReducer,
  service: serviceReducer,
  companyregistration: companyRegistrationReducer,
});

const persistConfig = {
  timeout: 0,
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

export default store;
