import { Box, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// sections
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Step1 from 'src/sections/company-registration/Step1';
import Step2 from 'src/sections/company-registration/Step2';
import Step3 from 'src/sections/company-registration/Step3';
import Step4 from 'src/sections/company-registration/Step4';
import Step5 from 'src/sections/company-registration/Step5';
import Step6 from 'src/sections/company-registration/Step6';
import Step7 from 'src/sections/company-registration/Step7';

import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import posts from 'src/_mock/blog';
import EmptyBusinessName from 'src/components/empty-businessname/EmptyBusinessName';
import NameReservationsLoading from 'src/components/name-reservations-loading/NameReservationsLoading';
import ReservedNameCard from 'src/components/reserved-names-card/ReservedNameCard';
import { NAME_RESERVATIONS } from 'src/graphql/mutations/nameReservation';
import { setCompanyRegistrationData } from 'src/redux/companyregistration.slice';
import { BlogPostsSearch } from 'src/sections/@dashboard/blog';
// ----------------------------------------------------------------------
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: 'linear-gradient(90deg, #9A0000 50%, #E60000 88.03%)',
  },
}));

export default function CompanyRegistrationPage() {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(20);
  const [selectedReservedName, setSelectedReservedName] = useState(null);
  const { data: userReservedNames, loading } = useQuery(NAME_RESERVATIONS);
  const totalSteps = 7;
  const dispatch = useDispatch();
  useEffect(() => {
    setProgress((100 / totalSteps) * step);
  }, [step, totalSteps]);

  const handleSelectReserveName = (data) => {
    dispatch(setCompanyRegistrationData({ nameReservation: data }));
    setSelectedReservedName(data);
  };

  return (
    <>
      <Helmet>
        <title> Company Registration </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {!selectedReservedName && (
            <>
              <Grid item xs={12} sm={12} md={12} container justifyContent="center">
                <Typography variant="h4"> Reserved Names</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <BlogPostsSearch posts={posts} />
              </Grid>

              {userReservedNames?.nameReservations?.length === 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <EmptyBusinessName />
                </Grid>
              )}
              {loading && (
                <Grid item xs={12} sm={12} md={12}>
                  <NameReservationsLoading />
                </Grid>
              )}

              {userReservedNames?.nameReservations?.map((name, index) => (
                <Grid item xs={12} sm={12} md={6} key={name?._id}>
                  <ReservedNameCard
                    _id={name?._id}
                    name={name}
                    natureOfBusiness={name?.NatureOfBusiness}
                    proposedName={name?.proposedNames?.optionOne}
                    status={name?.status}
                    setSelectedReservedName={handleSelectReserveName}
                  />
                </Grid>
              ))}
            </>
          )}
          {selectedReservedName && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography>{`Step ${step} of ${totalSteps} Company Details`}</Typography>
                  </Box>
                  <BorderLinearProgress variant="determinate" value={progress} />
                </Box>
              </Grid>
              {step === 1 && <Step1 setStep={setStep} step={step} selectedReservedName={selectedReservedName} />}
              {step === 2 && <Step2 setStep={setStep} step={step} selectedReservedName={selectedReservedName} />}
              {step === 3 && <Step3 setStep={setStep} step={step} selectedReservedName={selectedReservedName} />}
              {step === 4 && <Step4 setStep={setStep} step={step} selectedReservedName={selectedReservedName} />}
              {step === 5 && <Step5 setStep={setStep} step={step} selectedReservedName={selectedReservedName} />}
              {step === 6 && <Step6 setStep={setStep} step={step} selectedReservedName={selectedReservedName} />}
              {step === 7 && <Step7 setStep={setStep} step={step} selectedReservedName={selectedReservedName} />}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
