import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Skeleton, Stack } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPDATE_PAYMENT_STATUS } from 'src/graphql/mutations/payment';

export const StyledSectionContainer = styled('section')`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const StyledButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const ProcessingSubscrption = () => {
  return (
    <StyledSectionContainer>
      <Skeleton variant="circular" width={60} height={60} />
      <Stack spacing={1}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="rectangular" width={210} height={60} />
        <Skeleton variant="rounded" width={210} height={60} />
      </Stack>
    </StyledSectionContainer>
  );
};

const SuccessDisplay = () => {
  const navigate = useNavigate();
  return (
    <StyledSectionContainer>
      <img src="/assets/icons/SuccessIcon.svg" alt="tick" width="200" />
      <h3>{`Your Payment was successful, you will receive an email after Corporate Affairs Commission approves or denies application.`}</h3>
      <StyledButtonContainer>
        <Button variant="contained" startIcon={<ArrowBack />} onClick={() => navigate('/')}>
          Dashboard
        </Button>
      </StyledButtonContainer>
    </StyledSectionContainer>
  );
};

const Message = ({ message, navigate }) => (
  <StyledSectionContainer>
    <img src="/assets/icons/SuccessIcon.svg" alt="tick" width="200" />
    <p>{message}</p>
    <StyledButtonContainer>
      <Button variant="contained" startIcon={<ArrowBack />} onClick={() => navigate('/')}>
        Dashboard
      </Button>
    </StyledButtonContainer>
  </StyledSectionContainer>
);

function PaymentConfirmationPage() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [transactionId, setTransactionId] = useState('');
  let [transactionRef, setTransactionRef] = useState('');
  const [amount, setAmount] = useState(false);
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [requestSent, setRequestSent] = useState('');
  const [updatePaymentStatus, { loading, data, error }] = useMutation(UPDATE_PAYMENT_STATUS);
  console.log(data, error);

  useEffect(() => {
    if (!requestSent && status && transactionId && transactionRef && amount) {
      setRequestSent(true);
      updatePaymentStatus({
        variables: {
          input: {
            status,
            tx_ref: transactionRef,
            transaction_id: transactionId,
            serviceAmount: parseInt(amount),
            type,
          },
        },
      });
    }
  }, [requestSent, status, transactionId, transactionRef, amount, updatePaymentStatus, type]);

  const navigate = useNavigate();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('status') === 'successful') {
      setSuccess(true);
      setStatus(query.get('status'));
      setAmount(query.get('amount'));
      setTransactionId(query.get('transaction_id'));
      setTransactionRef(query.get('tx_ref'));
      setType(query.get('type'));
    }

    if (query.get('status') === 'cancelled') {
      setSuccess(false);
      setStatus(query.get('status'));
      setAmount(query.get('amount'));
      setTransactionId(query.get('transaction_id'));
      setTransactionRef(query.get('tx_ref'));
      setType(query.get('type'));
      setMessage("Payment canceled -- continue to explore and checkout when you're ready.");
    }
  }, [transactionId, transactionRef]);

  if (!success && message === '') {
    return <></>;
  } else if (success && transactionId !== '') {
    return loading ? (
      <ProcessingSubscrption />
    ) : (
      <SuccessDisplay transactionId={transactionId} transactionRef={transactionRef} />
    );
  } else {
    return loading ? <ProcessingSubscrption /> : <Message message={message} navigate={navigate} />;
  }
}

export default PaymentConfirmationPage;
