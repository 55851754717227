import { Divider, FormControl, Grid, Hidden, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import FileUploader from '../file-uploader/FileUploader';
// ----------------------------------------------------------------------

export const MeansOfIdentityInput = ({
  register,
  identityCard,
  setMeansOfID,
  signature,
  setSignature,
  idtype,
  handleChange,
  directorsInfo,
  type,
}) => {
  return (
    <Grid item container xs={12} sm={12} md={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Means of Identification *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={idtype}
              label="Means of Identification"
              onChange={handleChange}
            >
              <MenuItem value="National ID">National ID</MenuItem>
              <MenuItem value="International Passport">International Passport</MenuItem>
              <MenuItem value="Driver's Licence">Driver's Licence</MenuItem>
              <MenuItem value="Voter's Card">Voter's Card</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="identityNumber"
          label="Identity Number"
          fullWidth
          required
          {...register('identityNumber', { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Upload Documents</Typography>
        <FileUploader
          setValue={setMeansOfID}
          value={identityCard}
          name="proprietorID"
          icon="/assets/icons/PassportIcon.svg"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Signature</Typography>
        <FileUploader
          setValue={setSignature}
          value={signature}
          icon="/assets/icons/SignatureIcon.svg"
          name="proprietorSignature"
        />
      </Grid>

      <Grid item container xs={12} sm={12} md={12} spacing={2}>
        <Hidden smDown>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingButton type="submit" variant="contained" style={{ marginRight: '1rem' }}>
              {directorsInfo?.index !== undefined ? `Update ${type}` : `Add ${type}`}
            </LoadingButton>
            <LoadingButton variant="outlined">Cancel</LoadingButton>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item container xs={12} sm={12} md={12} spacing={1}>
            <Grid item xs={12}>
              <LoadingButton variant="outlined" fullWidth>
                Cancel
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton type="submit" variant="contained" fullWidth style={{ marginRight: '1rem' }}>
                {directorsInfo?.index !== undefined ? `Update ${type}` : `Add ${type}`}
              </LoadingButton>
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={12}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};
