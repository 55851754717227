import { createSlice } from '@reduxjs/toolkit';

const nameReservationSlice = createSlice({
  name: 'namereservation',
  initialState: {
    data: {},
  },
  reducers: {
    setNameReservationData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },

    clearNameReservationData: (state, action) => {
      state.data = {};
    },
  },
});

export const nameReservationReducer = nameReservationSlice.reducer;

export const { setNameReservationData, clearNameReservationData } = nameReservationSlice.actions;
