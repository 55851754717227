import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Button, TextField, CircularProgress, Box, Pagination, Grid } from '@mui/material';
import Iconify from '../../components/iconify';
import ClientRegistrationModal from '../../components/cac/CacModal';
import ClientAccordion from '../../components/cac/AdminCardCac';
import { debounce } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_ALL_CAC_CLIENT_PAGINATED } from '../../graphql/queries/cacClient';

export default function AdminCacClientRegistration() {
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const { data, loading, fetchMore, refetch } = useQuery(GET_ALL_CAC_CLIENT_PAGINATED, {
        variables: { page, pageSize, searchTerm },
        notifyOnNetworkStatusChange: true,
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handlePageChange = async (event, value) => {
        setPage(value);
        await fetchMore({
            variables: { page: value, pageSize, searchTerm },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
            },
        });
    };

    const handleSearchDebounced = debounce(async (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setPage(1);
        setSearchLoading(true);
        await fetchMore({
            variables: { page: 1, pageSize, searchTerm: newSearchTerm },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
            },
        });
        setSearchLoading(false);
    }, 200);

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        setSearchLoading(false);
        handleSearchDebounced(newSearchTerm);
    };

    return (
        <>
            <Helmet>
                <title> CAC Client Registration | BeeBot </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        CAC Client Registration
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpen}>
                        New Record
                    </Button>
                </Stack>

                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{ mb: 4 }}
                />

                {loading || searchLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {data?.allCACClient?.allClient?.map((client) => (
                            <ClientAccordion key={client._id} client={client} refetch={refetch}/>
                        ))}
                    </Box>
                )}
                <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" onClick={(event) => handlePageChange(event, page - 1)} disabled={page <= 1 || loading}>
                            Previous
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">Page {page}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={(event) => handlePageChange(event, page + 1)}
                            disabled={page >= data?.allCACClient?.pageInfo?.totalPages || loading}
                        >
                            Next
                        </Button>
                    </Grid>
                    {loading && <CircularProgress size={24} />}
                </Grid>

            </Container>

            <ClientRegistrationModal open={open} handleClose={handleClose} refetch={refetch} />
        </>
    );
}
