import { Helmet } from 'react-helmet-async';
// @mui
import {
  Grid,
  Container,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
// components
// mock
import { useQuery } from '@apollo/client';
import { GET_ALL_CLIENT_USERS_PAGINATED } from 'src/graphql/queries/user';

import { useCallback, useState } from 'react';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import UserListRequestCardClient from 'src/sections/@dashboard/user/UserListRequestCardClient';
// ----------------------------------------------------------------------
const ORDER_BY = {
  Oldest: { name: 'Oldest', value: 1 },
  Newest: { name: 'Newest', value: -1 },
};
// ----------------------------------------------------------------------

export default function UsersList() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(ORDER_BY.Oldest);
  const [filterStatus, setFilterStats] = useState('All');

  const {
    data,
    loading,
    fetchMore,
  } = useQuery(GET_ALL_CLIENT_USERS_PAGINATED, {
    variables: { page, pageSize, order: orderBy.value, filter: filterStatus },
  });

  const handleChange = (event) => {
    setOrderBy(ORDER_BY[event.target.value]);
  };

  const handleStatusChange = (event) => {
    setFilterStats(event.target.value);
  };

  const handlePageChange = async (newPage) => {
    setPage(newPage);
    await fetchMore({
      variables: { page: newPage, pageSize, searchTerm, order: orderBy.value, filter: filterStatus },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  };

  const handleSearchDebounced = useCallback(
    debounce(async (newSearchTerm) => {
      setSearchTerm(newSearchTerm);
      setPage(1);
      setSearchLoading(true);
      await fetchMore({
        variables: { page: 1, pageSize, searchTerm: newSearchTerm, filter: filterStatus },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
      setSearchLoading(false);
    }, 200),
    [fetchMore]
  );

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setSearchLoading(false);
    handleSearchDebounced(newSearchTerm);
  };

  const changeStatus = (_id, status) => {
    enqueueSnackbar('Status updated!');
  };

  return (
    <>
      <Helmet>
        <title> Users List | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User List ({
              data?.findAllClientUsersPaginated?.pageInfo?.totalItems || 0
            })
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={6} sm={6}>
            <TextField
              value={searchTerm}
              fullWidth
              placeholder="Search Reservation by name..."
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{searchLoading && <CircularProgress size={20} />}</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="statusLabel">Order By</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={orderBy.name}
                label="menuItem"
                onChange={(event) => handleChange(event)}
              >
                <MenuItem selected={orderBy === ORDER_BY.Oldest} value={ORDER_BY.Oldest.name}>
                  Oldest{' '}
                </MenuItem>
                <MenuItem selected={orderBy === ORDER_BY.Newest} value={ORDER_BY.Newest.name}>
                  Newest{' '}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="statusLabel">Filter Status</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={filterStatus}
                label="menuItem"
                onChange={(event) => handleStatusChange(event)}
              >
                {[
                  'All'
                ].map((one) => (
                  <MenuItem selected={filterStatus === one} value={one} key={one}>
                    {one}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {loading && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {data?.findAllClientUsersPaginated?.allClient?.map((post, index) => (
            <UserListRequestCardClient
              key={post._id}
              post={post}
              index={index}
              loading={loading}
              changeStatus={changeStatus}
            />
          ))}

          <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button variant="outlined" onClick={() => handlePageChange(page - 1)} disabled={page <= 1 || loading}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="body2">Page {page}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= data?.findAllClientUsersPaginated?.pageInfo?.totalPages || loading}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
