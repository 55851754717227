import PropTypes from 'prop-types';
// import ReactApexChart from 'react-apexcharts';
import LinearProgress from '@mui/material/LinearProgress';
// @mui
import { Card, CardHeader, Box, Typography, Grid } from '@mui/material';
// components
import Chart from 'react-apexcharts';

// ----------------------------------------------------------------------

AppServiceProgress.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppServiceProgress({ title, subheader, ...other }) {
  const state = {
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '80%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#636B81',
            strokeWidth: '67%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.25,
            },
          },

          dataLabels: {
            showOn: 'always',
            name: {
              offsetY: -20,
              show: false,
              color: '#FFABAB',
              fontSize: '13px',
            },
            value: {
              formatter: function (val) {
                return val;
              },
              color: '#111',
              fontSize: '40px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#F9C304'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Total Number of Registration'],
    },
    seriesRadial: [77],
  };
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Grid container>
        <Grid item md={7} lg={10} sm={12} xs={12}>
          <Box sx={{ p: 3, pb: 5, display: 'flex', flexDirection: 'column', gap: 2 }} dir="ltr">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Registered</Typography>
              <Typography variant="caption">10</Typography>
            </Box>
            <LinearProgress color="inherit" variant="determinate" value={100} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Queried</Typography>
              <Typography variant="caption">6</Typography>
            </Box>
            <LinearProgress color="secondary" variant="determinate" value={60} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Approved</Typography>
              <Typography variant="caption">2</Typography>
            </Box>
            <LinearProgress variant="determinate" value={20} />
          </Box>
        </Grid>
        <Grid item container md={5} lg={2} sm={12} xs={12} justifyItems="center" justifyContent="center">
          <Chart options={state.optionsRadial} series={state.seriesRadial} type="radialBar" width="230" />
          <Typography sx={{ pb: 3 }}>Total Number of Registration</Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
