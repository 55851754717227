import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { useQuery } from '@apollo/client';
import { GET_ALL_DUKKA_ACCOUNT } from 'src/graphql/queries/dukkaAccount';

import { useEffect, useState } from 'react';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import DukkaAccountRequestCard from 'src/sections/@dashboard/dukka/DukkaAccountRequestCard';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminDukkaAccount() {
  const { data, loading: loadingData } = useQuery(GET_ALL_DUKKA_ACCOUNT);

  const [dukkaAccountRequest, setDukkaAccountRequest] = useState([]);
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setDukkaAccountRequest(data?.allDukkaAccount);
  }, [data?.allDukkaAccount]);

  useEffect(() => {
    const lowerCaseSearchTerm = search.toLowerCase();
    if (search.length) {
      setFiltered(() =>
        dukkaAccountRequest?.filter((request) => {
          return (
            request?.firstName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.businessName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.lastName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.phoneNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.userName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.user?.firstName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.user?.lastName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.user?.email?.toLowerCase().includes(lowerCaseSearchTerm)
          );
        })
      );
    } else {
      setFiltered(dukkaAccountRequest);
    }
  }, [dukkaAccountRequest, search]);

  return (
    <>
      <Helmet>
        <title> Dukka Account | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Book Keeping
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={12} sm={12}>
            <TextField
              value={search}
              fullWidth
              placeholder="Search book keeping name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          {loadingData && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {filtered?.map((post, index) => (
            <DukkaAccountRequestCard key={post._id} post={post} index={index} />
          ))}
        </Grid>
      </Container>
    </>
  );
}
