import {gql} from "@apollo/client";

export const UPDATE_TIN_STATUS = gql`
  mutation updateTaxProMaxApprovalStatus($input: TaxProMaxStatusInput!) {
    updateTaxProMaxApprovalStatus(input: $input){
        status
      _id
      adminMessage
    }
  }
`;