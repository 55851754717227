import {gql} from "@apollo/client";

export const GET_ALL_ANNUAL_RETURN_REQUEST_PAGINATED = gql`
  query PaginatedReturnsTest($page: Float!, $pageSize: Float!, $searchTerm: String) {
    allTrademark(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      trademarks {
        _id
        firstName
        lastName
        status
        adminMessage
          user{
            firstName
            lastName
            email
            phoneNumber
            dob
            
          }
        phoneNumber
        email
        created_at
      }
      pageInfo {
        currentPage
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;
