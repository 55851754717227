import { useMutation } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { fDate } from 'src/utils/formatTime';
import { NAME_SERVATIONS_STATUS } from '../../../utils/Constants';

const ExpandableCard = ({
  data,
  handleSendEmail,
  handleToggle,
  handleApprove,
  selected,
  loading,
  checkCacStatus,
  resubmitQueriedApplication,
}) => {
  const [expanded, setExpanded] = useState(false);
  const paymentDetails = data.paymentDetails;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  const status = useMemo(() => {
    if (paymentDetails) {
      if (paymentDetails.status === 'success') {
        return NAME_SERVATIONS_STATUS.COMPLETED;
      } else {
        return NAME_SERVATIONS_STATUS.PENDING;
      }
    } else {
      return NAME_SERVATIONS_STATUS.CANCELLED;
    }
  });

  const Text = ({ text }) => {
    return (
      <LoadingButton variant="outlined" sx={{ ml: 1 }}>
        {`${text}`}
      </LoadingButton>
    );
  };

  const handleOpenLinkInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const cacReason = useMemo(() => {
    if (expanded) {
      try {
        return JSON.parse(data.cacQueryReason);
      } catch (e) {
        console.warn(e);
        return [];
      }
    } else {
      return [];
    }
  }, [data, expanded]);

  return (
    <Card sx={{ margin: '10px' }}>
      <Grid container>
        <Grid item xs={12}>
          <CardHeader
            avatar={<Avatar src={data.passport} alt={data.proprietorFirstname} sx={{ width: 56, height: 56 }} />}
            title={data.proposedOption1}
            subheader={`${new Date(data.created_at).toLocaleDateString()} ${new Date(
              data.created_at
            ).toLocaleTimeString()}`}
            action={
              <Grid container>
                <Grid item xs={12}>
                  <IconButton aria-label="settings">
                    <img
                      src={
                        status === NAME_SERVATIONS_STATUS.PENDING
                          ? '/assets/icons/statuses/Pending.svg'
                          : status === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION
                          ? '/assets/icons/statuses/Pending.svg'
                          : status === NAME_SERVATIONS_STATUS.COMPLETED
                          ? '/assets/icons/statuses/Approved.svg'
                          : '/assets/icons/statuses/Denied.svg'
                      }
                      width={20}
                      alt="status"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            }
            sx={{ padding: '12px 20px ' }}
          />
          <CardActions
            disableSpacing
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid>
              <Text text={`Order Number: ${data._id}`} />
              {data?.queryReason && <Text text={`Queried Internal`} />}
              {data.transactionRef && <Text text={`Submitted`} />}
              {data.status === 'queried' && <Text text={`Queried CAC`} />}
              {data.status === 'approved' && <Text text={`Approved`} />}
            </Grid>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{ mr: 1 }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ExpandMore>
          </CardActions>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            {loading && selected === data._id ? (
              <CircularProgress />
            ) : data.transactionRef && (
              <Grid item>
                <Typography paragraph>
                  <strong>Application sent successfully</strong>
                </Typography>
                <Typography paragraph>
                  Vas ref: <strong>{data.transactionRef}</strong>
                </Typography>
                <Typography paragraph>
                  Application status: <strong>{data.status || 'pending'}</strong>
                </Typography>
                {data.status === 'approved' ? (
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant="contained" size="small" onClick={() => handleOpenLinkInNewTab(data?.statusReport)}>
                      Donwload Status Report
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleOpenLinkInNewTab(data?.certificate);
                      }}
                    >
                      Download Certificate
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    onClick={() => {
                      checkCacStatus(data._id);
                    }}
                  >
                    Check CAC Status
                  </Button>
                )}

                {data.status === 'approved' && (
                  <Grid item>
                    <Typography paragraph>
                      Rc Number: <strong>{data.rcNumber}</strong>
                    </Typography>
                    <Typography paragraph>
                      TIN: <strong>{data.tin}</strong>
                    </Typography>
                    <Typography paragraph>
                      Reg Date:{' '}
                      <strong>{`${new Date(data.registrationDate).toLocaleDateString()} ${new Date(
                        data.registrationDate
                      ).toLocaleTimeString()}`}</strong>
                    </Typography>
                  </Grid>
                )}
                {cacReason?.length && (
                  <Grid item>
                    <Typography paragraph>Comment from CAC:</Typography>
                    {cacReason.map((item) => (
                      <>
                        <Typography paragraph>Reason: {item.reason}</Typography>
                        <Typography paragraph>Comment: {item.comment}</Typography>
                        <br />
                      </>
                    ))}
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Payment Details:</strong>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Payment Status:</strong>{' '}
                {data.paymentDetails?.status === 'success' || data.paymentDetails?.status === 'successful'
                  ? 'Paid'
                  : 'Not Paid'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Order Number:</strong> {data._id}
              </Typography>
            </Grid>
            {data.paymentDetails?.amount && (
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Amount:</strong> {data.paymentDetails?.amount}
                </Typography>
              </Grid>
            )}
            <Divider />
            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Company Details:</strong>
              </Typography>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Gender:</strong> {data.proprietorGender}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Nationality:</strong> {data.proprietorNationality}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Address:</strong> {data.proprietorStreetNumber}, {data.proprietorCity}, {data.proprietorState}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>LGA:</strong> {data.proprietorLga}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Postcode:</strong> {data.proprietorPostcode}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Phone Number:</strong> {data.proprietorPhonenumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Postcode:</strong> {data.proprietorPostcode}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Address:</strong> {data.companyStreetNumber}, {data.companyCity}, {data.companyState}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Email:</strong> {data.companyEmail}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Line of Business:</strong> {data.lineOfBusiness}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Proposed Name:</strong> {data.proposedOption1}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Proprietor Details:</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Name:</strong> {data.proprietorFirstname} {data.proprietorSurname}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Email:</strong> {data.proprietorEmail}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Phone:</strong> {data.proprietorPhonenumber}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Gender:</strong> {data.proprietorGender}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>DOB:</strong> {fDate(data.proprietorDob)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Nationality:</strong> {data.proprietorNationality}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Address:</strong> {data.proprietorStreetNumber}, {data.proprietorCity}, {data.proprietorState}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>LGA:</strong> {data.proprietorLga}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                <strong>Postcode:</strong> {data.proprietorPostcode}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography paragraph>
                <strong>Images:</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={data.passport} alt="Passport" style={{ width: '100%' }} />
              <Typography variant="caption">Passport</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={data.meansOfId} alt="Means of ID" style={{ width: '100%' }} />
              <Typography variant="caption">Means of ID</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={data.signature} alt="Signature" style={{ width: '100%' }} />
              <Typography variant="caption">Signature</Typography>
            </Grid>
            {data?.queryReason && (
              <Grid item xs={12}>
                <Typography paragraph>
                  <strong>Query Reason:</strong>
                </Typography>
                <Typography paragraph>{data.queryReason}</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const CacBusinessNameClientCard = ({
  items,
  handleSendEmail,
  handleToggle,
  handleApprove,
  selected,
  loading,
  checkCacStatus,
  resubmitQueriedApplication,
}) => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12} key={item._id}>
          <ExpandableCard
            data={item}
            handleSendEmail={handleSendEmail}
            handleToggle={handleToggle}
            handleApprove={handleApprove}
            selected={selected}
            loading={loading}
            checkCacStatus={checkCacStatus}
            resubmitQueriedApplication={resubmitQueriedApplication}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CacBusinessNameClientCard;
