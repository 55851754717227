import { gql } from '@apollo/client';

export const GET_ALL_DUKKA_ACCOUNT = gql`
  query {
    allDukkaAccount {
      _id
      email
      userName
      created_at
      businessName
      firstName
      lastName
      phoneNumber
      gender
      lga
      state

      user {
        _id
        firstName
        lastName
        email
        middleName
        gender
        address
        city
        postCode
        dob
        created_at
        verified
        thumbnail
        role
      }
    }
  }
`;
