import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
// @mui
import { Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD } from 'src/graphql/mutations/auth';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

const StyledFormWrapper = styled('form')(({ theme }) => ({
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
  padding: '0 1rem 1rem 1rem',
}));

const StyledFormHeader = styled('div')(({ theme }) => ({
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  borderBottom: '1px solid #CFCFCF',
  marginLeft: '-1rem',
  marginRight: '-1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const StyledHeaderIconWrapper = styled('div')`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50px;
  background-color: #ffd3d3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ForgetPasswordForm() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: () => {
      handleForgotPasswrd();
    },
  });

  const [handleForgotPasswrd, { loading }] = useMutation(FORGOT_PASSWORD, {
    variables: { input: { ...formik.values } },
    onCompleted: (data) => {
      if (data?.forgotPassword?.token) {
        navigate(`/reset-password`, { replace: true });
      } else {
        console.log('something went wrong');
      }
      console.log('handleForgotPasswrd', data);
    },
    onError: (error) => {
      console.log('onError', error);
    },
  });

  return (
    <>
      <StyledFormWrapper onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <StyledFormHeader>
            <StyledHeaderIconWrapper>
              <img src="/assets/icons/LockIcon.svg" alt="login" />
            </StyledHeaderIconWrapper>
            <Typography
              variant="h5"
              style={{
                padding: '0.5rem',
              }}
            >
              Forgot Password
            </Typography>
          </StyledFormHeader>

          <Typography variant="body2" sx={{ mb: 5 }} style={{ opacity: '0.5' }}>
            Please enter the email address you used to create your account, and we'll send you a link to reset your
            password.
          </Typography>

          <TextField
            name="email"
            label="Email"
            id="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={Boolean(formik.touched.email && formik.errors.email)}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            loadingIndicator="Loading…"
          >
            Submit
          </LoadingButton>
        </Stack>
      </StyledFormWrapper>
    </>
  );
}
