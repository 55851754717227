import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
// sections
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../../components/logo';
import { GET_ONE_CAC_CLIENT_REGISTRATIONS, GET_ONE_CAC_CLIENT_USERS } from '../../graphql/queries/cacClient';
import {
  AppWidgetSummary
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 0),
}));

const LoaderContainer = styled('div')`
  min-height: 70vh;
  display: flex;
  /* max: 500px; */
  align-items: center;
  justify-content: center;
`;

export default function PartnerDashboard() {
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const { clientRef } = useParams();
  const [searchTerm, setSearchTerm] = useState('');

const [getOneClientUsers, { data: data2, loading: loadingData2, error: error2}] = useLazyQuery(GET_ONE_CAC_CLIENT_USERS);
const [getOneClientRegistrations, { data, loading: loadingData, error }] = useLazyQuery(GET_ONE_CAC_CLIENT_REGISTRATIONS);

const refresh = () => {
  window.location = "/partner/"+ clientRef;
}

useEffect(() => {
  if (clientRef) {
    getOneClientUsers({ variables: { id: clientRef, page, pageSize, searchTerm } });
    getOneClientRegistrations({ variables: { id: clientRef, page, pageSize, searchTerm } });
  }
}, [clientRef, page, pageSize, searchTerm, getOneClientUsers, getOneClientRegistrations]);

  const userData = data2?.getAllUsersByClientRef;
  const regData = data?.getAllUserRegById;


  // const unpaid = useMemo(()=> regData?.pageInfo?.totalItems || 0  - regData?.paidRegistration || 0 , [regData])

  return (
    <>
      <Helmet>
        <title> Partner Dashboard | BeeBot </title>
      </Helmet>

      <Box sx={{ px: 3, py: 2, display: 'inline-flex' }}>
        <Logo disabledLink />
      </Box>
      <Container maxWidth="md">
        {(loadingData2 || loadingData) && <>
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
        </>}

        {(error2 || error || !clientRef) &&
        <>
          <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="h3" paragraph>
              Invalid link used!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, it seems the provided reference is invalid or missing. Please check the link and try again/ refresh.
            </Typography>

            <Box
              component="img"
              src="/assets/illustrations/illustration_404.svg"
              sx={{ height: 200, mx: 'auto', my: { xs: 2, sm: 4 } }}
            />

            <Box
              sx={{ mx: 'auto', my: { xs: 2, sm: 3 } }}
            >
              <Button variant='contained' onClick={refresh}>
                Refresh
              </Button>
            </Box>

          </StyledContent>
        </>}
        
        {(data && data2) && <>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, {userData?.client.companyName} 
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Affiliated Users" total={userData?.pageInfo.totalItems || 0}/>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Submitted Registrations" total={regData?.pageInfo.totalItems} color="info"/>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Paid Business Registrations" total={userData?.businesses} color="warning"/>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Paid Company Registrations" total={userData?.companies} color="error"/>
            </Grid>
          </Grid>
        </>}

      </Container>
    </>
  );
}
