import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    CHECK_CAC_REGISTRATION_STATUS,
    GET_ALL_CAC_CLIENT_REG_PAGINATED,
    GET_ALL_CAC_REG_PAGINATED,
    SEND_EMAIL,
    TOGGLE_EDITABLE
} from "../../graphql/queries/cacClient";
import CacBusinessNameCard from "../../sections/@dashboard/cac-business-reg/CacBusinessNameCard";
import CacBusinessNameClientCard from 'src/sections/@dashboard/cac-business-reg/CacBusinessNameClientCard';

const ORDER_BY = {
    Oldest: {name: "Oldest", value: 1 },
    Newest: {name: "Newest", value: -1 },
}

export default function AdminCacBusinessReg() {
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [annualReturnRequests, setAnnualReturnRequests] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [selected, setSelected] = useState();
    const [orderBy, setOrderBy] = useState(ORDER_BY.Oldest)
    const [filterStatus, setFilterStats] = useState("All")
    const { data: data2, loading: loadingData2, fetchMore } = useQuery(GET_ALL_CAC_CLIENT_REG_PAGINATED, {
        variables: { page, pageSize, order: orderBy.value, filter: filterStatus },
    });

    const [checkCacRegistrationStatus,{loading: loading2}] = useLazyQuery(CHECK_CAC_REGISTRATION_STATUS);

    const [sendEmailQuery] = useLazyQuery(SEND_EMAIL);
    const [toggleEditableMutation , ] = useMutation(TOGGLE_EDITABLE);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (data2 && data2?.getAllBusinessRegCacByUserClient) {
            setAnnualReturnRequests(data2?.getAllBusinessRegCacByUserClient?.allClient);
        }
    }, [data2]);

    useEffect(() => {
        setFiltered(annualReturnRequests);
    }, [annualReturnRequests]);

    const handleSendEmail = (query) => {
        sendEmailQuery({ variables: { query } });
    };

    const handleChange = (event) => {
        setOrderBy(ORDER_BY[event.target.value])
    };
    
    const handleStatusChange = (event) => {
        setFilterStats(event.target.value)
    };
    
    const handleToggle = async (id) => {
        try {
            const { data } = await toggleEditableMutation({ variables: { id } });
            const updatedList = annualReturnRequests.map((item) =>
                item._id === id ? { ...item, editable: data.editable } : item
            );
            setAnnualReturnRequests(updatedList);
            enqueueSnackbar('Editable state toggled successfully!', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Failed to toggle editable state', { variant: 'error' });
        }
    };

    const handlePageChange = async (newPage) => {
        setPage(newPage);
        await fetchMore({
            variables: { page: newPage, pageSize, searchTerm, order: orderBy.value, filter: filterStatus },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
            },
        });
    };

    const handleApprove = async (id )=>{
        setSelected(id)
    }

    const resubmitQueriedApplication = async (id )=>{
        setSelected(id)
    }

    const checkCacStatusFunction = async (id )=>{
        setSelected(id)
        const data =  await checkCacRegistrationStatus({variables:{id: id }});
        if (data?.data?.checkCacRegistrationStatus) {
            enqueueSnackbar("Status checked successfully", { variant: "success"});
            fetchMore({
                variables: { page, pageSize, order: orderBy.value, searchTerm, filter: filterStatus },
            });
        } else {
            enqueueSnackbar("Error checking application status", { variant: "error"});
        }
    }

    const handleSearchDebounced = useCallback(debounce(async (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setPage(1);
        setSearchLoading(true);
        await fetchMore({
            variables: { page: 1, pageSize, searchTerm: newSearchTerm, filter: filterStatus },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
            },
        });
        setSearchLoading(false);
    }, 200), [fetchMore]);

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        setSearchLoading(false);
        handleSearchDebounced(newSearchTerm);
    };

    return (
        <>
            <Helmet>
                <title>Business Name Registration CAC | BeeBot</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Cac Business Name Registration {
                            !data2?.getAllBusinessRegCacByUserClient?.pageInfo ? "": 
                            `(${data2?.getAllBusinessRegCacByUserClient?.pageInfo?.totalItems || 0} items)`
                        }
                    </Typography>
                    {(loadingData2 || loading2) &&
                        <CircularProgress />
                    }
                </Stack>

                <Grid container spacing={3}>
                    <Grid item md={6} sm={6}>
                        <TextField
                            value={searchTerm}
                            fullWidth
                            placeholder="Search Reservation by name..."
                            onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchLoading && <CircularProgress size={20} />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel id="statusLabel">Order By</InputLabel>
                            <Select
                                labelId="statusLabel"
                                id="status"
                                value={orderBy.name}
                                label="menuItem"
                                onChange={(event) => handleChange(event)}
                            >
                                <MenuItem
                                    selected={orderBy === ORDER_BY.Oldest}
                                    value={ORDER_BY.Oldest.name}
                                >
                                    Oldest{' '}
                                </MenuItem>
                                <MenuItem
                                    selected={orderBy === ORDER_BY.Newest}
                                    value={ORDER_BY.Newest.name}
                                >
                                    Newest{' '}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel id="statusLabel">Filter Status</InputLabel>
                            <Select
                                labelId="statusLabel"
                                id="status"
                                value={filterStatus}
                                label="menuItem"
                                onChange={(event) => handleStatusChange(event)}
                            >
                                {
                                    ["All", "Paid", "Not-Paid", "Submitted", "Queried", "Approved", "Internal-Query", "Not-Sent"].map(one =>
                                        <MenuItem
                                            selected={filterStatus === one}
                                            value={one}
                                            key={one}
                                        >
                                            {one}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <CacBusinessNameClientCard
                        items={filtered}
                        selected={selected}
                        loading={loading2}
                        handleSendEmail={handleSendEmail}
                        handleToggle={handleToggle}
                        handleApprove={handleApprove}
                        checkCacStatus={checkCacStatusFunction}
                        resubmitQueriedApplication={resubmitQueriedApplication}
                    />

                    <Grid item md={12} sm={12} container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={() => handlePageChange(page - 1)}
                                disabled={page <= 1 || loadingData2}
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">Page {page}</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={() => handlePageChange(page + 1)}
                                disabled={page >= data2?.getAllBusinessRegCacByUserClient.pageInfo?.totalPages || loadingData2}
                            >
                                Next
                            </Button>
                        </Grid>
                        {loadingData2 && <CircularProgress size={24} />}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}