import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { FormControlLabel, Typography } from '@mui/material';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: `${theme.palette.primary.main}`,
    height: '40px',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
    },
  },
}));
export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
}));

export const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
}));
export const StyledPaymentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '50vh',
  gap: '10px',
  padding: '25px',
}));
export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: '0px',
}));

export const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

export const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));
export const StyledFieldLabel = styled(Typography)(({ theme }) => ({
  color: 'gray',
}));

export const StyledHeader = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #222222;
`;
