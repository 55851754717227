import { Grid, TextField, Hidden, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { StyledTextInput } from 'src/components/input/Input';
import { setNameReservationData } from 'src/redux/namereservation.slice';
import { NATURE_OF_BUSINESS_CATEGORY, REASON_FOR_AVAILABILITY_SEARCH } from 'src/utils/Constants';
import { Stack } from '@mui/system';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  topInput: {
    [`& fieldset`]: {
      borderRadius: '5px 5px 0 0',
    },
  },
  middleInput: {
    [`& fieldset`]: {
      borderRadius: '0',
    },
  },
  bottomInput: {
    [`& fieldset`]: {
      borderRadius: '0 0 5px 5px',
    },
  },
}));

export default function Step2({ setStep, step }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [commentRequired, setCommentRequired] = useState(false);
  const { data } = useSelector((state) => state.namereservation);

  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setNameReservationData(data));
    if (
      (data?.availabilitySearch === 'CHANGE OF NAME' || data?.availabilitySearch === 'NAME SUBSTITUTION') &&
      (data?.additionalComment === '' || !data?.additionalComment)
    ) {
      setCommentRequired(true);
    } else {
      setCommentRequired(false);
      handleNextStep();
    }
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Autocomplete
          freeSolo
          id="reason_for_availability_search"
          defaultValue={data?.availabilitySearch}
          disableClearable
          options={REASON_FOR_AVAILABILITY_SEARCH.map((option) => option)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              required
              name="availabilitySearch"
              label="Reasons for availability search*"
              fullWidth
              {...register('availabilitySearch', { required: true })}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Autocomplete
          freeSolo
          id="reason_of_business_category"
          disableClearable
          defaultValue={data?.businessNature}
          options={NATURE_OF_BUSINESS_CATEGORY.map((option) => option)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              required
              name="businessNature"
              label="Nature of business"
              fullWidth
              {...register('businessNature', { required: true })}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledTextInput
          multiline
          rows={4}
          required={commentRequired ? true : false}
          className={classes.topInput}
          defaultValue={data?.additionalComment}
          placeholder="Additional comment"
          fullWidth
          {...register('additionalComment', { required: commentRequired ? true : false })}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction="row" spacing={2}>
            <LoadingButton variant="outlined" onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" type="submit">
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <Stack spacing={2}>
            <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" fullWidth type="submit">
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
    </Grid>
  );
}
