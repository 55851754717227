import { Checkbox, Grid, Hidden, Stack, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, styled } from '@mui/styles';
import { StyledTextInput } from 'src/components/input/Input';
import { setNameReservationData } from 'src/redux/namereservation.slice';
import { useMutation } from '@apollo/client';
import { RESERVE_NAME } from 'src/graphql/mutations/nameReservation';
import { useEffect, useState } from 'react';
import { clearNameReservationData } from 'src/redux/namereservation.slice';
import { StyledFormControlLabel } from './styled';
import { DISCLAIMER } from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  topInput: {
    [`& fieldset`]: {
      borderRadius: '5px 5px 0 0',
    },
    paddingTop: '2px',
  },
  middleInput: {
    [`& fieldset`]: {
      borderRadius: '0',
    },
    paddingTop: '2px',
  },
  bottomInput: {
    [`& fieldset`]: {
      borderRadius: '0 0 5px 5px',
    },
    paddingTop: '2px',
  },
  inputWrapper: {
    position: 'relative',
  },
  inputLabel: {
    position: 'absolute',
    left: '15px',
    opacity: '0.5',
  },
}));

const StyledHeaderIconWrapper = styled('div')`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50px;
  background-color: #ffd3d3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFormHeader = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #CFCFCF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
}));

export default function Step3({ setStep, step }) {
  const { service } = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [CreateNameReservation, { loading, data: nameReservationData }] = useMutation(RESERVE_NAME);
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const sendDataForProcessing = () => {
    CreateNameReservation({
      variables: {
        input: {
          serviceAmount: service?.cost,
          proposedNames: {
            optionOne: data.option1,
            optionTwo: data.option2,
          },
          classification: data.classification,
          additionalComment: data.additionalComment,
          reasonsForAvailabilitySearch: [data.availabilitySearch],
          NatureOfBusiness: data.businessNature,
          specificType: data.type,
        },
      },
    });
  };

  useEffect(() => {
    if (nameReservationData?.CreateNameReservation?.data?.link) {
      dispatch(clearNameReservationData());
      window.open(nameReservationData?.CreateNameReservation?.data?.link, '_self');
    }
  }, [nameReservationData, dispatch]);

  const { data } = useSelector((state) => state.namereservation);

  const [checked, setChecked] = useState(false);

  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(setNameReservationData(data));
    sendDataForProcessing();
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <StyledFormHeader>
          <StyledHeaderIconWrapper>
            <img src="/assets/icons/SignupMarkIcon.svg" alt="login" />
          </StyledHeaderIconWrapper>
          <Typography
            variant="h5"
            style={{
              padding: '0.5rem',
            }}
          >
            Business name details
          </Typography>
        </StyledFormHeader>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.inputWrapper}>
          <span className={classes.inputLabel}>Propose name (option 1)</span>
          <StyledTextInput
            className={classes.topInput}
            value={data.option1}
            fullWidth
            // {...register('proposedName', { required: true })}
          />
        </div>
        <div className={classes.inputWrapper}>
          <span className={classes.inputLabel}>Propose name (option 2)</span>
          <StyledTextInput
            className={classes.middleInput}
            value={data.option2}
            fullWidth
            // {...register('proposedName', { required: true })}
          />
        </div>
        <div className={classes.inputWrapper}>
          <span className={classes.inputLabel}>Classification</span>
          <StyledTextInput
            className={classes.bottomInput}
            value={data.classification}
            fullWidth
            // {...register('businessName', { required: true })}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledFormHeader>
          <StyledHeaderIconWrapper>
            <img src="/assets/icons/SignupMarkIcon.svg" alt="login" />
          </StyledHeaderIconWrapper>
          <Typography
            variant="h5"
            style={{
              padding: '0.5rem',
            }}
          >
            Other Information
          </Typography>
        </StyledFormHeader>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.inputWrapper}>
          <span className={classes.inputLabel}>Reasons for availability search</span>
          <StyledTextInput
            className={classes.topInput}
            value={data.availabilitySearch}
            fullWidth
            // {...register('nameChange', { required: true })}
          />
        </div>
        <div className={classes.inputWrapper}>
          <span className={classes.inputLabel}>Nature of Business</span>
          <StyledTextInput
            className={classes.middleInput}
            value={data.businessNature}
            fullWidth
            // {...register('businessNature', { required: true })}
          />
        </div>
        <div className={classes.inputWrapper}>
          <span className={classes.inputLabel}>Additional Comments</span>
          <StyledTextInput
            className={classes.bottomInput}
            value={data.additionalComment}
            fullWidth
            // {...register('businessNameAdditionalComment', { required: true })}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledFormControlLabel
          label={DISCLAIMER}
          control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} required={true} />}
        />
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction="row" spacing={2}>
            <LoadingButton variant="outlined" onClick={() => setStep(step - 1)} loading={loading}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" type="submit" loading={loading}>
              {`Save & Pay NGN ${numberWithCommas(service?.cost)}`}
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <Stack spacing={2}>
            <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)} loading={loading}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" fullWidth type="submit" loading={loading}>
              {`Save & Pay NGN ${numberWithCommas(service?.cost)}`}
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
    </Grid>
  );
}
