import React, { useEffect } from 'react';
import {
    Alert,
    CircularProgress,
    Grid,
    Stack
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setUser } from 'src/redux/auth.slice';
import { COMPLETE_VERIFICATION } from "src/graphql/mutations/auth";

// ----------------------------------------------------------------------

export default function AccountVerificationPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const [completeVerification, { data, loading, error }] = useMutation(COMPLETE_VERIFICATION);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
  
    useEffect(() => {
        if (token) {
            completeVerification({
                variables: {
                    token
                }
            });
        }
    }, [token, completeVerification]);

    useEffect(() => {
        if (data?.completeVerification) {
            enqueueSnackbar("Verification Completed!", { variant: "success" });
            dispatch(setUser(data?.completeVerification));
        }
    }, [data, enqueueSnackbar, dispatch]);

    return (
        <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "80vh" }}>
            <Stack>
                {error && <Alert severity="error">{error?.message}</Alert>}
                {data?.completeVerification && <Alert severity="success">Account verification successful!</Alert>}
                {loading && <CircularProgress />}
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    loading={loading}
                    onClick={() => navigate('/login')}
                >
                    Sign In
                </LoadingButton>
            </Stack>
        </Grid>
    );
}