import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { useMutation, useQuery } from '@apollo/client';

import { useEffect, useState } from 'react';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { useSnackbar } from 'notistack';
import { GET_ALL_UPDATE_DIRECTORS } from 'src/graphql/queries/companyName';
import { UPDATE_DIRECTORS_APPROVAL_STATUS } from 'src/graphql/mutations/companyRegistration';
import UpdateShareHolderDetailsRequestCard from 'src/sections/@dashboard/update shareholder details/UpdateShareholderDetails';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminUpdateDirectorsDetails() {
  const { data, loading: loadingData } = useQuery(GET_ALL_UPDATE_DIRECTORS);

  const [certifiedTrueCopyRequests, setCertifiedTrueCopyRequests] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateBusinessRegistrationApprovalStatus, { data: updatedReservation, loading }] = useMutation(
    UPDATE_DIRECTORS_APPROVAL_STATUS
  );
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setCertifiedTrueCopyRequests(data?.getUpdateDirectorDetails);
  }, [data?.getUpdateDirectorDetails]);

  useEffect(() => {
    if (updatedReservation?.updateBusinessRegistrationApprovalStatus && updateStatus) {
      setUpdateStatus(false);
      //Initailize array of objects.
      let tempReservations = [...certifiedTrueCopyRequests];

      //Find index of specific object using findIndex method.
      const objIndex = tempReservations.findIndex(
        (obj) => obj._id === updatedReservation?.updateBusinessRegistrationApprovalStatus?._id
      );

      //Update object's name property.
      tempReservations[objIndex].status = updatedReservation?.updateBusinessRegistrationApprovalStatus?.status;

      //Log object to console again.
      setCertifiedTrueCopyRequests(tempReservations);
    }
  }, [updatedReservation?.updateBusinessRegistrationApprovalStatus, updateStatus, certifiedTrueCopyRequests]);

  useEffect(() => {
    const lowerCaseSearchTerm = search.toLowerCase();
    if (search.length) {
      setFiltered(() =>
        certifiedTrueCopyRequests?.filter((request) => {
          return (
            request?.organisationName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.rcNumber?.toString().toLowerCase().includes(lowerCaseSearchTerm)
          );
        })
      );
    } else {
      setFiltered(certifiedTrueCopyRequests);
    }
  }, [certifiedTrueCopyRequests, search]);

  const changeStatus = (_id, status, adminMessage) => {
    setUpdateStatus(true);
    enqueueSnackbar('Status updated!');
    updateBusinessRegistrationApprovalStatus({
      variables: { input: { _id, status, adminMessage } },
    });
  };

  return (
    <>
      <Helmet>
        <title> Update of Director Details | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Update of Director's Details
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={12} sm={12}>
            {' '}
            <TextField
              value={search}
              fullWidth
              placeholder="Search Reservation by Company name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          {loadingData && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {filtered?.map((post, index) => (
            <UpdateShareHolderDetailsRequestCard
              key={post._id}
              post={post}
              index={index}
              loading={loading}
              changeStatus={changeStatus}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
}
