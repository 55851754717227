import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F4F6FA',
    borderRadius: '5px',
    border: 'none',
    padding: '0.3rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
}));

export const RegistrationCard = ({ data, setDirectorsInfo, setDirectorsList, handleNotify }) => {
  const classes = useStyles();
  const handleDelete = (ind) => {
    const updatedList = data.filter((value, index) => {
      return ind !== index;
    });
    setDirectorsList(updatedList);
    setDirectorsInfo({});
    handleNotify('Director deleted!');
  };

  return (
    <Grid item container xs={12} sm={12} md={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        {data.map((obj, index) => (
          <div className={classes.container} key={index}>
            <p>{obj?.surname} {obj?.othername} {obj?.lastname}</p>
            <div className={classes.iconContainer}>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => setDirectorsInfo({ ...obj, index, id: index })}
              >
                <img className={classes.icon} src="/assets/icons/DraftIcon.svg" alt="Nature of Business" />
              </IconButton>
              <IconButton aria-label="delete" size="small" onClick={() => handleDelete(index)}>
                <img className={classes.icon} src="/assets/icons/DeleteIcon.svg" alt="Nature of Business" />
              </IconButton>
            </div>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};
