import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import Pdf from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
// @mui
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fDate } from '../../../utils/formatTime';
//
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { NAME_SERVATIONS_STATUS } from 'src/utils/Constants';
import { onDownload } from 'src/utils/HelperFunctions';

// ----------------------------------------------------------------------

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

export const ComponentToPrint = React.forwardRef(({ post, changeStatus, loading }, ref) => {
  const {
    _id,
    businessName,
    businessEmail,
    businessPhone,
    businessAddress,
    natureOfBusinessDescription,
    paymentDetails,
    partners,
    status,
    customerReview,
    starRating,
    adminMessage,
    user,
  } = post;

  const [statusReservation, setStatusReservation] = React.useState(status);
  const [customerActionMessage, setCustomerActionMessage] = React.useState('');

  const FieldCheck = ({ partnerField, title, args }) => {
    return (
      partnerField && (
        <>
          <StyledElementContainer>
            <Typography variant="caption">{title}</Typography>
            {args && <Typography variant="subtitle2">{`${args}`}</Typography>}
            {!args && <Typography variant="subtitle2">{`${partnerField}`}</Typography>}
          </StyledElementContainer>
          <Divider />
        </>
      )
    );
  };

  const getFieldName = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg', '.webp'];
    const fileExtension = url?.toLowerCase().split('.').pop() && '';
    return imageExtensions.includes(`.${fileExtension}`);
  };

  const handleChange = (event) => {
    setStatusReservation(event.target.value);
  };

  const handleSubmit = (_id, statusReservation, customerActionMessage) => {
    if (statusReservation !== NAME_SERVATIONS_STATUS.CUSTOMER_ACTION) setCustomerActionMessage(null);
    changeStatus(_id, statusReservation, customerActionMessage);
    setCustomerActionMessage(null);
  };
  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid item md={12} sm={12} xs={12}>
        <StyledHeaderContainer>
          <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
          <Typography variant="h5">Star Rating and Reviews</Typography>
        </StyledHeaderContainer>
        <Divider />
        <StyledContent>
          <StyledElementContainer>
            <Typography variant="caption">Star Rating</Typography>
            <Typography variant="subtitle2">{starRating}</Typography>
          </StyledElementContainer>
          {customerReview?.length > 0 && (
            <StyledElementContainer>
              <Typography variant="caption">Customer Review</Typography>
              {customerReview?.map((review) => {
                return <Typography variant="subtitle2">{`${review}`}</Typography>;
              })}
            </StyledElementContainer>
          )}
          {adminMessage?.length > 0 && (
            <StyledElementContainer>
              <Typography variant="caption">Admin Review</Typography>
              {adminMessage?.map((review) => {
                return <Typography variant="subtitle2">{`${review}`}</Typography>;
              })}
            </StyledElementContainer>
          )}
        </StyledContent>
      </Grid>
      <Grid container spacing={2}>
        {partners?.map((partner, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Partner {index + 1} Information</Typography>
              </StyledHeaderContainer>
              <Divider />

              <StyledContent>
                <FieldCheck title={`Partner's Name`} partnerField={partner.proprietorName} />

                <FieldCheck partnerField={partner.proprietorTelephone} title={'Partner Telephone Number'} />

                <FieldCheck partnerField={partner.proprietorPhone} title={'Partner Phone'} />
                <FieldCheck partnerField={partner.gender} title={'Gender'} />

                <FieldCheck title={`Partner's DOB`} partnerField={partner.proprietorDob} />

                <FieldCheck partnerField={partner.proprietorEmail} title={"Partner's Email"} />
                <FieldCheck partnerField={partner.proprietorAddress} title={"Partner's Address"} />

                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">{`Partner's ID (${partner.proprietorMeansOfIdentification})`}</Typography>
                    <IconButton color="primary" component="label" onClick={() => onDownload(partner.proprietorID)}>
                      <Download />
                    </IconButton>
                  </Box>

                  {getFieldName(partner.proprietorMeansOfIdentification) ? (
                    <img
                      src={partner.proprietorMeansOfIdentification}
                      alt={partner.proprietorMeansOfIdentification}
                      width={200}
                      onClick={() => onDownload(partner.proprietorMeansOfIdentification)}
                    />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(partner.proprietorMeansOfIdentification)}
                    />
                  )}
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Partner's Passport Photo</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(partner.proprietorPassportPhoto)}
                    >
                      <Download />
                    </IconButton>
                  </Box>

                  {getFieldName(partner.proprietorPassportPhoto) ? (
                    <img src={partner.proprietorPassportPhoto} alt={partner.proprietorPassportPhoto} width={200} />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(partner.proprietorPassportPhoto)}
                    />
                  )}
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Partner's Signature</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      onClick={() => onDownload(partner.proprietorSignature)}
                    >
                      <Download />
                    </IconButton>
                  </Box>
                  {getFieldName(partner.proprietorSignature) ? (
                    <img src={partner.proprietorSignature} alt={partner.proprietorSignature} width={200} />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(partner.proprietorSignature)}
                    />
                  )}
                </StyledElementContainer>
              </StyledContent>
            </Grid>
          );
        })}

        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5"> Business name details</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <FieldCheck partnerField={_id} title={`Order Number`} />

            <StyledElementContainer>
              <Typography variant="caption">Business Name</Typography>
              <Typography variant="subtitle2">{businessName}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Business Email</Typography>
              <Typography variant="subtitle2">{businessEmail}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Business Phone</Typography>
              <Typography variant="subtitle2">{businessPhone}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Business Address</Typography>
              <Typography variant="subtitle2">{businessAddress}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Nature of Business</Typography>
              <Typography variant="subtitle2">{natureOfBusinessDescription}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Payment Status</Typography>
              <Typography variant="subtitle2">
                {paymentDetails ? `NGN ${paymentDetails?.amount} ${paymentDetails?.status}` : 'Not Paid'}
              </Typography>
            </StyledElementContainer>
          </StyledContent>
          {/* User Details */}
          <StyledContent>
            <StyledHeaderContainer>
              <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
              <Typography variant="h5">User details</Typography>
            </StyledHeaderContainer>
            <FieldCheck partnerField={user?.lastName} title={`Surname Name`} />
            <FieldCheck partnerField={user?.firstName} title={`First Email`} />
            <FieldCheck partnerField={user?.phoneNumber} title={`User Phone Number`} />
            <FieldCheck partnerField={user?.dob} title={`Date of Birth`} />
            <FieldCheck partnerField={user?.email} title={`User Email`} />
            <FieldCheck partnerField={user?.address} title={`User Address`} />
            <FieldCheck partnerField={user?.postCode} title={`Post Code`} />
          </StyledContent>
          <Stack spacing={2} sx={{ mt: 2 }} direction="row">
            <StyledElementContainer>
              <FormControl>
                <InputLabel id="statusLabel">Order Status</InputLabel>
                <Select
                  labelId="statusLabel"
                  id="status"
                  value={statusReservation}
                  label="menuItem"
                  onChange={(event) => handleChange(event)}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={NAME_SERVATIONS_STATUS.CUSTOMER_ACTION}>Customer Action Required</MenuItem>
                  <MenuItem value={NAME_SERVATIONS_STATUS.PROCESSING}>Processed</MenuItem>
                  <MenuItem value={NAME_SERVATIONS_STATUS.COMPLETED}>Completed</MenuItem>
                </Select>
              </FormControl>
            </StyledElementContainer>
          </Stack>
          <Stack>
            <StyledElementContainer>
              <TextField
                id="outlined-multiline-static"
                label="Extra Message"
                multiline
                rows={2}
                value={customerActionMessage}
                onChange={(event) => setCustomerActionMessage(event.target.value)}
              />
            </StyledElementContainer>

            <LoadingButton
              variant="contained"
              fullWidth={false}
              onClick={() => handleSubmit(_id, statusReservation, customerActionMessage)}
              loading={loading}
            >
              Update Status
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
});

BusinessRegistrationRequestCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BusinessRegistrationRequestCard({ post, index, changeStatus, loading }) {
  const { businessName, created_at, status, user } = post;

  const [expanded, setExpanded] = React.useState(false);
  const componentRef = React.useRef();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <img
                src={
                  status === NAME_SERVATIONS_STATUS.COMPLETED
                    ? '/assets/icons/statuses/Approved.svg'
                    : status === NAME_SERVATIONS_STATUS.PROCESSING
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION
                    ? '/assets/icons/statuses/Pending.svg'
                    : '/assets/icons/statuses/Denied.svg'
                }
                width={20}
                alt="status"
              />
            </IconButton>
          }
          title={`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
          subheader={`Submitted: ${fDate(created_at)}`}
        />
        <CardActions disableSpacing>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Business Name: ${businessName}`}
          </LoadingButton>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button onClick={handlePrint}>Print</Button>
                <Pdf targetRef={componentRef} filename="code-example.pdf">
                  {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                </Pdf>
              </Stack>

              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
