// routes
import Router from './routes/routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import { SnackbarProvider } from 'notistack';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloProvider client={client}>
            <SnackbarProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
            </SnackbarProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}
