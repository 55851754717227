import { Grid, TextField, Hidden, Divider, Stack } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { StyledHeader, StyledHeaderContainer } from './styled';
// ----------------------------------------------------------------------
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessRegistrationData } from 'src/redux/businessregistration.slice';

export default function Step3({ setStep, step }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.businessregistration);
  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setBusinessRegistrationData(data));
    handleNextStep();
  };
  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12} container>
        <StyledHeaderContainer>
          <img src="/assets/icons/NatureofBusiness.svg" alt="Nature of Business" />
          <StyledHeader>Nature of Business</StyledHeader>
        </StyledHeaderContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          multiline
          defaultValue={data?.natureOfBusinessDescription}
          name="natureOfBusinessDescription"
          label="Description"
          fullWidth
          rows={4}
          required
          {...register('natureOfBusinessDescription', { required: true })}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction="row" spacing={2}>
            <LoadingButton variant="outlined" onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" type="submit">
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} sm={12} md={12}>
          <Stack spacing={2}>
            <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
            <LoadingButton variant="contained" fullWidth type="submit">
              Save & Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Hidden>
    </Grid>
  );
}
