import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, TextField, Select, MenuItem, FormControl } from '@mui/material';
// components
import Iconify from '../../components/iconify';
// mock
import { useMutation, useQuery } from '@apollo/client';
import { GET_NAME_RESERVATION_SUBMISSIONS } from 'src/graphql/queries/nameReservation';
import { NameReservationRequestsCard } from 'src/sections/@dashboard/name-reservations-requests';
import { UPDATE_NAME_RESERVATION_STATUS } from 'src/graphql/mutations/nameReservation';
import { useEffect, useState } from 'react';
import LoadingCards from 'src/components/loading-cards/LoadingCards';
import { useSnackbar } from 'notistack';
import { NAME_SERVATIONS_STATUS } from 'src/utils/Constants';

// ----------------------------------------------------------------------

// const SORT_OPTIONS = [
//   { value: 'latest', label: 'Latest' },
//   { value: 'popular', label: 'Popular' },
//   { value: 'oldest', label: 'Oldest' },
// ];

// ----------------------------------------------------------------------

export default function AdminNameReservationsPage() {
  const { data, loading: loadingData } = useQuery(GET_NAME_RESERVATION_SUBMISSIONS);
  const [nameReservationRequests, setNameReservationRequests] = useState([]);
  const [nameReservationFiltered, setNameReservationFiltered] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredStatus, SetFilteredStatus] = useState(-1);
  const [updateNameReservationApprovalStatus, { data: updatedReservation, loading }] =
    useMutation(UPDATE_NAME_RESERVATION_STATUS);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.allNameReservations) {
      setNameReservationRequests(data?.allNameReservations);
    }
  }, [data?.allNameReservations]);

  useEffect(() => {
    if (nameReservationRequests?.length) {
      setNameReservationFiltered(nameReservationRequests);
    }
  }, [nameReservationRequests]);

  useEffect(() => {
    if (updatedReservation?.updateNameReservationApprovalStatus && updateStatus) {
      setUpdateStatus(false);
      //Initialize array of objects.
      let tempReservations = [...nameReservationRequests];

      //Find index of specific object using findIndex method.
      const objIndex = tempReservations.findIndex(
        (obj) => obj._id === updatedReservation?.updateNameReservationApprovalStatus?._id
      );

      //Update object's name property.
      tempReservations[objIndex].status = updatedReservation?.updateNameReservationApprovalStatus?.status;

      //Log object to console again.
      setNameReservationRequests(tempReservations);
    }
  }, [updatedReservation?.updateNameReservationApprovalStatus, nameReservationRequests, updateStatus]);

  const changeStatus = (_id, status, adminMessage = '') => {
    setUpdateStatus(true);
    updateNameReservationApprovalStatus({
      variables: { input: { _id, status, adminMessage: [adminMessage] } },
    });
    enqueueSnackbar('Status updated!');
  };

  useEffect(() => {
    const lowerCaseSearchTerm = search.toLowerCase();
    if (search.length) {
      setNameReservationFiltered(() =>
        nameReservationRequests?.filter((request) => {
          return (
            request?.proposedNames?.optionOne?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.proposedNames?.optionTwo?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.NatureOfBusiness?.toLowerCase().includes(lowerCaseSearchTerm) ||
            request?.user?.firstName?.toLowerCase().includes(lowerCaseSearchTerm)
          );
        })
      );
    } else {
      setNameReservationFiltered(nameReservationRequests);
    }

    if (filteredStatus !== -1)
      setNameReservationFiltered((prevState) =>
        prevState?.filter((request) => {
          return request?.status === filteredStatus;
        })
      );
  }, [search, nameReservationRequests, filteredStatus]);

  const handleChange = (e) => {
    SetFilteredStatus(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Name Reservation Submissions | BeeBot </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Name Reservation submissions
          </Typography>

          <Stack direction="row" alignItems="center" gap="20px">
            <FormControl></FormControl>
            <Select label="Select a filter" defaultValue={-1} value={filteredStatus} onChange={(e) => handleChange(e)}>
              <MenuItem value={-1}>All</MenuItem>
              <MenuItem value={NAME_SERVATIONS_STATUS.PROCESSING}>Processed</MenuItem>
              <MenuItem value={NAME_SERVATIONS_STATUS.COMPLETED}>Completed</MenuItem>
              <MenuItem value={NAME_SERVATIONS_STATUS.CANCELLED}>Cancelled</MenuItem>
            </Select>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Record
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={3}>
          <Grid item md={12} sm={12}>
            <TextField
              value={search}
              fullWidth
              placeholder="Search Reservation by Proposed name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>

          {loadingData && (
            <Grid item md={12} sm={12}>
              <LoadingCards />
            </Grid>
          )}
          {nameReservationFiltered?.map((post, index) => (
            <NameReservationRequestsCard
              key={post._id}
              post={post}
              index={index}
              changeStatus={changeStatus}
              loading={loading}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
}
