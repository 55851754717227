import { Grid, TextField } from '@mui/material';

export const PersonalDetailInput = ({ register }) => {
  return (
    <Grid item container xs={12} sm={12} md={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="name" label="Name" fullWidth required {...register('name', { required: true })} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <p style={{ margin: '0' }}>Date Of Birth</p>
        <TextField name="dob" fullWidth required type="date" {...register('dob', { required: true })} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="gender" label="Gender" fullWidth {...register('gender', { required: true })} required />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="nationality"
          label="Nationality"
          fullWidth
          required
          {...register('nationality', { required: true })}
        />
      </Grid>
    </Grid>
  );
};
