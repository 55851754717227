import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { Grid, Container, Typography, Button, Stack, Box } from '@mui/material';
// components
// sections
import { AppServiceProgress, ServicesClientBoard } from '../../sections/@dashboard/app';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_BUSINESS_NAME_REGISTRATION_REQUEST } from 'src/graphql/queries/businessName';

// ----------------------------------------------------------------------

export default function ClientDashboardPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState('Reserved');
  const { data, loading, error } = useQuery(GET_BUSINESS_NAME_REGISTRATION_REQUEST);

  return (
    <>
      <Helmet>
        <title> Dashboard | BeeBot </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item container xs={12} sm={12} md={12} justifyContent="space-between">
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <Typography variant="h4">Hi, {`${user?.firstName} ${user?.lastName}`}</Typography>
              <img src="/assets/icons/helloHand.svg" width={20} alt="status" />
            </Box>

            <Button variant="text" onClick={() => navigate('/client/profile', { replace: true })}>
              Edit profile
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row">
              <Button
                variant={accountType === 'Reserved' ? 'contained' : 'outlined'}
                onClick={() => setAccountType('Reserved')}
              >
                Name Reservations
              </Button>
              <Button
                variant={accountType === 'Registered' ? 'contained' : 'outlined'}
                onClick={() => setAccountType('Registered')}
              >
                Registrations
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppServiceProgress title={`${accountType} Names`} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ServicesClientBoard
              title={`${accountType} Names`}
              list={data?.businessNames?.map((_, index) => ({
                id: _?._id,
                title: _?.businessName,
                description: _?.natureOfBusinessDescription,
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: _?.created_at,
                status: _?.status,
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
