import { Grid, TextField, Hidden, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyRegistrationData } from 'src/redux/companyregistration.slice';
import { StyledHeader, StyledHeaderContainer } from './styled';
import { useState } from 'react';
// ----------------------------------------------------------------------

export default function Step5({ setStep, step, selectedReservedName }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.companyregistration);
  const [totalShareCapital, setTotalShareCapital] = useState(data?.totalShareCapital ? data?.totalShareCapital : '');

  const handleNextStep = () => {
    setStep(step + 1);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(setCompanyRegistrationData({ ...data, totalShareCapital }));
    handleNextStep();
  };

  const handleChangeShareCapital = (event) => {
    setTotalShareCapital(event.target.value);
  };

  return (
    <Grid item container xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Grid item xs={12} sm={12} md={12} container>
        <StyledHeaderContainer>
          <img src="/assets/icons/IssueShareCapital.svg" alt="Issues shared capital" />
          <StyledHeader>Issues shared capital </StyledHeader>
        </StyledHeaderContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Total Issue shared capital *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={totalShareCapital}
            required
            label="Total Issue shared capital"
            onChange={handleChangeShareCapital}
          >
            <MenuItem value="100000">N100,000</MenuItem>
            <MenuItem value="150000">N150,000</MenuItem>
            <MenuItem value="200000">N200,000</MenuItem>
            <MenuItem value="250000">N250,000</MenuItem>
            <MenuItem value="500000">N500,000</MenuItem>
            <MenuItem value="1000000">N1,000,000</MenuItem>
            <MenuItem value="10000000">Others</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="totalShareInCapitalWords"
          label="Total Share In Capital Words"
          fullWidth
          required
          defaultValue={data?.totalShareInCapitalWords}
          {...register('totalShareInCapitalWords', { required: true })}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingButton style={{ marginRight: '1rem' }} variant="outlined" onClick={() => setStep(step - 1)}>
            Go Back
          </LoadingButton>
          <LoadingButton variant="contained" type="submit">
            Save & Continue
          </LoadingButton>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item container xs={12} sm={12} md={12} spacing={2}>
          <Grid item xs={12}>
            <LoadingButton variant="outlined" fullWidth onClick={() => setStep(step - 1)}>
              Go Back
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton variant="contained" fullWidth type="submit">
              Save & Continue
            </LoadingButton>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}
