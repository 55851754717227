import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import * as React from 'react';
import Pdf from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
// @mui
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fDate } from '../../../utils/formatTime';
//
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { NAME_SERVATIONS_STATUS } from 'src/utils/Constants';
import { onDownload } from 'src/utils/HelperFunctions';
// ----------------------------------------------------------------------

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

export const ComponentToPrint = React.forwardRef(({ post, changeStatus, loading }, ref) => {
  const {
    _id,
    rcNumber,
    netAssets,
    annualTurnover,
    user,
    authorisedID,
    requestYear,
    status,
    organisationName,
    organisationType,
    paymentDetails,
  } = post;

  const [statusReservation, setStatusReservation] = React.useState(status);
  const [customerActionMessage, setCustomerActionMessage] = React.useState('');

  const handleChange = (event) => {
    setStatusReservation(event.target.value);
  };

  const handleSubmit = (_id, statusReservation, customerActionMessage) => {
    if (statusReservation !== NAME_SERVATIONS_STATUS.CUSTOMER_ACTION) setCustomerActionMessage(null);
    changeStatus(_id, statusReservation, customerActionMessage);
    setCustomerActionMessage('');
  };

  const getFieldName = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg', '.webp'];
    const fileExtension = url.toLowerCase().split('.').pop();
    return imageExtensions.includes(`.${fileExtension}`);
  };

  const FieldCheck = ({ partnerField, title, args }) => {
    return (
      partnerField && (
        <>
          <StyledElementContainer>
            <Typography variant="caption">{title}</Typography>
            {args && <Typography variant="subtitle2">{`${args}`}</Typography>}
            {!args && <Typography variant="subtitle2">{`${partnerField}`}</Typography>}
          </StyledElementContainer>
          <Divider />
        </>
      )
    );
  };

  const ImageContainer = ({ title, imageUrl }) => {
    return (
      <>
        {imageUrl && (
          <>
            <StyledElementContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="caption">{title}</Typography>
                <IconButton color="primary" component="label" onClick={() => onDownload(imageUrl)}>
                  <Download />
                </IconButton>
              </Box>
              {getFieldName(imageUrl) ? (
                <img src={imageUrl} alt={imageUrl} width={200} />
              ) : (
                <img
                  src="/assets/icons/PassportIcon.svg"
                  alt="pdf icon"
                  width={50}
                  onClick={() => onDownload(imageUrl)}
                />
              )}
            </StyledElementContainer>
            <Divider />
          </>
        )}
      </>
    );
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5">Annual Information</Typography>
          </StyledHeaderContainer>
          <Divider />

          <StyledContent>
            <FieldCheck title={`Order Number`} partnerField={_id} />
            <FieldCheck title={`Rc Number`} partnerField={rcNumber} />
            <FieldCheck title={'Name'} partnerField={`${user.lastName}  ${user.firstName} `} />
            <FieldCheck title={'Organisation Name'} partnerField={`${organisationName} `} />
            <FieldCheck title={'Organisation Type'} partnerField={`${organisationType} `} />
            <FieldCheck title={'Request Year'} partnerField={`${requestYear} `} />
            <FieldCheck title={'Annual Turnover'} partnerField={`${annualTurnover} `} />
            <FieldCheck title={'Net Assets'} partnerField={`${netAssets} `} />

            <FieldCheck partnerField={netAssets} title={'Net Asset'} />
            <FieldCheck partnerField={user.email} title={'Email Address'} />
            <FieldCheck partnerField={user.phoneNumber} title={'Phone Number'} />
            <FieldCheck partnerField={user.dob} title={'Date of Birth'} />

            <FieldCheck partnerField={annualTurnover} title={'Annual TurnOver'} />
            <ImageContainer title={`Director's ID`} imageUrl={authorisedID} />
            {/* <FieldCheck partnerField={partner.proprietorEmail} title={"Partner's Email"} /> */}
          </StyledContent>
        </Grid>

        {paymentDetails && (
          <Grid item md={12} sm={12} xs={12}>
            <StyledHeaderContainer>
              <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
              <Typography variant="h5">Payment Details</Typography>
            </StyledHeaderContainer>
            <StyledContent>
              <FieldCheck title={`Transaction Id`} partnerField={paymentDetails?.transactionId} />
              <FieldCheck title={`Amount`} partnerField={paymentDetails?.amount} />
              <FieldCheck
                title={`Payment Status`}
                partnerField={`${paymentDetails?.status === 'success' ? 'Paid' : 'Not Paid'}`}
              />
            </StyledContent>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12} gap={2}>
          <Stack spacing={2} sx={{ mt: 2, mb: 3 }} direction="row">
            <FormControl>
              <InputLabel id="statusLabel"> Order Status</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={statusReservation}
                label="menuItem"
                onChange={(event) => handleChange(event)}
                sx={{ minWidth: 200 }}
              >
                <MenuItem
                  selected={statusReservation === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION}
                  value={NAME_SERVATIONS_STATUS.CUSTOMER_ACTION}
                >
                  Customer Action Required{' '}
                </MenuItem>
                <MenuItem
                  selected={statusReservation === NAME_SERVATIONS_STATUS.PROCESSING}
                  value={NAME_SERVATIONS_STATUS.PROCESSING}
                >
                  Processed
                </MenuItem>
                <MenuItem
                  selected={statusReservation === NAME_SERVATIONS_STATUS.COMPLETED}
                  value={NAME_SERVATIONS_STATUS.COMPLETED}
                >
                  Completed
                </MenuItem>
                <MenuItem
                  selected={statusReservation === NAME_SERVATIONS_STATUS.COMPLETED}
                  value={NAME_SERVATIONS_STATUS.PENDING}
                >
                  Pending
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Stack>
            {/* <StyledElementContainer> */}
            {statusReservation === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION && (
              <TextField
                id="outlined-multiline-static"
                label="Extra Message"
                multiline
                rows={3}
                sx={{ margin: '10px 0' }}
                value={customerActionMessage}
                onChange={(event) => setCustomerActionMessage(event.target.value)}
              />
            )}
            {/* </StyledElementContainer> */}

            <LoadingButton
              variant="contained"
              fullWidth={true}
              onClick={() => handleSubmit(_id, statusReservation, customerActionMessage)}
              loading={loading}
            >
              Update Status
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
});

AnnualReturnRequestCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function AnnualReturnRequestCard({ post, index, changeStatus, loading }) {
  const { created_at, status, user, organisationName } = post;

  const [expanded, setExpanded] = React.useState(false);
  const componentRef = React.useRef();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <img
                src={
                  status === NAME_SERVATIONS_STATUS.PENDING
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.COMPLETED
                    ? '/assets/icons/statuses/Approved.svg'
                    : '/assets/icons/statuses/Denied.svg'
                }
                width={20}
                alt="status"
              />
            </IconButton>
          }
          title={`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
          subheader={`Submitted: ${fDate(created_at)}`}
        />
        <CardActions disableSpacing>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Company Name: ${organisationName}`}
          </LoadingButton>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button onClick={handlePrint}>Print</Button>
                <Pdf targetRef={componentRef} filename="code-example.pdf">
                  {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                </Pdf>
              </Stack>

              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
