import { Download } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import * as React from 'react';
import Pdf from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
// @mui
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fDate } from '../../../utils/formatTime';
//
import { LoadingButton } from '@mui/lab';
import { NAME_SERVATIONS_STATUS } from 'src/utils/Constants';
import { onDownload } from 'src/utils/HelperFunctions';

// ----------------------------------------------------------------------

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

export const ComponentToPrint = React.forwardRef(({ post, changeStatus, loading }, ref) => {
  const {
    _id,
    user,
    organisationName,
    rcNumber,
    shareholderName,
    firstName,
    surname,
    otherName,
    nationality,
    phoneNumber,
    emailAddress,
    dateOfBirth,
    gender,
    residentialAddress,
    serviceAddress,
    affidavit,
    application,
    adminMessage,
    status,
    paymentDetails,
    directorName,
  } = post;

  const [statusReservation, setStatusReservation] = React.useState(status);
  const [customerActionMessage, setCustomerActionMessage] = React.useState('');

  const handleChange = (event) => {
    setStatusReservation(event.target.value);
  };

  const handleSubmit = (_id, statusReservation, customerActionMessage) => {
    if (statusReservation !== NAME_SERVATIONS_STATUS.CUSTOMER_ACTION) setCustomerActionMessage(null);
    changeStatus(_id, statusReservation, customerActionMessage);
    setCustomerActionMessage('');
  };

  const getFieldName = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg', '.webp'];
    const fileExtension = url?.toLowerCase().split('.').pop();
    return imageExtensions.includes(`.${fileExtension}`);
  };

  const FieldCheck = ({ partnerField, title, args }) => {
    return (
      partnerField && (
        <>
          <StyledElementContainer>
            <Typography variant="caption">{title}</Typography>
            {args && <Typography variant="subtitle2">{`${args}`}</Typography>}
            {!args && <Typography variant="subtitle2">{`${partnerField}`}</Typography>}
          </StyledElementContainer>
          <Divider />
        </>
      )
    );
  };

  const ImageContainer = ({ title, imageUrl }) => {
    return (
      <>
        {imageUrl && (
          <>
            <StyledElementContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="caption">{title}</Typography>
                <IconButton color="primary" component="label" onClick={() => onDownload(imageUrl)}>
                  <Download />
                </IconButton>
              </Box>
              {getFieldName(imageUrl) ? (
                <img src={imageUrl} alt={imageUrl} width={200} />
              ) : (
                <img
                  src="/assets/icons/PassportIcon.svg"
                  alt="pdf icon"
                  width={50}
                  onClick={() => onDownload(imageUrl)}
                />
              )}
            </StyledElementContainer>
            <Divider />
          </>
        )}
      </>
    );
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5"> Business name details</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <FieldCheck title={'Order Number'} partnerField={_id} />
            <FieldCheck title={'Organisation Name'} partnerField={organisationName} />
            <FieldCheck title={'Rc Number'} partnerField={rcNumber} />
          </StyledContent>

          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5"> Data to be changed</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <FieldCheck title={'Director\'s Name'} partnerField={directorName} />
            <FieldCheck title={'Shareholder\'s Name'} partnerField={shareholderName} />
            <FieldCheck title={'First Name'} partnerField={firstName} />
            <FieldCheck title={'Surname'} partnerField={surname} />
            <FieldCheck title={'Other Name'} partnerField={otherName} />
            <FieldCheck title={'Phone Number'} partnerField={phoneNumber} />
            <FieldCheck title={'Email Address'} partnerField={emailAddress} />
            <FieldCheck title={'Date of Birth'} partnerField={dateOfBirth} />
            <FieldCheck title={'Gender'} partnerField={gender} />
            <FieldCheck title={'Nationality'} partnerField={nationality} />
            <FieldCheck title={'Residential Address'} partnerField={residentialAddress} />
            <FieldCheck title={'Service Address'} partnerField={serviceAddress} />
          </StyledContent>

          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />
            <Typography variant="h5">Payment Details </Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <StyledElementContainer>
              <Typography variant="caption">Payment Status</Typography>
              <Typography variant="subtitle2">{paymentDetails?.status === 'success' ? `Paid ` : 'Not Paid'}</Typography>
            </StyledElementContainer>
            <Divider />
            <FieldCheck title={'Amount'} partnerField={paymentDetails?.amount} />
            <FieldCheck title={'Transaction ID'} partnerField={paymentDetails?.transactionId} />
          </StyledContent>

          {adminMessage && adminMessage.length > 0 && (
            <Grid item md={12} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Admin Messages</Typography>
              </StyledHeaderContainer>
              <StyledContent>
                {adminMessage?.length > 0 && (
                  <StyledElementContainer>
                    <Typography variant="caption">Admin Review</Typography>
                    {adminMessage?.map((review, index) => {
                      return <Typography key={index} variant="subtitle2">{`${review}`}</Typography>;
                    })}
                  </StyledElementContainer>
                )}
              </StyledContent>
            </Grid>
          )}

          {user && (
            <>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Personal Details</Typography>
              </StyledHeaderContainer>
              <Divider />
              <StyledContent>
                <FieldCheck title={'Last Name'} partnerField={user.lastName} />
                <FieldCheck title={'First Name'} partnerField={user.firstName} />
                <FieldCheck title={'Email'} partnerField={user.email} />
                <FieldCheck title={'Address'} partnerField={user.address} />
                <FieldCheck title={'Phone Number'} partnerField={user.phoneNumber} />
              </StyledContent>
            </>
          )}
          <ImageContainer title="Affidavit Id" imageUrl={affidavit} />
          <ImageContainer title="Application" imageUrl={application} />

          <Stack spacing={2} sx={{ mt: 2, mb: 3 }} direction="row">
            <FormControl>
              <InputLabel id="statusLabel">Order Status</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={statusReservation}
                label="Age"
                onChange={(event) => handleChange(event)}
                sx={{ minWidth: 200 }}
              >
                <MenuItem value={NAME_SERVATIONS_STATUS.CUSTOMER_ACTION}>Customer Action Required </MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.PROCESSING}>Processed</MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.COMPLETED}>Completed</MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.PENDING}>Pending</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Stack>
            {statusReservation === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION && (
              <TextField
                sx={{ mt: 2, mb: 2 }}
                id="outlined-multiline-static"
                label="Extra Message"
                multiline
                minRows={3}
                value={customerActionMessage}
                onChange={(event) => setCustomerActionMessage(event.target.value)}
              />
            )}

            <LoadingButton
              variant="contained"
              fullWidth={false}
              onClick={() => handleSubmit(_id, statusReservation, customerActionMessage)}
              loading={loading}
            >
              Update Status
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
});

UpdateShareHolderDetailsRequestCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function UpdateShareHolderDetailsRequestCard({ post, index, changeStatus, loading }) {
  const { created_at, status, organisationName } = post;

  const [expanded, setExpanded] = React.useState(false);
  const componentRef = React.useRef();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <img
                src={
                  status === NAME_SERVATIONS_STATUS.PROCESSING
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.PENDING
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.CUSTOMER_ACTION
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.COMPLETED
                    ? '/assets/icons/statuses/Approved.svg'
                    : '/assets/icons/statuses/Denied.svg'
                }
                width={20}
                alt="status"
              />
            </IconButton>
          }
          title={`${organisationName}`}
          subheader={`Submitted: ${fDate(created_at)}`}
        />
        <CardActions disableSpacing>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Business Name: ${organisationName}`}
          </LoadingButton>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button onClick={handlePrint}>Print</Button>
                <Pdf targetRef={componentRef} filename="code-example.pdf">
                  {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                </Pdf>
              </Stack>

              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}

/* 
            <LoadingButton variant="contained" onClick={() => changeStatus(_id, statusReservation)} loading={loading}>
              Update Status
            </LoadingButton>*/
