import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import Pdf from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
// @mui
import { Button, Divider, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fDate } from '../../../utils/formatTime';
//
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { NAME_SERVATIONS_STATUS } from 'src/utils/Constants';
import { onDownload } from 'src/utils/HelperFunctions';

// ----------------------------------------------------------------------

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

export const ComponentToPrint = React.forwardRef(({ post, changeStatus, loading }, ref) => {
  const {
    user,
    directors,
    nextOfKins,
    refNameAccountOne,
    refNameAccountTwo,
    refNameOne,
    refNameTwo,
    companyName,
    rcNumber,
    tin,
    companyAddress,
    nearestLandmark,
    incorporationDocument,
    cacCertificate,
    statusReport,
    adminMessage,
    customerReview,
    starRating,
    refBankOne,
    refBankTwo,
    numberOfDirectors,
    numberOfSignatory,
    accountApplication,
    utilityBill,
    accountType,
  } = post;

  const FieldCheck = ({ partnerField, title, args }) => {
    return (
      partnerField && (
        <>
          <StyledElementContainer>
            <Typography variant="caption">{title}</Typography>
            {args && <Typography variant="subtitle2">{`${args}`}</Typography>}
            {!args && <Typography variant="subtitle2">{`${partnerField}`}</Typography>}
          </StyledElementContainer>
          <Divider />
        </>
      )
    );
  };

  const ReferenceDetails = ({ refNameAccountOne, refNameOne, number }) => {
    return (
      <>
        <FieldCheck variant={refNameAccountOne} title={`Reference ${number} Name`} />
        <FieldCheck variant={refNameOne} title={`Reference ${number} Account`} />
        <FieldCheck variant={refBankOne} title={`Reference ${number} Account`} />
      </>
    );
  };

  const getFieldName = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg', '.webp'];
    const fileExtension = url?.toLowerCase().split('.').pop() && '';
    return imageExtensions.includes(`.${fileExtension}`);
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid item md={12} sm={12} xs={12}>
        <StyledHeaderContainer>
          <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
          <Typography variant="h5">Personal Details</Typography>
        </StyledHeaderContainer>
        <Divider />

        <StyledContent>
          <StyledElementContainer>
            <FieldCheck partnerField={user.firstName} title={`First Name`} />
            <FieldCheck partnerField={user.lastName} title={`Last Name`} />
            <FieldCheck partnerField={user.middleName} title={`Other Name`} />
            <FieldCheck partnerField={user.email} title={`Email`} />
            <FieldCheck partnerField={user.phone} title={`Phone`} />
            <FieldCheck partnerField={user.city} title={`City`} />
            <FieldCheck partnerField={user.address} title={`Address`} />
            <FieldCheck partnerField={user.city} title={`City`} />
            <FieldCheck partnerField={user.gender} title={`Gender`} />
          </StyledElementContainer>
        </StyledContent>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <StyledHeaderContainer>
          <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
          <Typography variant="h5">Star Rating and Reviews</Typography>
        </StyledHeaderContainer>
        <Divider />

        <StyledContent>
          <StyledElementContainer>
            <Typography variant="caption">Star Rating</Typography>
            <Typography variant="subtitle">{starRating}</Typography>

            {adminMessage?.map((adminMsg) => {
              return (
                <>
                  <Typography variant="caption">Admin Message</Typography>
                  <Typography variant="subtitle">{adminMsg}</Typography>
                </>
              );
            })}
          </StyledElementContainer>
          <FieldCheck partnerField={customerReview} title={`Customer Review`} />
        </StyledContent>
      </Grid>
      <Grid container spacing={2}>
        {directors?.map((director, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Director {index + 1} Information</Typography>
              </StyledHeaderContainer>
              <Divider />

              <StyledContent>
                <FieldCheck title={`Director's Surname`} partnerField={director.surname} />
                <FieldCheck title={`Director's FirstName`} partnerField={director.firstName} />
                <FieldCheck title={`Director's OtherName`} partnerField={director.otherName} />
                <FieldCheck title={`Director's Email`} partnerField={director.email} />
                <FieldCheck title={`Director's Phone Number`} partnerField={director.mobile} />
                <FieldCheck title={`Director's Country`} partnerField={director.country} />
                <FieldCheck title={`Director's LGA`} partnerField={director.lga} />
                <FieldCheck title={`Director's BVN`} partnerField={director.bvn} />
                <FieldCheck title={`Director's DOB`} partnerField={director.dob} />

                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">{`Director's Signature `}</Typography>
                    <IconButton color="primary" component="label" onClick={() => onDownload(director.dirSignature)}>
                      <Download />
                    </IconButton>
                  </Box>
                  {getFieldName(director.dirSignature) ? (
                    <img src={director.dirSignature} alt={director.dirSignature} width={200} />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(director.dirSignature)}
                    />
                  )}
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Director's Passport</Typography>
                    <IconButton color="primary" component="label" onClick={() => onDownload(director.passportPhoto)}>
                      <Download />
                    </IconButton>
                  </Box>
                  {getFieldName(director.passportPhoto) ? (
                    <img src={director.passportPhoto} alt={director.passportPhoto} width={200} />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(director.passportPhoto)}
                    />
                  )}
                </StyledElementContainer>
                <Divider />
                <StyledElementContainer>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Director Identity Document</Typography>
                    <IconButton color="primary" component="label" onClick={() => onDownload(director.identityDocument)}>
                      <Download />
                    </IconButton>
                  </Box>

                  {getFieldName(director.identityDocument) ? (
                    <img src={director.identityDocument} alt={director.identityDocument} width={200} />
                  ) : (
                    <img
                      src="/assets/icons/PassportIcon.svg"
                      alt="pdf icon"
                      width={50}
                      onClick={() => onDownload(director.identityDocument)}
                    />
                  )}
                </StyledElementContainer>
              </StyledContent>
            </Grid>
          );
        })}

        {nextOfKins?.map((kin, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <StyledHeaderContainer>
                <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
                <Typography variant="h5">Next of Kin {index + 1} Information</Typography>
              </StyledHeaderContainer>
              <Divider />

              <StyledContent>
                <FieldCheck title={`Next of Kin's Surname Name`} partnerField={kin.surname} />
                <FieldCheck title={`Next of Kin's First Name`} partnerField={kin.firstName} />
                <FieldCheck title={`Next of Kin's Other Name`} partnerField={kin.otherName} />
                <FieldCheck title={`Next of Kin's Relationship`} partnerField={kin.nextOfKinRelationship} />
                <FieldCheck title={`Next of Kin's Gender`} partnerField={kin.gender} />
                <FieldCheck title={`Next of Kin's BVN`} partnerField={kin.bvn} />
                <FieldCheck title={`Next of Kin's Nationality`} partnerField={kin.nationality} />
                <FieldCheck title={`Next of Kin's Address`} partnerField={kin.address} />
                <FieldCheck title={`Next of Kin's Phone`} partnerField={kin.mobile} />
                <FieldCheck title={`Next of Kin's LGA`} partnerField={kin.lga} />
              </StyledContent>
            </Grid>
          );
        })}

        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />
            <Typography variant="h5"> Banking details</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <FieldCheck title={`TIN`} partnerField={tin} />
            <FieldCheck title={'Account Type'} partnerField={accountType} />
            <FieldCheck title={`RC Number`} partnerField={rcNumber} />
            <FieldCheck title={`Number of Directors `} partnerField={numberOfDirectors} />
            <FieldCheck title={`Number of Signatory`} partnerField={numberOfSignatory} />
            <FieldCheck title={`Account Application`} partnerField={accountApplication} />
            <ReferenceDetails
              number={1}
              refNameAccountOne={refNameAccountOne}
              refNameOne={refNameOne}
              refBankOne={refBankOne}
            />
            <ReferenceDetails
              number={2}
              refNameAccountOne={refNameAccountTwo}
              refNameOne={refNameTwo}
              refBankOne={refBankTwo}
            />
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Company Name</Typography>
              <Typography variant="subtitle2">{companyName}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Company Address</Typography>
              <Typography variant="subtitle2">{companyAddress}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Nearest LandMark</Typography>
              <Typography variant="subtitle2">{nearestLandmark}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="caption">Status Report</Typography>
                <IconButton color="primary" component="label" onClick={() => onDownload(statusReport)}>
                  <Download />
                </IconButton>
              </Box>

              {getFieldName(statusReport) ? (
                <img src={statusReport} alt={statusReport} width={200} />
              ) : (
                <img
                  src="/assets/icons/PassportIcon.svg"
                  alt="pdf icon"
                  width={50}
                  onClick={() => onDownload(statusReport)}
                />
              )}
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="caption">CAC Certificate</Typography>
                <IconButton color="primary" component="label" onClick={() => onDownload(cacCertificate)}>
                  <Download />
                </IconButton>
              </Box>

              {getFieldName(cacCertificate) ? (
                <img src={cacCertificate} alt={cacCertificate} width={200} />
              ) : (
                <img
                  src="/assets/icons/PassportIcon.svg"
                  alt="pdf icon"
                  width={50}
                  onClick={() => onDownload(cacCertificate)}
                />
              )}
            </StyledElementContainer>
            <Divider />

            <StyledElementContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="caption">Incorporation Document</Typography>
                <IconButton color="primary" component="label" onClick={() => onDownload(incorporationDocument)}>
                  <Download />
                </IconButton>
              </Box>
              {getFieldName(incorporationDocument) ? (
                <img src={incorporationDocument} alt={incorporationDocument} width={200} />
              ) : (
                <img
                  src="/assets/icons/PassportIcon.svg"
                  alt="pdf icon"
                  width={50}
                  onClick={() => onDownload(incorporationDocument)}
                />
              )}
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="caption">Utility Bill</Typography>
                <IconButton color="primary" component="label" onClick={() => onDownload(incorporationDocument)}>
                  <Download />
                </IconButton>
              </Box>
              {getFieldName(utilityBill) ? (
                <img src={utilityBill} alt={utilityBill} width={200} />
              ) : (
                <img
                  src="/assets/icons/PassportIcon.svg"
                  alt="pdf icon"
                  width={50}
                  onClick={() => onDownload(utilityBill)}
                />
              )}
            </StyledElementContainer>
            <Divider />
          </StyledContent>
        </Grid>
      </Grid>
    </div>
  );
});

BankAccountRequestCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BankAccountRequestCard({ post, index, changeStatus, loading }) {
  const { companyName, created_at, status } = post;

  const [expanded, setExpanded] = React.useState(false);
  const componentRef = React.useRef();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <img
                src={
                  status === NAME_SERVATIONS_STATUS.PENDING
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.COMPLETED
                    ? '/assets/icons/statuses/Approved.svg'
                    : '/assets/icons/statuses/Denied.svg'
                }
                width={20}
                alt="status"
              />
            </IconButton>
          }
          title={`${companyName}`}
          subheader={`Submitted: ${fDate(created_at)}`}
        />
        <CardActions disableSpacing>
          <LoadingButton variant="outlined" sx={{ ml: 1 }}>
            {`Business Name: ${companyName}`}
          </LoadingButton>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button onClick={handlePrint}>Print</Button>
                <Pdf targetRef={componentRef} filename="code-example.pdf">
                  {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                </Pdf>
              </Stack>

              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
