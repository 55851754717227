import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
// @mui
import { Alert, IconButton, InputAdornment, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Error from 'src/components/error';
import { createAgentUser } from 'src/graphql/mutations/auth';
import { setUser } from 'src/redux/auth.slice';
import * as Yup from 'yup';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledTextInput = styled(TextField)`
  margin: 0 !important;
  input {
    margin: 0;
  }
`;

const StyledFormWrapper = styled('form')(({ theme }) => ({
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
  padding: '0 1rem 1rem 1rem',
}));

const StyledFormHeader = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #CFCFCF',
  marginLeft: '-1rem',
  marginRight: '-1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const StyledTextInputCenter = styled(TextField)`
  margin: 0 !important;
  input {
    margin: 0;
  }
  fieldset {
    border-radius: 0;
  }
`;

const StyledHeaderIconWrapper = styled('div')`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50px;
  background-color: #ffd3d3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCenteredSection = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function SignupForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      gender: '',
      dob: '',
      address: '',
      postCode: '',
      city: '',
      phoneNumber: null,
      email: '',
      password: '',
      companyName: '',
      rcNumber: '',
    },

    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().required('Required').min(6, 'Password is too short - should be 6 chars minimum.'),
      phoneNumber: Yup.string().required('Phone number required').max(14, 'Invalid phone number'),
      // .min(10, 'Invalid phone number'),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      dob: Yup.string().required('Required'),
      // .validate((val) => Promise.resolve(+val.split('-')[0] + 18 > new Date().getFullYear())),
      gender: Yup.string().required('Required'),
      companyName: Yup.string().required('Required'),
      rcNumber: Yup.string().required('Required'),
      // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
    }),
    onSubmit: () => {
      handleCreateUser();
    },
  });

  const [handleCreateUser, { loading, error, data }] = useMutation(createAgentUser, {
    variables: { input: { ...formik.values } },
  });

  const checkAge = () => {
    return +formik.values.dob.split('-')[0] + 18 > new Date().getFullYear();
  };

  useEffect(() => {
    if (data?.createAgent) {
      dispatch(setUser(data?.createAgent));
      navigate('/verify-email', { replace: true });
    }
  }, [data, navigate]);


  return (
    <>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <StyledFormWrapper onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <StyledFormHeader>
            <StyledHeaderIconWrapper>
              <img src="/assets/icons/SignupMarkIcon.svg" alt="login" />
            </StyledHeaderIconWrapper>
            <Typography
              variant="h5"
              style={{
                padding: '0.5rem',
              }}
            >
              Sign up
            </Typography>
          </StyledFormHeader>
          <StyledTextInput
            name="companyName"
            placeholder="Company name"
            id="companyName"
            type="text"
            error={Boolean(formik.touched.companyName && formik.errors.companyName)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
          />
          <StyledTextInput
            name="rcNumber"
            placeholder="RC Number"
            id="rcNumber"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.rcNumber}
            error={Boolean(formik.touched.rcNumber && formik.errors.rcNumber)}
          />
          <br />
          <StyledTextInput
            name="firstName"
            placeholder="First name"
            id="firstName"
            type="text"
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
          <StyledTextInputCenter
            name="middleName"
            placeholder="Middle name"
            id="middleName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.middleName}
          />
          <StyledTextInput
            name="lastName"
            placeholder="Last name"
            id="lastName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
          />
          {Boolean(formik.touched.lastName && formik.errors.lastName) && <Error message={formik.errors.lastName} />}
          <Select
            name="gender"
            labelId="gender-select"
            id="gender"
            label="Gender"
            onChange={formik.handleChange}
            value={formik.values.gender || 'Gender'}
            error={Boolean(formik.touched.gender && formik.values.gender === 'Gender')}
          >
            <MenuItem value="Gender">Gender</MenuItem>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>
          {Boolean(formik.touched.gender && formik.errors.gender) && <Error message={formik.errors.gender} />}
          <TextField
            name="dob"
            label="Date of birth"
            InputProps={{
              style: {
                marginBottom: '24px',
              },
            }}
            id="dob"
            type="date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dob || '2021-12-18'}
            error={Boolean(formik.touched.dob && checkAge())}
          />
          <Typography variant="body2" sx={{ mb: 5 }} style={{ marginTop: '-24px', marginBottom: '24px' }}>
            To sign up, you need to be at least 18. Your birthday won’t be shared with any other person.
          </Typography>
          <StyledTextInput
            name="address"
            placeholder="Address"
            id="address"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
          <StyledTextInputCenter
            name="city"
            placeholder="City/Town*"
            id="city"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
          />
          <StyledTextInput
            name="postCode"
            placeholder="Postcode"
            id="postCode"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postCode}
          />

          <TextField
            name="phoneNumber"
            label="Phone Number*"
            InputProps={{
              style: {
                marginBottom: '24px',
              },
            }}
            id="phoneNumber"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
          />
          {Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber) && (
            <Error message={formik.errors.phoneNumber} />
          )}
          <Typography variant="body2" sx={{ mb: 5 }} style={{ marginTop: '-24px' }}>
            Only numbers, no spaces, max 14 characters
          </Typography>

          <TextField
            name="email"
            label="Email address"
            id="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={Boolean(formik.touched.email && formik.errors.email)}
          />
          {Boolean(formik.touched.email && formik.errors.email) && <Error message={formik.errors.email} />}
          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={Boolean(formik.touched.password && formik.errors.password)}
          />
          {Boolean(formik.touched.password && formik.errors.password) && <Error message={formik.errors.password} />}
          <Typography variant="body2" sx={{ mb: 5 }} style={{ marginTop: '0px' }}>
            By selecting Agree and continue, I agree to Beebot’s Terms and Conditions and acknowledge the Privacy
            Policy.
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            loadingIndicator="Loading…"
          >
            Agree and continue
          </LoadingButton>
        </Stack>
        <StyledCenteredSection>
          <Typography variant="body2">
            Already have an account? {''}
            <Link variant="subtitle2" onClick={() => navigate('/login', { replace: true })}>
              Log in
            </Link>
          </Typography>
        </StyledCenteredSection>
      </StyledFormWrapper>
    </>
  );
}
