import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import Pdf from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// @mui
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { LoadingButton } from '@mui/lab';
import { NAME_SERVATIONS_STATUS } from 'src/utils/Constants';
import { fDate } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '1px solid #CFCFCF',
  borderRadius: '10px',
}));

const StyledElementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '1rem',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ----------------------------------------------------------------------

NameReservationRequestsCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export const ComponentToPrint = React.forwardRef(({ post, changeStatus, loading }, ref) => {
  const {
    _id,
    classification,
    specificType,
    NatureOfBusiness,
    reasonsForAvailabilitySearch,
    additionalComment,
    // paymentDetails: { serviceId, status: paymentStatus, transactionId, amount },
    paymentDetails,
    proposedNames: { optionOne, optionTwo },
    // user: { firstName, lastName, email, phoneNumber },
    user,
    status,
    rcNumber,
    companyName,
    adminMessage,
    customerReview,
    starRating,
  } = post;

  const [statusReservation, setStatusReservation] = React.useState(status);


  const [customerActionMessage, setCustomerActionMessage] = React.useState('');

  const handleChange = (event) => {
    setStatusReservation(event.target.value);
  };
  const handleSubmit = (_id, statusReservation, customerActionMessage) => {
    if (statusReservation !== NAME_SERVATIONS_STATUS.CUSTOMER_ACTION) setCustomerActionMessage(null);
    changeStatus(_id, statusReservation, customerActionMessage);
    setCustomerActionMessage('');
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ px: 3 }}>
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5">Star Rating and Reviews</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <StyledElementContainer>
              <Typography variant="caption">Star Rating</Typography>
              <Typography variant="subtitle2">{starRating}</Typography>
            </StyledElementContainer>
            {customerReview?.length > 0 && (
              <StyledElementContainer>
                <Typography variant="caption">Customer Review</Typography>
                {customerReview?.map((review) => {
                  return <Typography variant="subtitle2">{`${review}`}</Typography>;
                })}
              </StyledElementContainer>
            )}
            {adminMessage?.length > 0 && (
              <StyledElementContainer>
                <Typography variant="caption">Admin Review</Typography>
                {adminMessage?.map((review) => {
                  return <Typography variant="subtitle2">{`${review}`}</Typography>;
                })}
              </StyledElementContainer>
            )}
          </StyledContent>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5"> Business name details</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <StyledElementContainer>
              <Typography variant="caption">Order Number</Typography>
              <Typography variant="subtitle2">{_id}</Typography>
            </StyledElementContainer>
            <StyledElementContainer>
              <Typography variant="caption">Client Name</Typography>
              <Typography variant="subtitle2">{`${user?.firstName ? user?.firstName : ''} ${
                user?.lastName ? user?.lastName : ''
              }`}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Client Email</Typography>
              <Typography variant="subtitle2">{`${user?.email ? user?.email : ''}`}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Client Phone</Typography>
              <Typography variant="subtitle2">{`${user?.phoneNumber ? user?.phoneNumber : ''}`}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Propose name (option 1)</Typography>
              <Typography variant="subtitle2">{`${optionOne}`}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Propose name (option 2)</Typography>
              <Typography variant="subtitle2">{optionTwo}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Classification</Typography>
              <Typography variant="subtitle2">{classification}</Typography>
            </StyledElementContainer>
          </StyledContent>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <StyledHeaderContainer>
            <img src="/assets/icons/PersonalDetails.svg" alt="login" />{' '}
            <Typography variant="h5">Other information</Typography>
          </StyledHeaderContainer>
          <Divider />
          <StyledContent>
            <StyledElementContainer>
              <Typography variant="caption">Reasons for availability search</Typography>
              <Typography variant="subtitle2">{`${reasonsForAvailabilitySearch}`}</Typography>
            </StyledElementContainer>
            <Divider />
            {rcNumber && (
              <StyledElementContainer>
                <Typography variant="caption">RC Number</Typography>
                <Typography variant="subtitle2">{rcNumber}</Typography>
              </StyledElementContainer>
            )}
            {rcNumber && <Divider />}
            {companyName && (
              <StyledElementContainer>
                <Typography variant="caption">Previous Company Name</Typography>
                <Typography variant="subtitle2">{companyName}</Typography>
              </StyledElementContainer>
            )}
            {companyName && <Divider />}
            <StyledElementContainer>
              <Typography variant="caption">Specific Type</Typography>
              <Typography variant="subtitle2">{`${specificType}`}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Nature of Business</Typography>
              <Typography variant="subtitle2">{NatureOfBusiness}</Typography>
            </StyledElementContainer>
            <Divider />
            <StyledElementContainer>
              <Typography variant="caption">Additional Comments</Typography>
              <Typography variant="subtitle2">{additionalComment}</Typography>
            </StyledElementContainer>
            <StyledElementContainer>
              <Typography variant="caption">Payment Status</Typography>
              <Typography variant="subtitle2">
                {paymentDetails ? `NGN ${paymentDetails?.amount} ${paymentDetails?.status}` : 'Not Paid'}
              </Typography>
            </StyledElementContainer>
          </StyledContent>
          <Stack spacing={2} sx={{ mt: 2 }} direction="row">
            <FormControl>
              <InputLabel id="statusLabel">Order Status</InputLabel>
              <Select
                labelId="statusLabel"
                id="status"
                value={statusReservation}
                label="menuItem"
                onChange={(event) => handleChange(event)}
                sx={{ minWidth: 200 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.CUSTOMER_ACTION}>Customer Action Required</MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.PROCESSING}>Processed</MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.COMPLETED}>Approved</MenuItem>
                <MenuItem value={NAME_SERVATIONS_STATUS.NOT_APPROVED}>Not Approved</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Stack>
            {statusReservation === 3 && (
              <StyledElementContainer>
                <TextField
                  id="outlined-multiline-static"
                  label="Extra Message"
                  multiline
                  rows={2}
                  value={customerActionMessage}
                  onChange={(event) => setCustomerActionMessage(event.target.value)}
                />
              </StyledElementContainer>
            )}
            <LoadingButton
              variant="contained"
              fullWidth={false}
              onClick={() => handleSubmit(_id, statusReservation, customerActionMessage)}
              loading={loading}
            >
              Update Status
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
});

export default function NameReservationRequestsCard({ post, index, changeStatus, loading }) {
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const {
    proposedNames: { optionOne, optionTwo },
    user,
    status,
    created_at,
  } = post;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <img
                src={
                  status === NAME_SERVATIONS_STATUS.PROCESSING
                    ? '/assets/icons/statuses/Pending.svg'
                    : status === NAME_SERVATIONS_STATUS.COMPLETED
                    ? '/assets/icons/statuses/Approved.svg'
                    : '/assets/icons/statuses/Denied.svg'
                }
                width={20}
                alt="status"
              />
            </IconButton>
          }
          title={`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
          subheader={`Submitted: ${fDate(created_at)}`}
        />

        <CardActions disableSpacing>
          <Stack direction="row" spacing={2}>
            <LoadingButton variant="outlined" sx={{ ml: 1 }}>
              {`Business Name: ${optionOne} / ${optionTwo}`}
            </LoadingButton>
          </Stack>

          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button onClick={handlePrint}>Print</Button>
                <Pdf
                  targetRef={componentRef}
                  filename={`${user?.firstName} ${user?.lastName} ${optionOne} ${optionTwo} .pdf`}
                >
                  {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                </Pdf>
              </Stack>

              <ComponentToPrint ref={componentRef} post={post} changeStatus={changeStatus} loading={loading} />
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
