import { createSlice } from '@reduxjs/toolkit';

const companyRegistrationSlice = createSlice({
  name: 'companyregistration',
  initialState: {
    data: {},
  },
  reducers: {
    setCompanyRegistrationData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },

    clearCompanyRegistrationData: (state, action) => {
      state.data = {};
    },
  },
});

export const companyRegistrationReducer = companyRegistrationSlice.reducer;

export const { clearCompanyRegistrationData, setCompanyRegistrationData } = companyRegistrationSlice.actions;
